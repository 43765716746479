import React, { type HTMLAttributes, type PropsWithChildren } from "react";
import { ReactComponent as BetIcon } from "../assets/poker_chip.svg";

export const BetChip = ({
	size = 150,
	children,
	style,
	...props
}: PropsWithChildren<{ size?: number } & HTMLAttributes<HTMLDivElement>>) => (
	<div style={{ aspectRatio: "1 / 1", ...style }} {...props}>
		<div style={{ position: "relative", height: "100%", width: "100%" }}>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 500 500"
				preserveAspectRatio="xMinYMid meet"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
				}}
			>
				<text
					x="50%"
					y="50%"
					fontSize={size}
					fontFamily={"Roboto"}
					fontWeight={700}
					dominantBaseline="middle"
					textAnchor="middle"
					opacity={0.7}
				>
					{children}
				</text>
			</svg>
			<BetIcon height={"100%"} width={"100%"} />
		</div>
	</div>
);
