import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$store} from "../../../store";
import Locale from "../../../core/locale";
import {useLayoutEffect} from "react";
import { ReactComponent as Cancel } from "../../../img/icons/cancel.svg";
import {setDictDebug} from "../../../store/locales";
import {Language} from "../language/Language";

let dictContent = "";
let dictErrors = "";
let dictUnreg = "";

export const Locales = ()=>{
	const store = useStore($store);
	useLayoutEffect(() => {
		dictContent = "";
		dictErrors = "";
		dictUnreg = "";
		Object.keys(Locale.Dict[$store.getState().locales.lang]||{}).map((key)=>{
			// @ts-ignore
			dictContent+=`${key}: "${Locale.Dict[$store.getState().locales.lang][key]}",\n`
		})
		Object.keys(Object.keys(store.locales.unregisteredKeys).map((key)=>{
			dictUnreg+=`${key}: "${store.locales.unregisteredKeys[key]}",\n`
		}))
		store.locales.errors.map((key)=>dictErrors+=`${key},\n`)
	}, []);
	return store.locales.debug
		? <div className={`${style.locales}`}>
			<div className={style.content}>

				<div className={style.dict}>
					<div className={style.header}>
						{`Locale keys [${store.locales.errors.length}], lang: ${store.locales.lang}`}
						<Language/>
						<Cancel height={18} width={18} onClick={()=>setDictDebug(false)} style={{pointerEvents:"all", cursor:"pointer"}}/>
					</div>
					<textarea defaultValue={dictContent} placeholder={"keys"} className={style.list}/>
				</div>
				<div className={style.errors}>
					<div className={style.col}>
						<p className={style.header}>{`Unregistered keys [${store.locales.errors.length}]`}</p>
						<textarea defaultValue={dictErrors} placeholder={"No errors keys"} className={style.list}/>
					</div>
					<div className={style.col}>
						<p className={style.header}>{`With default value [${Object.keys(store.locales.unregisteredKeys).length}]`}</p>
						<textarea defaultValue={dictUnreg} placeholder={"No unregistered keys"} className={style.list}/>
					</div>
				</div>
			</div>
		</div>
		: null
}