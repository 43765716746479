import { useUnit } from "effector-react";
import { $notifications } from "./store";

export const useNotifications = () => {
	const data = useUnit({
		notifications: $notifications,
	});

	return {
		...data,
	};
};
