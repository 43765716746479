import { createStore, createEvent } from "effector";
import {Lang} from "../core/locale";
import {WS_ACTION_BETRESULT} from "../core/socket";

export const defaults = {
	gaiti: {
		isSideblock: true
	},
	mobile: {
		landscape: {
			isSideblock: false,
			isSidetable: false,
		},
		portrait: {
			isSideblock: false,
			isSidetable: false,
		}
	},
	portal: {
		isSideblock: false,
		isSidetable: false,
		isFullwidth: true,
	}
}

export type App = {
	isDev: boolean,
	isDebug: boolean,
	isCashdesk: boolean,
	isSideblock: boolean,
	isSidetable: boolean,
	isSmallScreen: boolean,
	isFullwidth: boolean,
	isLandscape: boolean,
	isAllLoaded: boolean,

	gameHeight: number,
	language: Lang,

	socketIsReady: boolean,

	authToken: string,
	origin: string,

	balance: number,
	currency: string,
	roundDelay: number,
	timer: number,
	
	appIsMobile: boolean,

	loadedImagesCount: number

	results: WS_ACTION_BETRESULT[],
}

const mock = {
	isDev: false,
	isDebug: false,
	isCashdesk: true,
	isSideblock: JSON.parse(localStorage.getItem("sblock") || "false"),
	isSidetable: JSON.parse(localStorage.getItem("stable") || (defaults.gaiti.isSideblock ? "true" : "false")),
	isSmallScreen: false,
	isFullwidth: JSON.parse(localStorage.getItem("fwidth") || (defaults.gaiti.isSideblock ? "true" : "false")),
	isLandscape: true,
	isAllLoaded: false,

	gameHeight: 0,
	language: "en" as Lang,

	socketIsReady: false,

	authToken: "demo",
	origin: "",

	balance: 0,
	currency: "",
	roundDelay: 0,
	timer: 0,

	appIsMobile: false,

	loadedImagesCount: 0,

	results: []
}

export const initApp = createEvent<App>();
export const updateFlags = createEvent<object>();
export const setIsDev = createEvent<boolean>();
export const setIsDebug = createEvent<boolean>();
export const setIsCashdesk = createEvent<boolean>();
export const setIsSideblock = createEvent<boolean>();
export const setIsSidetable = createEvent<boolean>();
export const setSocketIsReady = createEvent<boolean>();
export const setAuthToken = createEvent<string>();
export const setOrigin = createEvent<string>();
export const setBalance = createEvent<number>();
export const setGameHeight = createEvent<number>();
export const setCurrency = createEvent<string>();
export const setRoundDelay = createEvent<number>();
export const decreaseTimer = createEvent();
export const resetTimer = createEvent();
export const setAppIsMobile = createEvent<boolean>();
export const setIsSmallScreen = createEvent<boolean>();
export const setIsLandscape = createEvent<boolean>();
export const setIsFullwidth = createEvent<boolean>();
export const addLoadedImagesCount = createEvent();
export const setIsAllLoaded = createEvent<boolean>();
export const addResults = createEvent<WS_ACTION_BETRESULT>();
export const setResults = createEvent<WS_ACTION_BETRESULT[]>();
export const clear = createEvent();

export const $app = createStore<App>(mock)
	.on(initApp, (state, newState:App) => { return { ...state, ...newState } })
	.on(updateFlags, (state, flags:object) => { return {...state, ...flags}; })
	.on(setIsDev, (state, isDev:boolean) => { return {...state, isDev}; })
	.on(setIsDebug, (state, isDebug:boolean) => {
		localStorage.setItem("isdev", JSON.stringify(isDebug));
		return {...state, isDebug};
	})
	.on(setIsCashdesk, (state, isCashdesk:boolean) => { return {...state, isCashdesk: Boolean(isCashdesk)}; })
	.on(setIsSideblock, (state, isSideblock:boolean) => {
		localStorage.setItem("sblock", JSON.stringify(isSideblock));
		return {...state, isSideblock}; }
	)
	.on(setIsSidetable, (state, isSidetable:boolean) => {
		localStorage.setItem("stable", JSON.stringify(isSidetable));
		return {...state, isSidetable}; }
	)
	.on(setSocketIsReady, (state, socketIsReady:boolean) => { return {...state, socketIsReady}; })
	.on(setAuthToken, (state, authToken:string) => {
		localStorage.setItem("token", authToken);
		return {...state, authToken};
	})
	.on(setAppIsMobile, (state, appIsMobile:boolean) => { return {...state, appIsMobile}; })
	.on(setOrigin, (state, origin:string) => { return {...state, origin}; })
	.on(setBalance, (state, balance:number) => { return {...state, balance}; })
	.on(setGameHeight, (state, gameHeight:number) => { return {...state, gameHeight}; })
	.on(setCurrency, (state, currency:string) => { return {...state, currency}; })
	.on(setRoundDelay, (state, roundDelay:number) => { return {...state, roundDelay}; })
	.on(decreaseTimer, (state) => { state.timer--; return {...state}; })
	.on(resetTimer, (state) => { state.timer=state.roundDelay; return {...state}; })
	.on(setIsSmallScreen, (state, isSmallScreen:boolean) => { return {...state, isSmallScreen}; })
	.on(setIsLandscape, (state, isLandscape:boolean) => { return {...state, isLandscape}; })
	.on(setIsFullwidth, (state, isFullwidth:boolean) => {
		localStorage.setItem("fwidth", JSON.stringify(isFullwidth));
		return {...state, isFullwidth};
	})
	.on(addLoadedImagesCount, (state) => {return {...state, loadedImagesCount: state.loadedImagesCount+1 }; })
	.on(setIsAllLoaded, (state, isAllLoaded:boolean) => {return {...state, isAllLoaded }; })
	.on(addResults, (state, betResult:WS_ACTION_BETRESULT) => {
		if(betResult.roundId) state.results.push(betResult);
		return {...state, results:state.results };
	})
	.on(setResults, (state, results:WS_ACTION_BETRESULT[]) => {
		return {...state, results };
	})
	.reset(clear);