import { useCallback, useMemo, useState } from "react";
import { useUnit } from "effector-react";
import {
	$bets,
	$columns,
	$columnsCells,
	$mousePosition,
	$nums,
	$parts,
	$partsCells,
	$rotation,
	$rows,
	$selected,
	$selectedBet,
	$selectedSlots,
	$slots,
	$totalUserBet,
	$user,
	$userBets,
	$userBetsLog,
	$zeroes,
	betSelected,
	blackSelected,
	cellSelected,
	columnsIdsSelected,
	evenSelected,
	highSelected,
	isBetsSending,
	lowSelected,
	mousePositionChanged,
	numIdsSelected,
	oddSelected,
	partsIdsSelected,
	redSelected,
	rotationChanged,
	setRotate,
	rowsIdsSelected,
	selectedBetsAdded,
	selectedBetsSet,
	unselected,
	userAllBetsInPositionRemoved,
	userBetsApplied,
	userBetsCleared,
	userBetsMultiplied,
	userBetsSet,
	userBetsUndo,
	userLastBetInPositionRemoved,
} from "./store";
import { type CellId, type Slot, type SlotId } from "./types";

const getSlotsByParentId: (slots: Slot[], parentId: string) => Slot[] = (slots, cellId) => {
	return slots.filter((slot) => slot.parent === cellId);
};
const getSlotsByParentsIds: (slots: Slot[], parentsIds: string[]) => Slot[] = (slots, parentsIds) => {
	return slots.filter((slot) => {
		return slot.ids.length === parentsIds.length && slot.ids.every(({ id }) => parentsIds.includes(id));
	});
};

export const useRotation = () => {
	const state = useUnit($rotation);
	const actions = useUnit({ rotate: rotationChanged, setRotate: setRotate });
	return { ...state, ...actions };
};
export const useTable = () => {
	const data = useUnit({
		nums: $nums,
		zeroes: $zeroes,
		columns: $columns,
		columnsCells: $columnsCells,
		rows: $rows,
		parts: $parts,
		partsCells: $partsCells,
	});

	const getNumsByNum = useCallback(
		(nums: number[]) => {
			const allNums = [...data.zeroes, ...data.nums];
			return allNums.filter(({ num }) => nums.includes(num));
		},
		[data.zeroes, data.nums],
	);

	return {
		...data,
		getNumsByNum,
	};
};
export const useSelected = () => {
	return useUnit({
		selected: $selected,
		selectOdd: oddSelected,
		selectEven: evenSelected,
		selectLow: lowSelected,
		selectHigh: highSelected,
		selectRed: redSelected,
		selectBlack: blackSelected,
		selectNumIds: numIdsSelected,
		selectColumnsIds: columnsIdsSelected,
		selectRowsIds: rowsIdsSelected,
		selectPartsIds: partsIdsSelected,
		selectCells: cellSelected,
		unselect: unselected,
	});
};
export const useSlots = () => {
	const data = useUnit({
		slots: $slots,
	});

	const getSlotById = (slotId: SlotId) => data.slots.find((slot) => slot.id === slotId) ?? null;
	const getSlotsByParent = (parentId: string) => getSlotsByParentId(data.slots, parentId);
	const getSlotsByCells = (cellsIds: string[]) => getSlotsByParentsIds(data.slots, cellsIds);

	return {
		...data,
		getSlotById,
		getSlotsByParent,
		getSlotsByCells,
	};
};
export const useBets = () => {
	const { slots } = useUnit({ slots: $slots });
	const bets = useUnit({
		bets: $bets,
		selectedBet: $selectedBet,
		selectBet: betSelected,
	});
	const userBets = useUnit({
		userBets: $userBets,
		totalUserBet: $totalUserBet,
		selectedBetsSlots: $selectedSlots,
		clearBets: userBetsCleared,
		setBets: userBetsSet,
		addSelectedBets: selectedBetsAdded,
		setSelectedBets: selectedBetsSet,
		multiplyBets: userBetsMultiplied,
		undoBets: userBetsUndo,
		applyBets: userBetsApplied,
		removePositionLastBet: userLastBetInPositionRemoved,
		removePositionAllBets: userAllBetsInPositionRemoved,
		isSending: isBetsSending,
	});
	const userBetsLog = useUnit({
		userBetsLog: $userBetsLog,
	});

	const getUserBetsFromSlot = ({ slotId }: { slotId: string }) =>
		userBets.userBets.filter((bet) => bet.slotId === slotId);
	const getUserBetsFromCell = ({ cellId }: { cellId: CellId }) => {
		const slotsInCell = getSlotsByParentId(slots, cellId);
		return userBets.userBets.filter((bet) => slotsInCell.some((slot) => slot.id === bet.slotId));
	};
	const getBetInfo = (betId: string) => bets.bets.find((bet) => bet.key === betId);

	return {
		...bets,
		...userBets,
		...userBetsLog,
		getBetInfo,
		userBetsInSlot: getUserBetsFromSlot,
		userBetsInCell: getUserBetsFromCell,
	};
};
export const useMousePosition = () => useUnit({ position: $mousePosition });
export const useMouseUpdate = () => useUnit({ updatePosition: mousePositionChanged });

export const useUser = () => useUnit($user);

const getCenteredElementPosition: (element: Element) => [top: number, left: number] = (element) => {
	const elementPosition = element.getBoundingClientRect();
	const { y, x, width } = elementPosition;
	return [y, x + 0.5 * width];
};
type Prognosis = {
	amount: number;
	prognosis: number;
	position: [top: number, left: number];
};
export const usePrognosis = () => {
	const { bets, userBetsInSlot } = useBets();
	const [hovered, setHovered] = useState<null | { slot: Slot; element: Element }>(null);

	const prognosis = useMemo<Prognosis | null>(() => {
		if (!hovered) return null;

		const slotId = hovered.slot.id;
		const betsInSlot = userBetsInSlot({ slotId });

		if (betsInSlot.length > 0) {
			const amount = betsInSlot.reduce((prev, userBet) => {
				const bet = bets.find(({ key }) => key === userBet.betKey);
				return bet ? prev + bet.value : prev;
			}, 0);
			const prognosis = amount * hovered.slot.multiply;
			const position = getCenteredElementPosition(hovered.element);
			return { amount, prognosis, position };
		} else {
			return null;
		}
	}, [bets, hovered, userBetsInSlot]);

	return { prognosis, setHovered };
};
