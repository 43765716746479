import React, { RefObject, useEffect, useMemo, useRef } from "react";
import { useUnit } from "effector-react";
import Locale from "../core/locale";
import { $locales } from "../store/locales";

type UseOutsideClick = <T extends HTMLElement>(data: {
	/** Callback */
	onOutsideClick?: () => void;
	/** List of elements ignored reaction */
	ignored?: RefObject<HTMLElement>[];
}) => React.MutableRefObject<T>;
export const useOutsideClick: UseOutsideClick = ({ onOutsideClick, ignored = [] }) => {
	const ref = useRef<any>(null);

	useEffect(() => {
		const handleClick = (event: MouseEvent | TouchEvent) => {
			const target = event.target as Node;
			if (!target || !target.isConnected) return;
			if (ignored.some((ref) => ref.current && ref.current.contains(target))) return;
			if (ref.current && !ref.current.contains(target)) {
				event.stopPropagation();
				event.stopImmediatePropagation();
				event.preventDefault();
				onOutsideClick?.();
			}
		};

		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, [ignored, onOutsideClick, ref]);

	return ref;
};

export const useTranslation = () => {
	const locales = useUnit($locales);
	return useMemo(() => ({ t: Locale.Get }), [locales.lang]);
};
