import style from "./style.module.scss";
import {useStore, useUnit} from "effector-react";
import {$store} from "../../store";
import {SocketControls} from "../debug/socketControls/SocketControls";
import React, {Fragment, useCallback} from "react";
import { Switch } from "../debug/primitives/switch/Switch";
import {setIsDebug, setIsFullwidth, setIsSideblock} from "../../store/app";
import Locale from "../../core/locale";
import {LocalesControls} from "../debug/localesControls/LocalesControls";
import { ReactComponent as Fullwidth } from "../../img/icons/fullwidth.svg";
import {cn} from "../../shared/lib/classNames";
import {Button} from "../../shared/ui/button";
import { ReactComponent as SideListOpen } from "../../img/icons/sidebar-open.svg";
import { ReactComponent as SideListClose } from "../../img/icons/sidebar-close.svg";
import {useRotation} from "../../features/table";

export const Header = ()=>{
    const store = useStore($store);
	const rotation = useRotation();

    return (
		<div className={style.header}>
			<div style={{display:"flex",alignItems:"center",height:"100%"}}>
				{ !store.app.appIsMobile &&
                    <Fullwidth height={18} width={18} style={{cursor:"pointer"}} onClick={()=> {setIsFullwidth(!store.app.isFullwidth)}}/>
				}
				{store.app.isDev && !store.app.appIsMobile && (
					<div
						style={{
							height: "80%",
							display: "flex",
							gap: "clamp(4px,1vh,8px)",
							alignItems: "center",
							lineHeight: "0",
							fontSize: ".8rem",
							background: "rgba(20, 20, 20, .2)",
							padding: ".5rem",
						}}
					>
						<Switch bool={store.app.isDebug} onClick={setIsDebug} /> Debug
					</div>
				)}
				{ store.app.isDev && !store.app.appIsMobile &&
					// <Link to={"/page"}>PAGE</Link>
					<p onClick={()=>{
						rotation.rotate();
					}}>ROT</p>
				}
				{store.app.isDebug && (
					<Fragment>
						<div style={{
							height: "80%",
							fontSize: ".8rem",
							background: "rgba(0,0,0,0.2)",
							display: "flex",
							alignItems: "center",
						}}
						>
							<SocketControls />
							<LocalesControls />
						</div>
						<p style={{
							height: "80%",
							fontSize: ".8rem",
							background: "rgba(20, 20, 20, .2)",
							padding: ".5rem",
						}}
						>{`State: ${store.game.roundState || "???"}`}</p>
					</Fragment>
				)}
			</div>
			<div className={style.right}>
				<div className={style.balance}>
					<p className={style.label}>{Locale.Get("balance").toUpperCase()}</p>
					<p className={style.value}>{`${(store.app.balance/100).toFixed(2)} ${store.app.currency}`}</p>
				</div>
				{ store.app.isCashdesk && !store.app.appIsMobile && store.app.origin!=="portal"
					? <Button
						className={cn(style.sideList, store.app.isSideblock && style.active)}
						variant={"contained"}
						colored={"basic"}
						onClick={()=>setIsSideblock(!store.app.isSideblock)}
						aria-label={store.app.isSideblock ? "Hide side list " : "Show side list"}
					> { store.app.isSideblock
						? <SideListClose height={20} width={20} />
						: <SideListOpen height={20} width={20} />
					}
					</Button>
					: null
				}
			</div>
		</div>
	);
}