import {decreaseTimer, resetTimer} from "../store/app";
import {$store} from "../store";

export default class Time {
	private static timer:number;
	public static startService() {
		resetTimer();
		Time.timer = window.setInterval(Time.timerTickSeconds,1000);
	}

	public static stopService() {
		clearInterval(Time.timer);
	}

	private static timerTickSeconds() {
		decreaseTimer();
		if($store.getState().app.timer<=0) {
			resetTimer();
		}
	}

	public static GetTimeH=():number=>{
		return Number(Time.GetTimeHMS().split(":")[0])
	}

	public static GetTimeHMS=():string=> {
		return new Date().toLocaleTimeString();
	}
}