import { attach, createEvent, createStore, sample, createEffect, combine } from "effector";
import Socket, { WS_ACTION_BETRESULT } from "../../core/socket";

import { TICKET_STATUS } from "./config";
import { type HistoryItem } from "./types";
import { Num, TicketStatus } from "../table";

export const $betsHistory = createStore<HistoryItem[]>([]);
export const $betsFilters = createStore<{
	id: HistoryItem["id"] | null;
}>({ id: null });
export const $lastBetStatus = createStore<null | TicketStatus>(null);

export const $filteredBets = combine([$betsHistory, $betsFilters], ([history, filters]) => {
	let items = [...history];
	if (filters.id !== null) {
		items = items.filter((ticket) => (filters.id ? ticket.id.toString().includes(filters.id.toString()) : true));
	}
	return items;
});

export const addToHistory = createEvent<HistoryItem>();
export const historyShown = createEvent();
export const userBetCanceled = createEvent<{ betId: HistoryItem["id"] }>();
export const filtersCleared = createEvent();
export const filterIdChanged = createEvent<HistoryItem["id"] | null>();

export const updateHistoryFx = attach({
	source: { $betsHistory },
	effect: async ({ $betsHistory: history }, { allNums, update }: { allNums: Num[]; update: WS_ACTION_BETRESULT }) => {
		const clonedHistory: HistoryItem[] = structuredClone(history);
		const itemIndex = clonedHistory.findIndex((item) => item.id === update.betId);
		if (itemIndex === -1) return history;
		const item: HistoryItem = clonedHistory[itemIndex];
		item.resultBall = allNums.find(({ num }) => num === update.ball) ?? null;
		for (const part of update.parts) {
			const ticket = item.data.find((ticket) => ticket.slotId === part.slotId);
			if (!ticket) continue;
			ticket.amount = part.amount;
			ticket.status = part.win ? TICKET_STATUS.paid : TICKET_STATUS.lost;
			ticket.paid = part.win;
		}
		return clonedHistory;
	},
});
export const cancelBetFx = createEffect(async ({ betId }: { betId: HistoryItem["id"] }) => {
	const response = await Socket.cancelBet({ betId });
	return { betId: response.betId, status: TICKET_STATUS.canceled };
});

sample({
	clock: $betsHistory,
	fn: (state) => {
		const lastItem = state.at(-1);
		if (!lastItem) return null;
		const hasPayout = lastItem.data.some((ticket) => ticket.status === TICKET_STATUS.paid);
		const hasPayment = lastItem.data.some((ticket) => ticket.status === TICKET_STATUS.lost);
		if (hasPayout) return TICKET_STATUS.paid;
		if (hasPayment) return TICKET_STATUS.lost;
		return null;
	},
	target: $lastBetStatus,
});
sample({
	clock: historyShown,
	fn: () => null,
	target: $lastBetStatus,
});
sample({
	clock: userBetCanceled,
	target: cancelBetFx,
});
sample({
	clock: cancelBetFx.doneData,
	source: $betsHistory,
	fn: (history, { betId, status }) => {
		return history.map((item) =>
			item.id !== betId ? item : { ...item, data: item.data.map((ticket) => ({ ...ticket, status })) },
		);
	},
	target: $betsHistory,
});

$betsFilters.reset(filtersCleared);
sample({
	clock: filterIdChanged,
	source: $betsFilters,
	fn: (filters, id) => ({ ...filters, id }),
	target: $betsFilters,
});
