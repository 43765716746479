import style from "./style.module.scss";
import { cn } from "../../../../shared/lib/classNames";
import { type HistoryItem, type Ticket as TicketType } from "../../types";
import { Status } from "../status";
import { useTable } from "../../../table";
import { useTranslation } from "../../../../shared/hooks";

export interface TicketProps {
	item: HistoryItem;
	ticket: TicketType;
	showNumbers: boolean;
	onTicketClick?: (ticket: TicketType, item: HistoryItem) => void;
}

export const Ticket = ({ item, ticket, showNumbers, onTicketClick }: TicketProps) => {
	const { t } = useTranslation();
	const { getNumsByNum } = useTable();
	const selectedNumsTitles = getNumsByNum(ticket.selectedNums).map((n) => n.title);

	const handleClick = () => {
		onTicketClick?.(ticket, item);
	};

	return (
		<div className={cn(style.ticket, !!onTicketClick && style.interactive)} onClick={handleClick}>
			<Status status={ticket.status} className={style.status} />
			<div className={style.info}>
				<span>{`${t("ticket.round")}: `}</span>
				<span className={style.round}>{item.roundId}</span>
				<span>{" | "}</span>
				<span className={style.combo}>{t(ticket.combinationKey.key, ticket.combinationKey.args)}</span>
			</div>
			{showNumbers && <div className={style.cells}>{selectedNumsTitles.join("-")}</div>}
		</div>
	);
};
