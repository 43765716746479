import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$store} from "../../store";
import {Fragment} from "react";
import {useTable} from "../../features/table";
import {useHistory} from "../../features/history";
import Locale from "../../core/locale";
import {getFormatDate} from "../../features/history/helpers";

export const Ticket = (props: { id: number }) => {
	const store = useStore($store);
	const {getNumsByNum} = useTable();
	const {getHistoryItem} = useHistory();
	// FIXME: potential error
	const bet = getHistoryItem(props.id)!;
	const styleInline = {
		block: {
			margin: 0,
			borderTop: "1px solid black",
			// padding:"1mm",
		},
		clean: {
			padding: "0",
			margin: "0",
		},
		wrapper: {
			width: "60mm",
			background: "white",
			color: "black",
			fontSize: "14px",
			overflowWrap: "anywhere",
		},
		bets: {
			//border:"1px solid black",
		},
		caption: {
			textAlign: "center",
			// padding: "4mm"
		},
		table: {
			maxWidth: "100%",
			pointerEvents: "all",
			//padding: "2mm",
		},
		hor: {
			width: "100%",
			display: "flex",
			gap: "10px",
			justifyContent: "space-between",
		},
		numbers: {
			// width: "70%",
			// fontSize: "10px",
		},
		bold: {
			fontWeight: "bold",
		},
	} as const;

	return (
		<div className={style.ticket} style={styleInline.wrapper}>
			<div style={styleInline.table}>
				<p style={styleInline.caption}>{`- ${Locale.Get("game_a_roulette")} -`}</p>
				<div style={{...styleInline.hor, ...styleInline.bold}}>
					<p style={styleInline.clean}>{`${Locale.Get("round")} ${bet?.roundId}`}</p>
					<p style={styleInline.clean}>{`${Locale.Get("ticket")} ${bet?.id}`}</p>
				</div>
				<br/>
				<div style={styleInline.bets}>
					{bet?.data.map((el) => {
						const selectedNumsTitles = getNumsByNum(el.selectedNums).map((n) => n.title);
						return (
							<Fragment key={el.id}>
								<div style={styleInline.block}>
									<div style={styleInline.hor}>
										<p style={{...styleInline.bold, ...styleInline.clean}}>
											{Locale.Get(el.combinationKey.key, el.combinationKey.args)}
										</p>
										<p style={styleInline.clean}
										>{`${(el.amount / 100).toFixed(2)} ${store.app.currency}`}</p>
									</div>
									<div style={{...styleInline.hor}}>
										<p style={{...styleInline.numbers, ...styleInline.clean}}>
											{selectedNumsTitles.join(", ")}
										</p>
										<p style={{...styleInline.clean, textWrap: "nowrap"}}>{`x${el.combinationMultiply} = ${(el.amount*el.combinationMultiply/100).toFixed(2)} ${store.app.currency}`}</p>
									</div>
								</div>
							</Fragment>
						);
					})}
				</div>
				<br/>
				<div style={{...styleInline.hor, ...styleInline.bold}}>
					<p style={styleInline.clean}>{`${Locale.Get("total")}:`}</p>
					<p style={styleInline.clean}
					>{`${(bet?.data?.reduce((sum, a) => sum + a.amount, 0) / 100).toFixed(2)} ${store.app.currency}`}</p>
				</div>
				<div style={{...styleInline.hor, ...styleInline.bold}}>
					{/*<p style={styleInline.clean}>{`Time:`}</p>*/}
					<p style={styleInline.clean}>{`${getFormatDate(bet.date)}`}</p>
				</div>
				<div style={{...styleInline.hor, ...styleInline.bold}}>
					<p style={styleInline.clean}>{`Pin:`}</p>
					<p style={styleInline.clean}>{`${bet?.pin}`}</p>
				</div>
			</div>
		</div>
	);
};
