import { createVariants } from "../../../shared/lib/classVariants";
import { TICKET_STATUS } from "../config";
import colors from "./colors.module.scss";

export const statusColorVariant = createVariants("", {
	variants: {
		status: {
			default: "",
			[TICKET_STATUS.open]: colors.statusOpened,
			[TICKET_STATUS.paid]: colors.statusPaidOut,
			[TICKET_STATUS.lost]: colors.statusLost,
			[TICKET_STATUS.canceled]: colors.statusCanceled,
		},
	},
	defaultVariants: {
		status: "default",
	},
});
