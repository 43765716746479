export default class Utils {
	static StringReplaceAt(str: string, index: number, replacement: string): string {
		return str.substring(0, index) + replacement + str.substring(index + replacement.length);
	}

	static getRandomHexColor() {
		const letters = "0123456789ABCDEF";
		let color = "#";
		for (let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	static arrFromCount(count: number) {
		let counter = 0;
		const newDigitArr: number[] = [];
		while (counter < count) {
			newDigitArr.push(counter);
			counter++;
		}
		return newDigitArr;
	}

	static getRandomId = (() => {
		let lastId = 0;
		return (): `${string}-${string}-${string}-${string}` => {
			lastId += 1;
			const chunkLength = 6;
			const date = Date.now().toString(16);
			const dateStart = date.substring(0, chunkLength).padEnd(chunkLength, "0");
			const dateEnd = date.substring(chunkLength, 2 * chunkLength).padEnd(chunkLength, "0");
			const hex = Math.floor(Math.random() * 0xffffff)
				.toString(16)
				.padEnd(chunkLength, "0");
			const id = lastId.toString(16).padStart(chunkLength, "0");
			return `${dateStart}-${dateEnd}-${hex}-${id}`;
		};
	})();

	static RandomWithSeed = (seed:number, min:number=0, max:number=1)=>{
		seed = Math.sin(seed) * 10000;
		return Math.floor((seed - Math.floor(seed)) * (max - min + 1) + min);
	};

	public static normalize360(angle:number) {
		angle = angle % 360;
		if (angle < 0) angle = angle + 360;
		return angle;
	}
}
