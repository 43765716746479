import { useLayoutEffect } from "react";
import { useStore } from "effector-react";
import style from "./style.module.scss";
import { $store } from "../../store";
import { Table } from "../table/Table";
import { selectNumber } from "../../store/table";
import Locale from "../../core/locale";
import {BetsTable} from "../betsTable/BetsTable";

export const RoundResult = () => {
	const store = useStore($store);
	useLayoutEffect(() => {
		selectNumber(store.game.nextNumber.num);
	}, []);
	return (
		<div className={style.result}>
			<div className={style.wrap}>
				<div className={style.left}>
					<p className={style.title}>{Locale.Get("result.title").toUpperCase()}</p>
					<p className={style.description}>{Locale.Get("result.win_number").toUpperCase()}</p>
					<p style={{ background: store.game.nextNumber.color }} className={style.number}>
						{store.game.nextNumber.num !== 100 ? store.game.nextNumber.num : "00"}
					</p>
				</div>
				<div className={style.right}>
					<div className={style.title}>
						<p style={{color: "white"}}>{Locale.Get("result.win_games").toUpperCase()}</p>
						<p style={{fontSize: "3.3vh"}}>{Locale.Get("result.table_betting").toUpperCase()}</p>
					</div>
					<Table/>
				</div>
			</div>
		</div>
	);
};
