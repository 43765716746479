import React, { useMemo } from "react";
import style from "./style.module.scss";
import { type HistoryItem } from "../../types";
import { TICKET_STATUS } from "../../config";
import { getAmount } from "../../helpers";
import { cn } from "../../../../shared/lib/classNames";
import { Status } from "../status";
import { ShortInfo } from "../shortInfo";
import { DetailedTicket, NumberLabel } from "../detailedTicket";
import { TotalsInfo } from "../totalsInfo";
import { Button } from "../../../../shared/ui/button";
import { useHistory } from "../../hooks";
import { useTranslation } from "../../../../shared/hooks";

export const ModalStory = ({
	children,
	onClose,
}: React.PropsWithChildren<{
	onClose: () => void;
}>) => (
	<>
		<div
			className={style.popupLayout}
			onClick={(event) => {
				event.nativeEvent.stopImmediatePropagation();
				onClose();
			}}
		/>
		<div className={style.popup}>{children}</div>
	</>
);

export const SingleItemModal = ({
	itemId,
	onRebetClick,
	onClose,
}: {
	itemId: HistoryItem["id"];
	onRebetClick?: (item: HistoryItem) => void;
	onClose?: () => void;
}) => {
	const { t } = useTranslation();
	const { betsHistory } = useHistory();
	const item = useMemo(() => {
		return betsHistory.find(({ id }) => id === itemId);
	}, [betsHistory, itemId]);

	const status = useMemo(() => {
		const hasPaidOut = item?.data.some(({ status }) => status === TICKET_STATUS.paid);
		if (hasPaidOut) return TICKET_STATUS.paid;
		const hasLost = item?.data.some(({ status }) => status === TICKET_STATUS.lost);
		if (hasLost) return TICKET_STATUS.lost;
		return TICKET_STATUS.open;
	}, [item]);

	const totalPayment = useMemo(() => {
		const total = item?.data.reduce((prev, { amount }) => prev + amount, 0);
		return getAmount(total ?? 0);
	}, [item]);

	const totalPayOut = useMemo(() => {
		const total = item?.data.reduce((prev, { paid }) => prev + paid, 0);
		return getAmount(total ?? 0);
	}, [item]);

	const handleRebet = () => {
		if (item) onRebetClick?.(item);
	};

	if (!item) return <div>{t("modals.undefined_item")}</div>;

	return (
		<div className={style.single}>
			<div className={style.top}>
				<span className={style.modalTitle}>{"Roulette"}</span>
				<Status status={status} />
			</div>
			{item.resultBall ? (
				<div className={style.result}>
					<span className={style.title}>{`${t("modals.round_result")}:`}</span>
					<NumberLabel className={style.number} num={item.resultBall} />
				</div>
			) : null}
			<ShortInfo id={item.id} roundId={item.roundId} date={item.date} />
			<div className={style.body}>
				<div className={cn(style.list, "scrollbar")}>
					{item.data.map((ticket) => (
						<DetailedTicket key={ticket.id} ticket={ticket} />
					))}
				</div>
			</div>
			<TotalsInfo payment={totalPayment} payout={totalPayOut} />
			<Controls onClose={onClose} onRebetClick={handleRebet} />
		</div>
	);
};

const Controls = ({ onClose, onRebetClick }: { onClose?: () => void; onRebetClick?: () => void }) => {
	const { t } = useTranslation();

	return (
		<div className={style.controls}>
			{onRebetClick ? (
				<Button
					className={style.rebet}
					variant={"contained"}
					colored={"basic"}
					size={"lg"}
					onClick={onRebetClick}
					aria-label={t("modals_controls.rebet")}
				>
					{t("modals_controls.rebet")}
				</Button>
			) : null}
			<Button
				className={style.close}
				variant={"text"}
				colored={"payIn"}
				onClick={onClose}
				aria-label={t("modals_controls.close")}
			>
				{t("modals_controls.close")}
			</Button>
		</div>
	);
};
