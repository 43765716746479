import { type SVGProps, useMemo } from "react";
import { ReactComponent as InfoIcon } from "../../../shared/assets/information_bold.svg";
import { ReactComponent as StopIcon } from "../../../shared/assets/stop.svg";
import { ReactComponent as AttentionIcon } from "../../../shared/assets/attention-circle.svg";
import { ReactComponent as SuccessIcon } from "../../../shared/assets/success-tick.svg";
import { NOTIFICATION_TYPE } from "../config";

export const StatusIcon = ({ status, ...props }: { status?: string } & SVGProps<SVGSVGElement>) => {
	const Icon = useMemo(() => {
		switch (status) {
			case NOTIFICATION_TYPE.error:
				return StopIcon;
			case NOTIFICATION_TYPE.warning:
				return StopIcon;
			case NOTIFICATION_TYPE.attention:
				return AttentionIcon;
			case NOTIFICATION_TYPE.success:
				return SuccessIcon;
			default:
				return InfoIcon;
		}
	}, [status]);

	return <Icon {...props} />;
};
