import { createEvent, createStore, sample } from "effector";
import { authed } from "../../core/socket";

export const $auth = createStore({ isAuthenticated: false });

export const authStatusChanged = createEvent<boolean>();

sample({
	clock: authStatusChanged,
	source: $auth,
	fn: (state, isAuthenticated) => ({
		...state,
		isAuthenticated,
	}),
	target: $auth,
});

sample({
	clock: authed,
	fn: () => true,
	target: authStatusChanged,
});
