export default class Easing {
	static fmod(a: number, b: number) {
		return a - b * Math.floor(a / b);
	}

	static easeInSine(x: number): number {
		return 1 - Math.cos((x * Math.PI) / 2);
	}
	static easeInOutBack(x: number): number {
		const c1 = 1.70158;
		const c2 = c1 * 1.525;
		return x < 0.5
			? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
			: (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
	}
	static easeOutSine(x: number): number {
		return Math.sin((x * Math.PI) / 2);
	}
	static easeOutCirc(x: number): number {
		return Math.sqrt(1 - Math.pow(x - 1, 2));
	}
	static easeInOutCirc(x: number): number {
		return x < 0.5 ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2 : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
	}
	static easeOutExpo(x: number): number {
		return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
	}
	static easeInOutSine(x: number): number {
		return -(Math.cos(Math.PI * x) - 1) / 2;
	}
	static easeInOutQuad(x: number): number {
		return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
	} //
	static easeInBounce(x: number): number {
		return 1 - Easing.easeOutBounce(1 - x);
	} //
	static easeInQuint(x: number): number {
		return x * x * x * x * x;
	} //
	static easeOutQuint(x: number): number {
		return 1 - Math.pow(1 - x, 5);
	}
	static test(x: number): number {
		x = Easing.fmod(x, 1);
		const step = Math.pow(2, Math.floor(Math.log2(1 - x)));
		const slope = Easing.fmod(x / step, 1);
		return Math.pow(2 * step, 2) * (-4 * Math.pow(slope - 0.5, 2) + 1);
	}
	static easeOutBounce(x: number): number {
		const n1 = 7.5625;
		const d1 = 2.75;
		if (x < 1 / d1) return n1 * x * x;
		else if (x < 2 / d1) return n1 * (x -= 1.5 / d1) * x + 0.75;
		else if (x < 2.5 / d1) return n1 * (x -= 2.25 / d1) * x + 0.9375;
		else return n1 * (x -= 2.625 / d1) * x + 0.984375;
	}
	static easeInOutBounce(x: number): number {
		return x < 0.5 ? (1 - Easing.easeOutBounce(1 - 2 * x)) / 2 : (1 + Easing.easeOutBounce(2 * x - 1)) / 2;
	}
	static easeInBack(x: number): number {
		const c1 = 1.70158;
		const c3 = c1 + 1;
		return c3 * x * x * x - c1 * x * x;
	}

	// NOT WORK ?
	static easeInOutElastic(x: number): number {
		const c5 = (2 * Math.PI) / 4.5;
		return x === 0
			? 0
			: x === 1
				? 1
				: x < 0.5
					? -(Math.pow(2, 20 * x - 10) * Math.sin((20 * x - 11.125) * c5)) / 2
					: (Math.pow(2, -20 * x + 10) * Math.sin((20 * x - 11.125) * c5)) / 2 + 1;
	}
	static easeInElastic(x: number): number {
		const c4 = (2 * Math.PI) / 3;
		return x === 0 ? 0 : x === 1 ? 1 : -Math.pow(2, 10 * x - 10) * Math.sin((x * 10 - 10.75) * c4);
	}
	static easeOutElastic(x: number): number {
		const c4 = (2 * Math.PI) / 3;
		return x === 0 ? 0 : x === 1 ? 1 : Math.pow(2, -10 * x) * Math.sin((x * 10 - 0.75) * c4) + 1;
	}
	static easeOutBack(x: number): number {
		const c1 = 1.70158;
		const c3 = c1 + 1;
		return 1 + c3 * Math.pow(x - 1, 3) + c1 * Math.pow(x - 1, 2);
	}
}
