export default class URL
{
	static UrlParams() {
		let queryString = window.location.href ? window.location.href.split('?')[1] : window.location.search.slice(1); // извлекаем строку из URL или объекта window
		const obj = {}; // объект для хранения параметров
		// если есть строка запроса
		if (queryString) {
			queryString = queryString.split('#')[0];	// данные после знака # будут опущены
			const arr = queryString.split('&');			// разделяем параметры
			for (let i=0; i<arr.length; i++) {
				const a = arr[i].split('=');			// разделяем параметр на ключ => значение
				// обработка данных вида: list[]=thing1&list[]=thing2
				let paramNum = '';
				let paramName = a[0].replace(/\[\d*\]/, function(v) {
					paramNum = v.slice(1,-1);
					return '';
				});
				// передача значения параметра ('true' если значение не задано)
				let paramValue = typeof(a[1])==='undefined' ? true : a[1];
				// преобразование регистра
				// paramName	= paramName.toLowerCase();
				// paramValue	= paramValue.toString().toLowerCase();
				// если ключ параметра уже задан
				if (obj[paramName]) {
					if (typeof obj[paramName] === 'string') { obj[paramName] = [obj[paramName]];	 }	// преобразуем текущее значение в массив
					if (typeof paramNum === 'undefined') 	{ obj[paramName].push(paramValue);		 }	// если не задан индекс, то в конец массива.
					else 									{ obj[paramName][paramNum] = paramValue; }	// если индекс задан...
				}
				else { obj[paramName] = paramValue; } // если параметр не задан, делаем это вручную
			}
		}
		return obj;
		// const params = new URLSearchParams(window.location.search);
		// const objParams = Object.fromEntries(params,entries())
		// return params; // objParams;
	}
}