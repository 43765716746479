import { createStore, createEvent } from "effector";
import Constants, { NUMBER } from "../core/constants";

export type Stats = {
	lastNumbers: NUMBER[]
}

const mock = {
	lastNumbers: [],
}

export const setLastNumbers = createEvent<number[]>();
export const clear = createEvent();

export const $stats = createStore<Stats>(mock)
	.on(setLastNumbers, (state, numbers:number[]) => {
		const lastNumbers: NUMBER[] = [];
		for (const num of numbers) {
			lastNumbers.push(Constants.NUMBERS.find((el:NUMBER)=>el.num===num)!);
		}
		return {...state, lastNumbers};
	})
	.reset(clear);