import React, {Fragment, memo} from "react";
import {useStore} from "effector-react";
import style from "./style.module.scss";
import {$store} from "../../store";
import Locale from "../../core/locale";
import {setResults} from "../../store/app";
import {Button} from "../../shared/ui/button";
import {$slots} from "../../features/table/store";
import {SlotId, useSlots} from "../../features/table";

export const BetsTable = memo(() => {
	const store = useStore($store);
	const slots = useSlots();

	return (
		<div className={style.table}>
			<div className={style.wrapper}>
				<table>
					<thead>
					<tr>
						<th scope="col">№</th>
						<th scope="col">{Locale.Get("bet")}</th>
						<th scope="col">{Locale.Get("amount")}</th>
						<th scope="col">X</th>
						<th scope="col">{Locale.Get("detailed.win")}</th>
						<th scope="col">{Locale.Get("total")}</th>
					</tr>
					</thead>
					<tbody>
					{store.app.results.map((bet) =>
						<tr className={style.bet} key={bet.betId}>
							<th scope="row" style={{whiteSpace: "nowrap"}}>{bet.betId}</th>
							<th scope="row" style={{padding: "0"}}>
								{bet.parts.map((el, i) =>
									<tr style={{display: "flex", placeContent: "center"}}>
										<td style={{borderLeft: "1px", fontSize: "1.3vw"}}>{
											el.nums.map((num, i) => {
												return num === 100
													? i !== 0
														? ",00" : "00"
													: i !== 0
														? `,${num}` : num;
											})
										}</td>
									</tr>
								)}
							</th>
							<th scope="row" style={{padding: "0"}}>
								{bet.parts.map((el, i) =>
									<tr style={{display: "flex", placeContent: "center"}}>
										<td style={{borderRight: "0"}}>{el.amount / 100}</td>
									</tr>
								)}
							</th>
							<th scope="row" style={{padding: "0"}}>
								{bet.parts.map((el, i) => {
										return <tr style={{display: "flex", placeContent: "center"}}>
											<td style={{borderRight: "0"}}>{`x${slots.getSlotById(el.slotId as SlotId)?.multiply || -1}`}</td>
										</tr>
									}
								)}
							</th>
							<th scope="row" style={{padding: "0"}}>
								{bet.parts.map((el, i) =>
									<tr style={{display: "flex", placeContent: "center"}}>
										<td style={{borderRight: "0"}}>{el.win / 100}</td>
									</tr>
								)}
							</th>
							<th scope="row">{bet.allWin / 100}</th>
						</tr>
					)}
					</tbody>
				</table>
			</div>
			<Button
				style={{
					width: "35vh",
					height: "8vh",
					marginTop: "5vh",
					float: "right",
					fontSize: "3vh",
					fontWeight: "bold"
				}}
				variant={"contained"}
				colored={"payIn"}
				onClick={() => {
					setResults([])
				}}
			>
				{Locale.Get("ok")}
			</Button>
		</div>
	);
});


// <table>
// 	<caption>
// 		Front-end web developer course 2021
// 	</caption>
// 	<thead>
// 	<tr>
// 		<th scope="col">Person</th>
// 		<th scope="col">Most interest in</th>
// 		<th scope="col">Age</th>
// 	</tr>
// 	</thead>
// 	<tbody>
// 	<tr>
// 		<th scope="row">Chris</th>
// 		<td>HTML tables</td>
// 		<td>22</td>
// 	</tr>
// 	<tr>
// 		<th scope="row">Dennis</th>
// 		<td>Web accessibility</td>
// 		<td>45</td>
// 	</tr>
// 	<tr>
// 		<th scope="row">Sarah</th>
// 		<td>JavaScript frameworks</td>
// 		<td>29</td>
// 	</tr>
// 	<tr>
// 		<th scope="row">Karen</th>
// 		<td>Web performance</td>
// 		<td>36</td>
// 	</tr>
// 	</tbody>
// 	<tfoot>
// 	<tr>
// 		<th scope="row" colSpan="2">Average age</th>
// 		<td>33</td>
// 	</tr>
// 	</tfoot>
// </table>