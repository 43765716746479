import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$store} from "../../../store";
import {Bool} from "../primitives/bool/Bool";
import Socket from "../../../core/socket";
import {setDictDebug} from "../../../store/locales";

export const LocalesControls = ()=>{
    const store = useStore($store);
    return <div className={`${style.localesControls}`}>
        <div className={style.btn} onClick={()=>setDictDebug(true)}>
            <p>{"Locales"}</p>
        </div>
        <div className={style.group}>
            <p>{`Errors ${store.locales.errors.length}`}</p>
            <Bool bool={!store.locales.errors.length}/>
        </div>
    </div>
}