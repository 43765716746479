import { useLayoutEffect } from "react";
import { useStore } from "effector-react";
import style from "./style.module.scss";
import { $store } from "../../store";
import { clearHighlight } from "../../store/table";
import { SECTIONS_ANIM, setAnimIndex, setVariant } from "../../store/variant";
import Locale from "../../core/locale";
import { useTranslation } from "../../shared/hooks";

let animTimeout: NodeJS.Timeout;

export const Variant = () => {
	const store = useStore($store);

	useLayoutEffect(() => {
		setAnimIndex(0); // Init first variant visualisation
		setVariant(SECTIONS_ANIM[0]); // Init first variant visualisation
		const timeoutId = setTimeout(() => nextVariant(), 2000);
		return () => {
			clearTimeout(animTimeout);
			clearHighlight();
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<div className={style.variant}>
			<p className={style.label}>{Locale.Get("game_bettings").toUpperCase()}</p>
			<div className={style.info}>
				{store.app.isDebug && (
					<div className={style.debug}>
						<p onClick={nextVariant}>{"Next".toUpperCase()}</p>
						<a>{`Index: ${store.variant.index}`}</a>
						<a>{`F: ${SECTIONS_ANIM[store.variant.index].flag}`}</a>
					</div>
				)}
				<div className={style.titleWrap}>
					<p className={style.title}>{Locale.Get(store.variant.anim.title).toUpperCase()}</p>
					<p className={style.description}>{Locale.Get(store.variant.anim.subtitle).toUpperCase()}</p>
				</div>
				<div className={style.valWrap}>
					<p className={style.title}>{`${store.variant.anim.value},00`}</p>
					<p className={style.description}>{"X wager".toUpperCase()}</p>
				</div>
			</div>
		</div>
	);
};

const nextVariant = () => {
	clearTimeout(animTimeout);

	if (SECTIONS_ANIM[$store.getState().variant.index + 1]) {
		setAnimIndex($store.getState().variant.index + 1);
		setVariant(SECTIONS_ANIM[$store.getState().variant.index]);
	} else {
		setAnimIndex(0);
		setVariant(SECTIONS_ANIM[0]);
	}

	animTimeout = setTimeout(() => {
		nextVariant();
	}, SECTIONS_ANIM[$store.getState().variant.index].delay);
};
