import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Fonts
import "./fonts/Nunito-Black.ttf";

// Helpers
import URL from "./core/url";
import Time from "./core/time";
import Socket from "./core/socket";
import { Lang } from "./core/locale";
import { setAuthToken, setIsDebug, setIsDev, setOrigin } from "./store/app";
import { setLanguage } from "./store/locales";

// Pages
import App from "./App";
import { Game } from "./components/game/Game";
const Page = lazy(() => import("./page").then((f) => ({ default: f.Page })));
const GraphPage = lazy(() => import("./features/ballMovement").then((f) => ({ default: f.GraphPage })));
import Display from "./core/display";

setIsDev(process.env.NODE_ENV === "development");
setIsDebug(process.env.NODE_ENV === "development" && JSON.parse(localStorage.getItem("isdev") || "false"));

const urlParams = URL.UrlParams() as { token: string, origin:string }
const token = (urlParams.token || localStorage.getItem("token") || "debug");
const origin = urlParams.origin;
setAuthToken(token);
setOrigin(origin);

const lang = (URL.UrlParams() as { language: string })?.language || localStorage.getItem("lang") || "en";
setLanguage(lang.slice(0, 2) as Lang);

window.addEventListener("resize", () => {
	//const gameHeight:number = document.getElementById("game")!.clientHeight;
	//console.log(document.getElementById("game")!.clientHeight)
	//setGameHeight(gameHeight);
});

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "/roulette",
		element: <Game onToScreen={() => {}} onToHome={() => {}} />,
	},
	{
		path: "/page",
		element: (
			<Suspense fallback={<p>{"Loading..."}</p>}>
				<Page />
			</Suspense>
		),
	},
	{
		path: "/graph",
		element: (
			<Suspense fallback={<p>{"Loading..."}</p>}>
				<GraphPage />
			</Suspense>
		),
	},
]);

Time.startService();
Display.startService();
Socket.startService();

createRoot(document.getElementById("app")!).render(<RouterProvider router={router} />);
export { getAmount } from "./features/history/helpers";
