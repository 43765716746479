import {setAppIsMobile, setGameHeight, setIsLandscape, setIsSmallScreen} from "../store/app";

export default class Display {

	static appMobileWidth = 830;
	static appSmallHeight = 700;

	static startService() {
		window.addEventListener('resize', Display.windowOnResize );
		window.onload = ()=> {
			Display.windowOnResize();
		}
	}

	static windowOnResize() {
		setAppIsMobile(document.documentElement.clientWidth<=Display.appMobileWidth);
		setIsSmallScreen(window.innerHeight<Display.appSmallHeight);
		setIsLandscape(window.innerWidth>window.innerHeight);

		const gameObject = document.getElementById("game");
		const gameHeight: number = gameObject?.clientHeight || 100;
		setGameHeight(gameHeight);

		console.log("RESIZE", gameHeight);
	}

	static isFullScreen(state:boolean, el?:HTMLElement) {
		const doc = window.document;
		const docEl = doc.documentElement;

		// document.getElementById("myCanvas")!.requestFullscreen()

		const requestFullScreen = el
			? el.requestFullscreen // @ts-ignore
			: docEl.requestFullscreen || docEl?.mozRequestFullScreen || docEl?.webkitRequestFullScreen
		// @ts-ignore
		const cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc?.webkitExitFullscreen;

		// @ts-ignore
		if(!doc.fullscreenElement && !doc?.mozFullScreenElement && !doc?.webkitFullscreenElement) {
			if(state) requestFullScreen.call(docEl);
		} else {
			if(!state) cancelFullScreen.call(doc);
		}
	}

	static getIsFullscreen() {
		const doc = window.document;
		// @ts-ignore
		return (doc?.fullscreenElement || doc?.mozFullScreenElement || doc?.webkitFullscreenElement);
	}
	
	static getWindowSize = function(withScrollBar:boolean=true) {
		let wid = 0;
		let hei = 0;
		if (typeof window.innerWidth != "undefined") {
			wid = window.innerWidth;
			hei = window.innerHeight;
		}
		else {
			if (document.documentElement.clientWidth == 0) {
				wid = document.body.clientWidth;
				hei = document.body.clientHeight;
			} else {
				wid = document.documentElement.clientWidth;
				hei = document.documentElement.clientHeight;
			}
		}
		return { width: wid - (withScrollBar ? (wid - document.body.offsetWidth + 1) : 0), height: hei };
	};
}
