import React, { useMemo } from "react";
import style from "./style.module.scss";
import { cn } from "../../../../shared/lib/classNames";
import { getCapitalized } from "../../../../shared/lib/getCapitalized";
import { useSlots, useTable, type Num } from "../../../table";
import { getAmount } from "../../helpers";
import { Ticket as TicketType } from "../../types";
import { Status } from "../status";
import { TICKET_STATUS } from "../../config";
import { useTranslation } from "../../../../shared/hooks";

interface DetailedTicketProps {
	ticket: TicketType;
}

export const DetailedTicket = ({ ticket }: DetailedTicketProps) => {
	const { t } = useTranslation();
	const { getNumsByNum } = useTable();
	const { getSlotById } = useSlots();
	const nums = useMemo(() => {
		const numsList = getNumsByNum(ticket.selectedNums);
		return [...numsList].sort((a, b) => a.num - b.num);
	}, [getNumsByNum, ticket.selectedNums]);
	const slot = getSlotById(ticket.slotId);

	return (
		<div className={style.detailedTicket}>
			<div className={cn(style.column, style.numsInfo)}>
				<div className={style.title}>
					<span className={style.combo}>
						{getCapitalized(t(ticket.combinationKey.key, ticket.combinationKey.args))}
					</span>
					<Status status={ticket.status} className={style.status} />
				</div>
				<div className={style.nums}>
					{nums.map((num) => (
						<NumberLabel key={num.id} num={num} />
					))}
				</div>
			</div>
			<div className={cn(style.column, style.oddInfo)}>
				<span className={style.odd}>{`${t("detailed.odd")}: ${slot?.multiply.toFixed(2)}`}</span>
			</div>
			<div className={cn(style.column, style.amountsInfo)}>
				<span>{`${t("detailed.stake")}: ${getAmount(ticket.amount)}`}</span>
				{ticket.status === TICKET_STATUS.open ? (
					<span>{`${t("detailed.possible_payout")}: ${getAmount(ticket.possible)}`}</span>
				) : (
					<span>{`${t("detailed.win")}: ${getAmount(ticket.paid)}`}</span>
				)}
			</div>
		</div>
	);
};

export const NumberLabel = ({ num, className }: { num: Num; className?: string }) => (
	<div key={num.id} className={cn(style.number, num.isBlack && style.black, num.isRed && style.red, className)}>
		{num.title}
	</div>
);
