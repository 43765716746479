import {Ticket as Component} from "../components/ticket/Ticket";
import Printd from "printd";
import {createRoot} from "react-dom/client";
import {flushSync} from "react-dom";

export default class Ticket {
    static Bet (id:number) {
        const div = document.createElement('div');
        const root = createRoot(div);
        flushSync(() => {
            root.render(<Component id={id} />);
        });
        const print = new Printd()
        print.print(div, [`.ticket { background:F00; }`] )
    }
}