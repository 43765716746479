import React, { useMemo } from "react";
import style from "./style.module.scss";
import { type TicketStatus } from "../../../table";
import { TICKET_STATUS } from "../../config";
import { cn } from "../../../../shared/lib/classNames";
import { statusColorVariant } from "../variants";
import { useTranslation } from "../../../../shared/hooks";

interface Props {
	status: TicketStatus;
	className?: string;
}

export const Status = ({ status, className }: Props) => {
	const { t } = useTranslation();

	const info = useMemo(() => {
		switch (status) {
			case TICKET_STATUS.paid: {
				return {
					status,
					title: t("status.paidout"),
					className: statusColorVariant({ status }),
				};
			}
			case TICKET_STATUS.lost: {
				return {
					status,
					title: t("status.lost"),
					className: statusColorVariant({ status }),
				};
			}
			case TICKET_STATUS.open: {
				return {
					status,
					title: t("status.open"),
					className: statusColorVariant({ status }),
				};
			}
			case TICKET_STATUS.canceled: {
				return {
					status,
					title: t("status.canceled"),
					className: statusColorVariant({ status }),
				};
			}
			default: {
				return {
					status,
					title: status,
					className: "",
				};
			}
		}
	}, [status, t]);

	return <span className={cn(style.status, info.className, className)}>{info.title}</span>;
};
