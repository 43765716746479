import { useCallback, useMemo, useState } from "react";
import style from "./style.module.scss";
import { useBets } from "../../features/table";
import { ReactComponent as XCrossIcon } from "../../shared/assets/xcross.svg";
import { ReactComponent as TicketIcon } from "../../shared/assets/ticket.svg";
import { Presets } from "../../features/table";
import { cn } from "../../shared/lib/classNames";
import { Popup } from "../../shared/ui/popup";
import { useNotifications } from "../../features/notifications";
import { StatusIcon } from "../../features/notifications/ui/statusIcon";
import { notificationColor } from "../../features/notifications/colors";
import Locale from "../../core/locale";

export const Infobar = () => {
	const [show, setShow] = useState(false);
	const { userBets, selectedBetsSlots: selectedBetsSlots, clearBets } = useBets();
	const { notifications } = useNotifications();

	const handleCloseClick = useCallback(() => {
		setShow(false);
	}, []);

	const lastNotification = useMemo(() => notifications.at(-1), [notifications]);

	return (
		<div
			className={cn(
				style.infobar,
				notificationColor({ status: lastNotification?.type, background: "true", color: "true" }),
			)}
		>
			{lastNotification ? (
				<div className={cn(style.betsInfo)}>
					<StatusIcon status={lastNotification.type} className={style.icon} />
					<span>{lastNotification?.message}</span>
				</div>
			) : (
				<div className={style.betsInfo}>
					<StatusIcon className={style.icon} />
					<span>
						{ userBets.length > 0
							? `${selectedBetsSlots.length} bets selected`
							: Locale.Get("validation.error_min_bets_on_table")
						}
					</span>
					{selectedBetsSlots.length > 0 && (
						<div className={style.controls}>
							<TicketIcon className={style.controlsIcon} onClick={() => setShow(true)} />
							<XCrossIcon className={style.controlsIcon} onClick={() => clearBets()} />
						</div>
					)}
				</div>
			)}
			{show ? (
				<Popup onLayerClick={handleCloseClick}>
					<span style={{ fontSize: 18, fontWeight: 700 }}>{"Presets"}</span>
					<Presets onPresetClick={handleCloseClick} />
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
						<span
							style={{ color: "rgb(255, 204, 51)", textTransform: "uppercase" }}
							onClick={handleCloseClick}
						>
							{"Close"}
						</span>
					</div>
				</Popup>
			) : null}
		</div>
	);
};
