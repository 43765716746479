import React, { forwardRef, type HTMLAttributes, useCallback, useMemo, useState } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { cn } from "../../../../shared/lib/classNames";
import { useBets } from "../../../table";
import { useTranslation } from "../../../../shared/hooks";
import { useHistory } from "../../hooks";
import { Button, ButtonProps } from "../../../../shared/ui/button";
import { ReactComponent as RefreshIcon } from "../../../../shared/assets/repeat.svg";
import { ReactComponent as Chevron } from "../../../../shared/assets/chevron-bottom.svg";
import { ReactComponent as Print } from "../../../../img/icons/print.svg";
import { ReactComponent as Cancel } from "../../../../img/icons/cancel.svg";
import { ReactComponent as Rebet } from "../../../../img/icons/rebet.svg";
import { TICKET_STATUS } from "../../config";
import { HistoryItem } from "../../types";
import { getAmount } from "../../helpers";
import { Ticket } from "../ticket";
import { default as TicketPrint } from "../../../../core/ticket";
import { ModalStory, SingleItemModal } from "../modals";
import { statusColorVariant } from "../variants";
import { $game, GAME_ACTION, type GAME_STATE } from "../../../../store/game";
import { $app } from "../../../../store/app";

interface Props extends HTMLAttributes<HTMLDivElement> {
	chevron?: boolean;
	onTitleClick?: () => void;
	onRebetClick?: () => void;
}

export const ShortHistory = forwardRef<HTMLDivElement, Props>(
	({ chevron, onTitleClick, onRebetClick, className, ...props }, ref) => {
		const { t } = useTranslation();
		const { betsHistory } = useHistory();

		return (
			<div ref={ref} className={cn(style.history, chevron && style.chevron, className)} {...props}>
				<div className={style.topTitle}>
					<div className={cn(style.title, !!onTitleClick && style.interactive)} onClick={onTitleClick}>
						<Chevron height={16} />
						<span>{` ${t("short_history.last_tickets")}`}</span>
					</div>
					<div>
						<Button disabled={true} variant={"text"} colored={"basic"}>
							<RefreshIcon height={18} width={18} />
						</Button>
					</div>
				</div>
				<ItemsList items={betsHistory} className={cn(style.items, "scrollbar")} onRebetClick={onRebetClick} />
			</div>
		);
	},
);

export interface ListProps {
	items: HistoryItem[];
	className?: string;
	onRebetClick?: (item: HistoryItem) => void;
	onTicketClick?: () => void;
}

export const ItemsList = ({ items, className, onRebetClick }: ListProps) => {
	const { cancelUserBet } = useHistory();
	const { setBets } = useBets();
	const storyList = useMemo(() => {
		return [...items].sort((a, b) => b.id - a.id);
	}, [items]);

	const handleRebetClick = useCallback(
		(item: HistoryItem) => {
			setBets(item.userBets);
			onRebetClick?.(item);
		},
		[onRebetClick, setBets],
	);
	const handleCancelClick = useCallback(
		(item: HistoryItem) => {
			cancelUserBet({ betId: item.id });
		},
		[cancelUserBet],
	);
	const [showItem, setShowItem] = useState<HistoryItem["id"] | null>(null);

	const handleClose = useCallback(() => setShowItem(null), []);

	return (
		<>
			<div className={cn(style.itemsList, className)}>
				{storyList.map((item) => (
					<Item
						key={item.id}
						item={item}
						onCancelClick={handleCancelClick}
						onRebetClick={handleRebetClick}
						onTicketClick={() => setShowItem(item.id)}
					/>
				))}
			</div>
			{showItem ? (
				<ModalStory onClose={handleClose}>
					<SingleItemModal itemId={showItem} onRebetClick={handleRebetClick} onClose={handleClose} />
				</ModalStory>
			) : null}
		</>
	);
};

export interface ItemProps {
	item: HistoryItem;
	onCancelClick?: (item: HistoryItem) => void;
	onRebetClick?: (item: HistoryItem) => void;
	onTicketClick?: () => void;
}

export const Item = ({ item, onCancelClick, onRebetClick, onTicketClick }: ItemProps) => {
	const { t } = useTranslation();
	const app = useUnit($app);
	const payOut = useMemo(() => {
		const amount = item.data.reduce((p, v) => p + v.paid, 0);
		return getAmount(amount);
	}, [item.data]);
	const payment = useMemo(() => {
		const amount = item.data.reduce((p, v) => p + v.amount, 0);
		return getAmount(amount);
	}, [item.data]);

	return (
		<div className={style.item}>
			<div className={style.checkInfo}>
				<div className={style.title}>
					{item.data.some(({ status }) => status === TICKET_STATUS.paid) ? (
						<span
							className={cn(style.payment, statusColorVariant({ status: TICKET_STATUS.paid }))}
						>{`${t("short_history_item.payout")}: ${payOut}`}</span>
					) : (
						<span className={style.payment}>{`${t("short_history_item.payment")}: ${payment}`}</span>
					)}
					<div className={style.controls}>
						{app.isCashdesk && item.data.every((bet) => bet.status !== TICKET_STATUS.error)
							? <Button
								variant={"text"}
								colored={"basic"}
								onClick={() => {
									TicketPrint.Bet(item.id);
								}}
								aria-label={t("short_history_item.print_ticket")}
							>
								<Print height={18} width={18} />
							</Button>
							: null
						}
						<Button
							variant={"text"}
							colored={"basic"}
							onClick={() => {
								onRebetClick?.(item);
							}}
							aria-label={t("short_history_item.rebet")}
						>
							<Rebet height={18} width={18} />
						</Button>
						{app.isCashdesk
							? <BetCancelButton
								item={item}
								variant={"text"}
								onClick={() => {
									onCancelClick?.(item);
								}}
								aria-label={t("short_history_item.cancel")}
							/>
							: null
						}
					</div>
				</div>
				<div className={style.info}>
					<span>{`${t("short_info.ticket_code")}: ${item.id}`}</span>
				</div>
			</div>
			{item.data.map((ticket) => (
				<Ticket key={ticket.id} item={item} ticket={ticket} showNumbers={true} onTicketClick={onTicketClick} />
			))}
		</div>
	);
};

const $isEnabledByGame = $game.map(
	(state) => !([GAME_ACTION.nobets, GAME_ACTION.finish] as GAME_STATE[]).includes(state.roundState),
);
const getCancelByStatusEnabled = (item: HistoryItem): boolean => {
	return item.data.every((ticket) => ticket.status === TICKET_STATUS.open);
};

const BetCancelButton = ({ item, disabled, ...props }: ButtonProps & { item: HistoryItem }) => {
	const isEnabledByGame = useUnit($isEnabledByGame);
	const isEnabledByStatus = useMemo(() => getCancelByStatusEnabled(item), [item]);

	if (disabled || !(isEnabledByGame && isEnabledByStatus)) return null;
	return (
		<Button variant={"text"} {...props}>
			<Cancel height={18} width={18} />
		</Button>
	);
};
