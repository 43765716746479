import React, { type HTMLAttributes, type PropsWithChildren } from "react";
import style from "./style.module.scss";
import { cn } from "../../lib/classNames";

interface Props extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
	onLayerClick?: (event: React.MouseEvent) => void;
}

export const Popup = ({ onLayerClick, className, children, ...props }: Props) => (
	<div className={cn(style.popup, className)} {...props}>
		<div className={cn(style.layer, onLayerClick && style.active)} onClick={onLayerClick}></div>
		<div className={style.content}>{children}</div>
	</div>
);
