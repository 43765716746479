import style from "./style.module.scss";
import {useLayoutEffect, useRef} from "react";
import {useStore} from "effector-react";
import {$store} from "../../../store";
import Constants from "../../../core/constants";

const SIZE = 1000;

export const StatsWheel = ()=>{
    const statsWheel = useRef<HTMLCanvasElement|null>(null);
    let context: CanvasRenderingContext2D;
    let cx: number = 0;
    let cy: number = 0;
    const radius = {
        wheel:   { outer: 0, inner: 0 },
        numbers: { outer: 0, inner: 0 },
        groups:  { outer: 0, inner: 0 }
    }

    const init = ()=> {
        if(statsWheel.current) {
            const wheel: HTMLCanvasElement = statsWheel.current;

            context = wheel.getContext("2d")!;
            wheel.width = SIZE;
            wheel.height = SIZE;

            cx = wheel.width/2;
            cy = wheel.height/2;

            radius.wheel.outer = wheel.width/2;
            radius.wheel.inner = wheel.width/7;
            radius.numbers.outer = wheel.width/2.55;
            radius.numbers.inner = radius.numbers.outer/1.18;
            radius.groups.outer = wheel.width/2;
            radius.groups.inner = radius.numbers.outer;

            computeProbablyCoefficients();
            drawStatisticsWheel();
        }
    }

    function computeProbablyCoefficients() {
        const minValue = Constants.NUMBERS.reduce((min, obj)=> obj.probability.count < min.probability.count?obj:min );
        const maxValue = Constants.NUMBERS.reduce((max, obj)=> obj.probability.count > max.probability.count?obj:max );
        Constants.NUMBERS.forEach((el, index)=>{
            const cf = (el.probability.count-minValue.probability.count)/(maxValue.probability.count-minValue.probability.count);
            el.probability.cf = cf;
            const cf4 = (Math.floor(cf * 4) / 4).toFixed(2)
            // @ts-ignore
            el.probability.color = Constants.COLORS.probability[cf4];
        })
    }

    function drawStatisticsWheel() {

        const borderWidth = 6;

        // Background rad
        context.beginPath();
        context.lineWidth = 2;
        context.arc(SIZE/2, SIZE/2, (SIZE-14)/2 - borderWidth, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.fillStyle = Constants.COLORS.fill.back;
        context.stroke();
        context.fill();

        // Outer rad stroke
        context.beginPath();
        context.lineWidth = 4;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.wheel.outer - borderWidth/2, 0, Math.PI * 2);
        context.strokeStyle = "rgba(255,255,255,0.2)";
        context.stroke();

        // GROUPS ======================================================================================================
        // 1nd radius
        context.beginPath();
        context.lineWidth = 6;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.wheel.outer/1.065, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.fillStyle = Constants.COLORS.fill.groups;
        context.stroke();
        context.fill();

        // 2nd radius
        context.beginPath();
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.wheel.outer/1.13, 0, Math.PI * 2);
        context.fillStyle = Constants.COLORS.fill.percent;
        context.fill();

        // Lines radial
        Constants.SECTIONS.forEach((section,index)=>{
            const value = 1000/6*index;
            const scaledValue=scaleIntoRange(0,1000,0,360,value);
            const degrees=scaledValue+135;
            radiantLine(cx,cy,radius.groups.inner,radius.groups.outer/1.06,degrees,2, Constants.COLORS.fill.lines);

            section.forEach((el,j)=>{

                const scaledValueFirst=scaleIntoRange(0,1000,0,360,value+(55*j));
                const degreesFirst=scaledValueFirst+135;
                const scaledValueSecond=scaleIntoRange(0,1000,0,360,value+(55*(j+1)));
                const degreesSecond=scaledValueSecond+135;

                if(index===Constants.SECTIONS.length-1) {
                    radiantFillSection(cx,cy,radius.groups.inner,radius.groups.outer/1.125,degreesFirst,degreesSecond,2, el.color);
                }

                const scaledValueText=scaleIntoRange(0,1000,0,360,value+(((section.length%2!==0 ? 150 : 180)/section.length)*j));
                const degreesText=scaledValueText+148;

                radiantText(cx,cy,radius.groups.outer/1.11,degreesText,2,"white", `${el.name.toUpperCase()}`, "bold 18px RajdhaniBold" );
                radiantText(cx,cy,radius.groups.inner*1.04,degreesText,2,"white", `${el.value}%`, "bold 24px RajdhaniBold");
            })
        });

        // 2nd radius
        context.beginPath();
        context.lineWidth = 6;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.wheel.outer/1.13, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.stroke();

        // NUMBERS =====================================================================================================
        // 1nd radius fill
        context.beginPath();
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.outer, 0, Math.PI * 2);
        context.fillStyle = Constants.COLORS.fill.numbers;
        context.fill();

        // 2nd radius fill
        context.beginPath();
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.inner, 0, Math.PI * 2);
        context.fillStyle = Constants.COLORS.fill.counter;
        context.fill();

        // 3nd radius fill
        context.beginPath();
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.outer/1.36, 0, Math.PI * 2);
        context.fillStyle = Constants.COLORS.fill.graphs;
        context.fill();

        // Lines radial && fill numbers && fill probably coefficients
        Constants.NUMBERS.forEach((el,index,ddd)=>{
            const value = 1000/Constants.NUMBERS.length*index;
            const scaledValue=scaleIntoRange(0,1000,0,360, value);
            const degrees=scaledValue+135;

            const valueNext = 1000/Constants.NUMBERS.length*(index+1);
            const scaledValueNext=scaleIntoRange(0,1000,0,360, valueNext);
            const degreesNext=scaledValueNext+135;

            radiantFillSection(cx,cy,radius.wheel.inner,radius.numbers.outer/1.36,degrees,degreesNext,2, el.probability.color, el.probability.cf);
            radiantFillSection(cx,cy,radius.numbers.inner,radius.numbers.outer,degrees,degreesNext,2, el.color);
            radiantLine(cx,cy,radius.wheel.inner,radius.numbers.outer,degrees,1.5,Constants.COLORS.fill.lines);
            radiantText(cx,cy,radius.numbers.outer/1.12,degrees+4.8,2,"#fff", el.num===100 ? "00" : el.num.toString(), "32px RajdhaniBold" );
            radiantText(cx,cy,radius.numbers.outer/1.3,degrees+4.8,2,"#fff", el.probability.count.toString(), "22px RajdhaniBold" );
        })

        // 1nd radius stroke
        context.beginPath();
        context.lineWidth = 4;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.outer, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.stroke();

        // 2nd radius stroke
        context.beginPath();
        context.lineWidth = 4;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.inner, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.stroke();

        // 3nd radius stroke
        context.beginPath();
        context.lineWidth = 2;
        context.arc(radius.wheel.outer, radius.wheel.outer, radius.numbers.outer/1.36, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.stroke();
        // =============================================================================================================

        // Inner rad
        context.beginPath();
        context.lineWidth = 6;
        context.arc(cx, cy, radius.wheel.inner - borderWidth/2, 0, Math.PI * 2);
        context.strokeStyle = Constants.COLORS.fill.lines;
        context.fillStyle = Constants.COLORS.fill.inner;
        context.stroke();
        context.fill();
    }

    function radiantLine(centerX:number,centerY:number,innerRadius:number,outerRadius:number,degrees:number,lineWidth:number,color:string){
        const radians=degrees*Math.PI/180;
        const innerX = centerX + innerRadius * Math.cos(radians);
        const innerY = centerY + innerRadius * Math.sin(radians);
        const outerX = centerX + outerRadius * Math.cos(radians);
        const outerY = centerY + outerRadius * Math.sin(radians);

        context.beginPath();
        context.moveTo(innerX,innerY);
        context.lineTo(outerX,outerY);
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.stroke();
    }

    function radiantFillSection(centerX:number,centerY:number,innerRadius:number,outerRadius:number,firstDegrees:number,secondDegrees:number,lineWidth:number,color:string=Constants.COLORS.null,cf:number=1){

        const firstRadians= firstDegrees*Math.PI/180;
        const secondRadians= secondDegrees*Math.PI/180;
        const range = (outerRadius-innerRadius)*cf
        const computedLines = {
            first: {
                innerX: centerX + innerRadius * Math.cos(firstRadians),
                innerY: centerY + innerRadius * Math.sin(firstRadians),
                outerX: centerX + (innerRadius+range) * Math.cos(firstRadians),
                outerY: centerY + (innerRadius+range) * Math.sin(firstRadians),
            },
            second: {
                innerX: centerX + innerRadius * Math.cos(secondRadians),
                innerY: centerY + innerRadius * Math.sin(secondRadians),
                outerX: centerX + (innerRadius+range) * Math.cos(secondRadians),
                outerY: centerY + (innerRadius+range) * Math.sin(secondRadians)
            }
        }

        context.beginPath();
        context.moveTo(computedLines.first.innerX,computedLines.first.innerY);
        context.lineTo(computedLines.first.outerX,computedLines.first.outerY);
        context.lineTo(computedLines.second.outerX,computedLines.second.outerY);
        context.lineTo(computedLines.second.innerX,computedLines.second.innerY);
        context.lineTo(computedLines.first.innerX,computedLines.first.innerY);
        context.strokeStyle = color;
        context.lineWidth = lineWidth;
        context.fillStyle = color;
        context.stroke();
        context.fill();
    }

    function radiantText(centerX:number,centerY:number,outerRadius:number,degrees:number,lineWidth:number,color:string,value:string,font?:string){
        const radians=degrees*Math.PI/180;
        const outerX = centerX + outerRadius * Math.cos(radians);
        const outerY = centerY + outerRadius * Math.sin(radians);

        context.fillStyle = color;
        context.font = font || "32px RajdhaniBold";
        context.textAlign = "center";

        context.save();
        context.translate(outerX, outerY);
        context.rotate((degrees+90)*Math.PI/180 );
        context.fillText(value, 0, 0);
        context.restore();
    }

    function scaleIntoRange(minActual:number,maxActual:number,minRange:number,maxRange:number,value:number){
        const scaled=(maxRange-minRange)*(value-minRange)/(maxActual-minActual)+minRange;
        return(scaled);
    }

    useLayoutEffect(() => {
        if(statsWheel.current) {
            init();
        }
    }, []);

    return <div className={style.statsWheel}>
        <canvas ref={statsWheel}/>
        <Counter/>
    </div>
}

const Counter = ()=> {
    const store = useStore($store);
    return <p className={`${( store.app.timer<=15 && store.app.roundDelay!==0) ? style.active:''}`}>{ store.app.timer }</p>
}