import React, { useRef } from "react";
import style from "./style.module.scss";
import { ReactComponent as XCrossIcon } from "../../assets/xcross.svg";
import { cn } from "../../lib/classNames";
import { Button } from "../button";
import { Input } from "../input";

interface Props {
	min?: number;
	defaultValue?: null | number;
	placeholder?: string;
	className?: string;
	onValueChange?: (value: null | number) => void;
}

export const SearchNumber = ({ min, placeholder, defaultValue, className, onValueChange }: Props) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const value = event.target.value;
		const transformedValue = value === "" ? null : Number(value);
		if (!Number.isNaN(value)) onValueChange?.(transformedValue);
	};
	const handleReset = () => {
		if (inputRef.current) inputRef.current.value = "";
		onValueChange?.(null);
	};

	return (
		<div className={cn(style.search, className)}>
			<Input
				ref={inputRef}
				type={"number"}
				min={min}
				defaultValue={defaultValue ?? ""}
				className={style.input}
				placeholder={placeholder}
				onChange={handleInputChange}
			/>
			<Button variant={"text"} colored={"danger"} aria-label={"Clear search"} onClick={handleReset}>
				<XCrossIcon height={18} width={18} />
			</Button>
		</div>
	);
};
