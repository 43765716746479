import { createEvent, createStore } from "effector";
import { ROUND_TIMING } from "../core/socket";
import Constants, { NUMBER } from "../core/constants";
import { type Values } from "../shared/types";

export const SPIN_TIME: number = 5000;
export const GAME_ACTION = {
	start: "start",
	lastbet: "lastbets",
	nobets: "nobets",
	finish: "finish",
} as const;

export type NEXT_NUMBER = {
	num: number;
	deg: number;
	color: string;
};
export type GAME_STATE = `${Values<typeof GAME_ACTION>}`;

export type Game = {
	isWheelOpen: boolean;
	isPreloader: boolean;
	isSpin: boolean;

	wheelIsSpin: boolean;
	isBallShaking: boolean;

	nextNumber: NEXT_NUMBER;

	roundNumber: number;
	roundState: GAME_STATE;

	curSpeedCoefficient: number;
	curBallAngle: number;
	curBallRadCoefficient: number;
	randJumpShift: number;

	curWheelAngle: number;

	physVariant: number;

	progressCoefficient: number;

	timing: ROUND_TIMING;
};

const mock = {
	isWheelOpen: false,
	isPreloader: true,
	isSpin: false,

	wheelIsSpin: true,
	isBallShaking: true,

	nextNumber: {
		num: -1,
		deg: 0,
		color: Constants.COLORS.null,
	},
	roundNumber: 0,
	roundState: "" as GAME_STATE,

	curSpeedCoefficient: 0,
	curBallAngle: 0,
	curBallRadCoefficient: 1,
	randJumpShift: 0,

	curWheelAngle: 0,

	physVariant: 0,

	progressCoefficient: 0,

	timing: {
		round_interval: 0,
		time_to_finish: 0,
		time_to_lastbets: 0,
		time_to_nobets: 0,
	},
};

export const setIsWheelOpen = createEvent<boolean>();
export const setIsPreloader = createEvent<boolean>();
export const setIsSpin = createEvent<boolean>();
export const setWheelIsSpin = createEvent<boolean>();
export const setIsBallShaking = createEvent<boolean>();
export const setNextNumber = createEvent<number>();
export const setRoundNumber = createEvent<number>();
export const setRoundState = createEvent<GAME_STATE>();
export const setTimings = createEvent<ROUND_TIMING>();
export const setCurSpeedCoefficient = createEvent<number>();
export const setRandJumpShift = createEvent<number>();
export const setCurBallAngle = createEvent<number>();
export const setCurBallRadCoefficient = createEvent<number>();
export const setCurWheelAngle = createEvent<number>();
export const setProgressCoefficient = createEvent<number>();
export const setPhysVariant = createEvent<number>();
export const clear = createEvent();

export const $game = createStore<Game>(mock)
	.on(setIsWheelOpen, (state, isWheelOpen: boolean) => {
		return { ...state, isWheelOpen };
	})
	.on(setIsPreloader, (state, isPreloader: boolean) => {
		return { ...state, isPreloader };
	})
	.on(setIsSpin, (state, isSpin: boolean) => {
		return { ...state, isSpin };
	})
	.on(setWheelIsSpin, (state, wheelIsSpin: boolean) => {
		return { ...state, wheelIsSpin };
	})
	.on(setIsBallShaking, (state, isBallShaking: boolean) => {
		return { ...state, isBallShaking };
	})
	.on(setNextNumber, (state, number: number) => {
		const numberElement = Constants.NUMBERS.find((el: NUMBER) => el.num === number);
		const nextNumber: NEXT_NUMBER = {
			num: number,
			deg: Constants.getNumDegreesByNumberElement(numberElement),
			color: numberElement?.color || Constants.COLORS.null,
		};
		return { ...state, nextNumber };
	})
	.on(setRoundNumber, (state, roundNumber: number) => {
		return { ...state, roundNumber };
	})
	.on(setTimings, (state, timing: ROUND_TIMING) => {
		return { ...state, timing };
	})
	.on(setRoundState, (state, roundState: GAME_STATE) => {
		return { ...state, roundState };
	})
	.on(setCurSpeedCoefficient, (state, curSpeedCoefficient: number) => {
		return { ...state, curSpeedCoefficient };
	})
	.on(setCurBallAngle, (state, curBallAngle: number) => {
		return { ...state, curBallAngle };
	})
	.on(setCurBallRadCoefficient, (state, curBallRadCoefficient: number) => {
		return { ...state, curBallRadCoefficient };
	})
	.on(setRandJumpShift, (state, randJumpShift) => {
		return { ...state, randJumpShift };
	})
	.on(setCurWheelAngle, (state, curWheelAngle: number) => {
		return { ...state, curWheelAngle };
	})
	.on(setProgressCoefficient, (state, processCoefficient: number) => {
		return { ...state, progressCoefficient: processCoefficient };
	})
	.on(setPhysVariant, (state, physVariant: number) => {
		return { ...state, physVariant };
	})
	.reset(clear);
