import { $locales, addUniqueKeyError, addUnregisteredKeys } from "../store/locales";


export type Lang = "en" | "ru" | "ht" | "fr" | "es";
export type Languages = { [key in Lang]: Dictionary; };
export type Dictionary = typeof defaultLang;

type DefaultConfig = { defaultValue?: string };
type CustomArgs = { [key: string]: string | number };
export type Template = { key: keyof Dictionary | string; args?: DefaultConfig & CustomArgs };

const defaultLang = {
	"language.ru": "Русский",
	"language.en": "English",
	"language.ht": "Kreyòl",
	"language.fr": "Français",
	"language.es": "Español",

	"ok": "Ok",
	"amount": "Amount",
	"ticket": "Ticket",
	"pin": "Pin",
	"total": "Total",
	"bet": "Bet",
	"balance": "Balance",
	"round": "Round",
	"number": "Number",
	"game_a_roulette": "American Roulette",
	"game_last_result": "Latest results",
	"game_bettings": "Bettings",
	"dozen": "Dozen",
	"basket": "Basket",
	"trio": "Trio",
	"double_street": "Double street",
	"corner": "Corner",
	"split": "Split",
	"street": "Street",
	"straight_up": "Straight up",
	"column": "Column",

	"variants.basket": "Pick zero, double zero, 1, 2 and 3",
	"variants.trio": "Pick three numbers including at least one zero",
	"variants.w_street": "Pick 2 vertical lines with 6 numbers",
	"variants.corner": "Pick any 4 joining numbers",
	"variants.split": "Pick any 2 joining numbers",
	"variants.street": "Pick vertical line with 3 numbers",
	"variants.straight_up": "Pick any number on the table",
	"variants.1st12": "Pick first dozen (12 numbers)",
	"variants.2nd12": "Pick second dozen (12 numbers)",
	"variants.3rd12": "Pick third dozen (12 numbers)",
	"variants.low": "Pick numbers 1 to 18",
	"variants.high": "Pick numbers 19 to 36",
	"variants.col1": "Pick all numbers in first horizontal line",
	"variants.col2": "Pick all numbers in second horizontal line",
	"variants.col3": "Pick all numbers in third horizontal line",
	"variants.red": "Pick red color",
	"variants.black": "Pick black color",
	"variants.even": "Pick all even numbers",
	"variants.odd": "Pick all odd numbers",

	"result.title": "Round completed",
	"result.win_number": "Winning\nnumber",
	"result.win_games": "Winning games",
	"result.table_betting": "Table betting",

	"detailed.odd": "Odd",
	"detailed.stake": "Stake",
	"detailed.possible_payout": "Possible payout",
	"detailed.win": "Win",
	"full_story_item.payout": "Payout",
	"full_story_item.payment": "Payment",
	"modals.undefined_item": "Oops something goes wrong...",
	"modals.round_result": "Round result",
	"modals_controls.rebet": "Rebet",
	"modals_controls.close": "Close",
	"short_history.last_tickets": "Last tickets",
	"short_history_item.payout": "Payout",
	"short_history_item.payment": "Payment",
	"short_history_item.cancel": "Cancel",
	"short_history_item.rebet": "Rebet",
	"short_history_item.print_ticket": "Print ticket",
	"short_info.round": "Round",
	"short_info.ticket_code": "Ticket code",
	"status.paidout": "Paidout",
	"status.lost": "Lost",
	"status.open": "Open",
	"status.canceled": "Canceled",
	"ticket.round": "Round",
	"totals.payment": "Total payment",
	"totals.payout": "Total payout",

	"ticket.processing_status": `Ticket is processing`,
	"ticket.confirmed_status": `Ticket confirmed: {{ticketId}}`,
	"ticket.successfully_cancelled_status": `Ticket ID:{{betId}} successfully cancelled`,

	"controls.double": "Double",
	"controls.rebet": "Rebet",
	"controls.undo": "Undo",
	"slotBet.Pay in": "Pay in",
	"slotBet.Payout": "Payout",
	"payment.total": "Total payment",
	"sign.in": "Sign in",
	"pay.in": "Pay in",
	"validation.error_balance_is_less": `Invalid bet. Balance is less. Maximum is {{maxValue}}`,
	"validation.error_min_bet": `Invalid bet payment. Minimum is {{minValue}}.`,
	"validation.error_max_bet": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
	"validation.error_max_bets_on_slot": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
	"validation.error_max_bets_on_table": `Invalid number of bets. Maximum is {{maxValue}}.`,
	"validation.error_min_bets_on_table": `Place chip on desired bet to start.`,

	"table.column.1": `1st\ncol.`,
	"table.column.2": `2nd\ncol.`,
	"table.column.3": `3rd\ncol.`,
	"table.low": `low`,
	"table.high": `high`,
	"table.even": `even`,
	"table.odd": `odd`,
	"table.red": `red`,
	"table.black": `black`,
	"table.part.1": `1st {{rows}}`,
	"table.part.2": `2nd {{rows}}`,
	"table.part.3": `3rd {{rows}}`,

	"combination.street": "street",
	"combination.six line": "six line",
	"combination.column.1": "1st column",
	"combination.column.2": "2nd column",
	"combination.column.3": "3rd column",
	"combination.part.1": "1st dozen",
	"combination.part.2": "2nd dozen",
	"combination.part.3": "3rd dozen",
	"combination.low": `low`,
	"combination.high": `high`,
	"combination.even": `even`,
	"combination.odd": `odd`,
	"combination.red": `red`,
	"combination.black": `black`,
	"combination.nums.1": `straight`,
	"combination.nums.2": `split`,
	"combination.nums.3": `trio`,
	"combination.nums.4": `corner`,
	"combination.nums.5": `basket`,
};

const Dict: Languages = {
	en: defaultLang,
	ru: {
		"language.ru": "Русский",
		"language.en": "English",
		"language.ht": "Kreyòl",
		"language.fr": "Français",
		"language.es": "Español",

		"ok": "Ok",
		"amount": "Сумма",
		"ticket": "Чек",
		"pin": "Pin",
		"total": "Итого",
		"bet": "Ставка",
		"balance": "Баланс",
		"round": "Раунд",
		"number": "Номер",
		"game_a_roulette": "Американская Рулетка",
		"game_last_result": "Результаты",
		"game_bettings": "Ставки",
		"dozen": "Dozen",
		"basket": "Basket",
		"trio": "Trio",
		"double_street": "Double street",
		"corner": "Corner",
		"split": "Split",
		"street": "Street",
		"straight_up": "Straight up",
		"column": "Column",

		"variants.basket": "Выбрать зеро, двойной зеро, 1, 2 и 3",
		"variants.trio": "Выбрать 3 числа, включая один зеро",
		"variants.w_street": "Выбрать две верт. линии из 6 чисел",
		"variants.corner": "Выбрать любые 4 соседних числа",
		"variants.split": "Выбрать любые 2 соседних числа",
		"variants.street": "Выбрать верт. линию из 3 чисел",
		"variants.straight_up": "Выбрать любое число на столе",
		"variants.1st12": "Выбрать первую дюжину (12 чисел)",
		"variants.2nd12": "Выбрать вторую дюжину (12 чисел)",
		"variants.3rd12": "Выбрать третью дюжину (12 чисел)",
		"variants.low": "Выбрать числа от 1 до 18",
		"variants.high": "Выбрать числа от 19 до 36",
		"variants.col1": "Выбрать числа в первой гор. строке",
		"variants.col2": "Выбрать числа во второй гор. строке",
		"variants.col3": "Выбрать числа в третьей гор. строке",
		"variants.red": "Выбрать красный цвет",
		"variants.black": "Выбрать черный цвет",
		"variants.even": "Выбрать все четные числа",
		"variants.odd": "Выбрать все нечетные числа",

		"result.title": "Раунд завершен",
		"result.win_number": "Выиграл\nномер",
		"result.win_games": "Выигрышные игры",
		"result.table_betting": "Ставки на столе",

		"detailed.odd": "Коэфф.",
		"detailed.stake": "Ставка",
		"detailed.possible_payout": "Возможная выплата",
		"detailed.win": "Выигрыш",
		"full_story_item.payout": "Выплата",
		"full_story_item.payment": "Оплата",
		"modals.undefined_item": "Ой, что-то пошло не так...",
		"modals.round_result": "Результат раунда",
		"modals_controls.rebet": "Повторить",
		"modals_controls.close": "Закрыть",
		"short_history.last_tickets": "Последние билеты",
		"short_history_item.payout": "Выплата",
		"short_history_item.payment": "Оплата",
		"short_history_item.cancel": "Отмена",
		"short_history_item.rebet": "Повторить",
		"short_history_item.print_ticket": "Распечатать билет",
		"short_info.round": "Раунд",
		"short_info.ticket_code": "Код билета",
		"status.paidout": "Paidout",
		"status.lost": "Lost",
		"status.open": "Open",
		"status.canceled": "Canceled",
		"ticket.round": "Раунд",
		"totals.payment": "Итого оплачено",
		"totals.payout": "Итого выплата",

		"ticket.processing_status": `Ticket is processing`,
		"ticket.confirmed_status": `Ticket confirmed: {{ticketId}}`,
		"ticket.successfully_cancelled_status": `Ticket ID:{{betId}} successfully cancelled`,

		"controls.double": "Удвоить",
		"controls.rebet": "Повторить",
		"controls.undo": "Отмена",
		"slotBet.Pay in": "Оплата",
		"slotBet.Payout": "Выигрыш",
		"payment.total": "Итого оплата",
		"sign.in": "Войти",
		"pay.in": "Оплатить",
		"validation.error_balance_is_less": `Invalid bet. Balance is less. Maximum is {{maxValue}}`,
		"validation.error_min_bet": `Invalid bet payment. Minimum is {{minValue}}.`,
		"validation.error_max_bet": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_slot": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_table": `Invalid number of bets. Maximum is {{maxValue}}.`,
		"validation.error_min_bets_on_table": `Place chip on desired bet to start.`,

		"table.column.1": `1st\ncol.`,
		"table.column.2": `2nd\ncol.`,
		"table.column.3": `3rd\ncol.`,
		"table.low": `low`,
		"table.high": `high`,
		"table.even": `even`,
		"table.odd": `odd`,
		"table.red": `red`,
		"table.black": `black`,
		"table.part.1": `1st {{rows}}`,
		"table.part.2": `2nd {{rows}}`,
		"table.part.3": `3rd {{rows}}`,

		"combination.street": "street",
		"combination.six line": "six line",
		"combination.column.1": "1st column",
		"combination.column.2": "2nd column",
		"combination.column.3": "3rd column",
		"combination.part.1": "1st dozen",
		"combination.part.2": "2nd dozen",
		"combination.part.3": "3rd dozen",
		"combination.low": `low`,
		"combination.high": `high`,
		"combination.even": `even`,
		"combination.odd": `odd`,
		"combination.red": `red`,
		"combination.black": `black`,
		"combination.nums.1": `straight`,
		"combination.nums.2": `split`,
		"combination.nums.3": `trio`,
		"combination.nums.4": `corner`,
		"combination.nums.5": `basket`,
	},
	ht: {
		"language.ru": "Русский",
		"language.en": "English",
		"language.ht": "Kreyòl",
		"language.fr": "Français",
		"language.es": "Español",

		"ok": "Ok",
		"amount": "Montan",
		"ticket": "Fich",
		"pin": "Pin",
		"total": "Total",
		"bet": "Pari",
		"balance": "Balance",
		"round": "Course",
		"number": "Nimewo",
		"game_a_roulette": "Woulèt Ameriken",
		"game_last_result": "Dènye rezilta yo",
		"game_bettings": "Pari yo",
		"dozen": "Douzèn",
		"basket": "Baskèt",
		"trio": "Trio",
		"double_street": "Doub Katye",
		"corner": "Kònè",
		"split": "Cheval",
		"street": "Katye",
		"straight_up": "Dirèk",
		"column": "Kolònn",

		"variants.basket": "Chwazi zewo, doub zewo, 1, 2 e 3",
		"variants.trio": "Chwazi twa boul ki gen ladan omwen yon zewo",
		"variants.w_street": "Chwazi 2 ranje vètikal avèk 6 boul",
		"variants.corner": "Chwazi 4 boul kare",
		"variants.split": "Chwazi 2 boul kole",
		"variants.street": "Chwazi ranje vètikal avèk 3 boul",
		"variants.straight_up": "Chwazi nenpòt boul sou tab la",
		"variants.1st12": "Chwazi premye douzèn (12 boul)",
		"variants.2nd12": "Chwazi dezyèm douzèn (12 boul)",
		"variants.3rd12": "Chwazi twazyèm douzèn (12 boul)",
		"variants.low": "Chwazi boul 1 a 18",
		"variants.high": "Chwazi boul 19 a 36",
		"variants.col1": "Chwazi tout boul nan premye liy orizontal",
		"variants.col2": "Chwazi tout boul nan dezyèm liy orizontal",
		"variants.col3": "Chwazi tout boul nan twazyèm liy orizontal",
		"variants.red": "Chwazi koulè Wouj",
		"variants.black": "Chwazi koulè Nwa",
		"variants.even": "Chwazi tout boul pè",
		"variants.odd": "Chwazi tout boul Enpè",

		"result.title": "Course fini",
		"result.win_number": "Boul\nGayan",
		"result.win_games": "Rezilta Gayan",
		"result.table_betting": "Tab Paryaj",

		"detailed.odd": "Kot",
		"detailed.stake": "Miz",
		"detailed.possible_payout": "Genyen posib",
		"detailed.win": "Genyen",
		"full_story_item.payout": "Touche",
		"full_story_item.payment": "Peman",
		"modals.undefined_item": "Oops yon bagay ale mal...",
		"modals.round_result": "Course result",
		"modals_controls.rebet": "Reparye",
		"modals_controls.close": "Fèmen",
		"short_history.last_tickets": "Dènye fich yo",
		"short_history_item.payout": "Touche",
		"short_history_item.payment": "Peman",
		"short_history_item.cancel": "Anile",
		"short_history_item.rebet": "Reparye",
		"short_history_item.print_ticket": "Enprime fich",
		"short_info.round": "Course",
		"short_info.ticket_code": "Nimewo fich",
		"status.paidout": "Paidout",
		"status.lost": "Lost",
		"status.open": "Open",
		"status.canceled": "Canceled",
		"ticket.round": "Course",
		"totals.payment": "Peman Total",
		"totals.payout": "Touche Total",

		"ticket.processing_status": `Fich ap trete`,
		"ticket.confirmed_status": `Fich konfime: {{ticketId}}`,
		"ticket.successfully_cancelled_status": `Fich ID:{{betId}} anile avèk siksè`,

		"controls.double": "Double",
		"controls.rebet": "Reparye",
		"controls.undo": "Anile",
		"slotBet.Pay in": "Peye",
		"slotBet.Payout": "Touche",
		"payment.total": "Peman Total",
		"sign.in": "Siyen nan",
		"pay.in": "Peye",
		"validation.error_balance_is_less": `Parye envalid. Balans se mwens. Maksimòm se {{maxValue}}.`,
		"validation.error_min_bet": `Peman parye envalid. Minimòm se {{minValue}}.`,
		"validation.error_max_bet": `Peman parye envalid. Minimòm se {{minValue}}, maksimòm se {{maxValue}}.`,
		"validation.error_max_bets_on_slot": `Peman parye envalid. Minimòm se {{minValue}}, maksimòm se {{maxValue}}.`,
		"validation.error_max_bets_on_table": `Envalid kantite parye. Maksimòm se {{maxValue}}.`,
		"validation.error_min_bets_on_table": `Mete chip sou parye vle yo kòmanse.`,

		"table.column.1": `1ye\nkol.`,
		"table.column.2": `2yèm\nkol.`,
		"table.column.3": `3yèm\nkol.`,
		"table.low": `1-18`,
		"table.high": `19-36`,
		"table.even": `Pè`,
		"table.odd": `Enpè`,
		"table.red": `Rouj`,
		"table.black": `Nwa`,
		"table.part.1": `1ye {{rows}}`,
		"table.part.2": `2yèm {{rows}}`,
		"table.part.3": `3yèm {{rows}}`,

		"combination.street": "Katye",
		"combination.six line": "Doub Katye",
		"combination.column.1": "1ye kolòn",
		"combination.column.2": "2yèm kolòn",
		"combination.column.3": "3yèm kolòn",
		"combination.part.1": "1ye douzèn",
		"combination.part.2": "2yèm douzèn",
		"combination.part.3": "3yèm douzèn",
		"combination.low": `1-18`,
		"combination.high": `19-36`,
		"combination.even": `Pè`,
		"combination.odd": `Enpè`,
		"combination.red": `Rouj`,
		"combination.black": `Nwa`,
		"combination.nums.1": `Dirèk`,
		"combination.nums.2": `Cheval`,
		"combination.nums.3": `Trio`,
		"combination.nums.4": `Kònè`,
		"combination.nums.5": `Baskèt`,
	},
	fr: {
		"language.ru": "Русский",
		"language.en": "English",
		"language.ht": "Kreyòl",
		"language.fr": "Français",
		"language.es": "Español",

		"ok": "Ok",
		"amount": "Montant",
		"ticket": "Ticket",
		"pin": "Pin",
		"total": "Total",
		"bet": "Pari",
		"balance": "Balance",
		"round": "Round",
		"number": "Numéro",
		"game_a_roulette": "American Roulette",
		"game_last_result": "Derniers résultats",
		"game_bettings": "Les paris",
		"dozen": "Douzaine",
		"basket": "Basket",
		"trio": "Trio",
		"double_street": "Double street",
		"corner": "Corner",
		"split": "Split",
		"street": "Street",
		"straight_up": "Straight up",
		"column": "Colonne",

		"variants.basket": "Choisissez zéro, double zéro, 1, 2 et 3",
		"variants.trio": "Choisissez trois nombres dont au moins un zéro",
		"variants.w_street": "Sélectionnez 2 lignes verticales avec 6 chiffres",
		"variants.corner": "Sélectionnez n'importe quels 4 numéros d'affilée",
		"variants.split": "Choisissez n'importe quel 2 numéros joints",
		"variants.street": "Sélectionnez une ligne verticale avec 3 chiffres",
		"variants.straight_up": "Choisissez n'importe quel numéro sur le tableau",
		"variants.1st12": "Choisissez la première douzaine (12 numéros)",
		"variants.2nd12": "Choisissez la deuxième douzaine (12 numéros)",
		"variants.3rd12": "Choisissez la troisième douzaine (12 numéros)",
		"variants.low": "Choisissez les numéros de 1 à 18",
		"variants.high": "Choisissez les numéros de 19 à 36",
		"variants.col1": "Choisissez tous les numéros dans la première ligne horizontale",
		"variants.col2": "Choisissez tous les numéros dans la deuxième ligne horizontale",
		"variants.col3": "Choisissez tous les numéros dans la troisième ligne horizontale",
		"variants.red": "Choisissez toutes les couleurs rouges",
		"variants.black": "Choisissez toutes les couleurs noires",
		"variants.even": "Choisissez tous les numéros pairs",
		"variants.odd": "Choisissez tous les numéros impairs",

		"result.title": "Round terminé",
		"result.win_number": "Numéro\ngagnant",
		"result.win_games": "Jeux à prix",
		"result.table_betting": "Paris sur la table",

		"detailed.odd": "Impair",
		"detailed.stake": "Mise",
		"detailed.possible_payout": "Versement possible",
		"detailed.win": "Gain",
		"full_story_item.payout": "Versement",
		"full_story_item.payment": "Paiement",
		"modals.undefined_item": "Oups, quelque chose ne va pas...",
		"modals.round_result": "Tour Résultat",
		"modals_controls.rebet": "Reparier",
		"modals_controls.close": "Fermer",
		"short_history.last_tickets": "Derniers tickets",
		"short_history_item.payout": "Versement",
		"short_history_item.payment": "Paiement",
		"short_history_item.cancel": "Annuler",
		"short_history_item.rebet": "Reparier",
		"short_history_item.print_ticket": "Imprimer le ticket",
		"short_info.round": "Tour",
		"short_info.ticket_code": "Code du ticket",
		"status.paidout": "Paidout",
		"status.lost": "Lost",
		"status.open": "Open",
		"status.canceled": "Canceled",
		"ticket.round": "Tour",
		"totals.payment": "Paiement total",
		"totals.payout": "Total des versements",

		"ticket.processing_status": `Ticket is processing`,
		"ticket.confirmed_status": `Ticket confirmed: {{ticketId}}`,
		"ticket.successfully_cancelled_status": `Ticket ID:{{betId}} successfully cancelled`,

		"controls.double": "Double",
		"controls.rebet": "Reparier",
		"controls.undo": "Annuler",
		"slotBet.Pay in": "Paiement",
		"slotBet.Payout": "Versement",
		"payment.total": "Paiement total",
		"sign.in": "Se connecter",
		"pay.in": "Paiement",
		"validation.error_balance_is_less": `Invalid bet. Balance is less. Maximum is {{maxValue}}`,
		"validation.error_min_bet": `Invalid bet payment. Minimum is {{minValue}}.`,
		"validation.error_max_bet": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_slot": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_table": `Invalid number of bets. Maximum is {{maxValue}}.`,
		"validation.error_min_bets_on_table": `Place chip on desired bet to start.`,

		"table.column.1": `1ère\ncol.`,
		"table.column.2": `2ème\ncol.`,
		"table.column.3": `3ème\ncol.`,
		"table.low": `Bas`,
		"table.high": `Haut`,
		"table.even": `Pair`,
		"table.odd": `Impair`,
		"table.red": `Rouge`,
		"table.black": `Noir`,
		"table.part.1": `1ère {{rows}}`,
		"table.part.2": `2ème {{rows}}`,
		"table.part.3": `3ème {{rows}}`,

		"combination.street": "Street",
		"combination.six line": "Six lignes",
		"combination.column.1": "1ère colonne",
		"combination.column.2": "2ème colonne",
		"combination.column.3": "3ème colonne",
		"combination.part.1": "1ère douzaine",
		"combination.part.2": "2ème douzaine",
		"combination.part.3": "3ème douzaine",
		"combination.low": `Bas`,
		"combination.high": `Haut`,
		"combination.even": `Pair`,
		"combination.odd": `Impair`,
		"combination.red": `Rouge`,
		"combination.black": `Noir`,
		"combination.nums.1": `Straight`,
		"combination.nums.2": `Split`,
		"combination.nums.3": `Trio`,
		"combination.nums.4": `Corner`,
		"combination.nums.5": `Basket`,

	},
	es: {
		"language.ru": "Русский",
		"language.en": "English",
		"language.ht": "Kreyòl",
		"language.fr": "Français",
		"language.es": "Español",

		"ok": "Ok",
		"amount": "Cantidad",
		"ticket": "Ticket",
		"pin": "Pin",
		"total": "Total",
		"bet": "Apuesta",
		"balance": "Balance",
		"round": "Ronda",
		"number": "Número",
		"game_a_roulette": "American Roulette",
		"game_last_result": "Últimos resultados",
		"game_bettings": "Apuestas",
		"dozen": "Docena",
		"basket": "Canasta",
		"trio": "Trio",
		"double_street": "Dos Filas",
		"corner": "Cuadrante",
		"split": "Dividir",
		"street": "Fila",
		"straight_up": "Directo",
		"column": "Columna",

		"variants.basket": "Elige cero, doble cero, 1, 2 y 3",
		"variants.trio": "Elige tres números incluyendo al menos un cero",
		"variants.w_street": "Elige 2 líneas verticales con 6 números",
		"variants.corner": "Elige cualquier combinación de 4 números",
		"variants.split": "Elegir 2 números de unión cualquiera",
		"variants.street": "Elige una línea vertical con 3 números",
		"variants.straight_up": "Elegir cualquier número en la mesa",
		"variants.1st12": "Elegir la primera docena (12 números)",
		"variants.2nd12": "Elegir la segunda docena (12 números)",
		"variants.3rd12": "Elegir la tercera docena (12 números)",
		"variants.low": "Elegir números del 1 al 18",
		"variants.high": "Elegir números del 19 al 36",
		"variants.col1": "Elegir todos los números en la primera línea horizontal",
		"variants.col2": "Elegir todos los números en la segunda línea horizontal",
		"variants.col3": "Elegir todos los números en la tercera línea horizontal",
		"variants.red": "Elegir todos de color rojo",
		"variants.black": "Elegir todos de color negro",
		"variants.even": "Elegir todos los números pares",
		"variants.odd": "Elegir todos los números impares",

		"result.title": "Ronda completada",
		"result.win_number": "Número\nganador",
		"result.win_games": "Juegos ganadores",
		"result.table_betting": "Apuestas en la mesa",

		"detailed.odd": "Odd",
		"detailed.stake": "Stake",
		"detailed.possible_payout": "Possible payout",
		"detailed.win": "Win",
		"full_story_item.payout": "Payout",
		"full_story_item.payment": "Payment",
		"modals.undefined_item": "Oops something goes wrong...",
		"modals.round_result": "Ronda result",
		"modals_controls.rebet": "Rebet",
		"modals_controls.close": "Cerrar",
		"short_history.last_tickets": "Últimos tickets",
		"short_history_item.payout": "Payout",
		"short_history_item.payment": "Payment",
		"short_history_item.cancel": "Anular",
		"short_history_item.rebet": "Rebet",
		"short_history_item.print_ticket": "Imprimir ticket",
		"short_info.round": "Ronda",
		"short_info.ticket_code": "Ticket code",
		"status.paidout": "Paidout",
		"status.lost": "Lost",
		"status.open": "Open",
		"status.canceled": "Canceled",
		"ticket.round": "Round",
		"totals.payment": "Total payment",
		"totals.payout": "Total payout",

		"ticket.processing_status": `Ticket is processing`,
		"ticket.confirmed_status": `Ticket confirmed: {{ticketId}}`,
		"ticket.successfully_cancelled_status": `Ticket ID:{{betId}} successfully cancelled`,

		"controls.double": "Doble",
		"controls.rebet": "Rebet",
		"controls.undo": "Deshacer",
		"slotBet.Pay in": "Pay in",
		"slotBet.Payout": "Payout",
		"payment.total": "Pago total",
		"sign.in": "Iniciar sesión",
		"pay.in": "Pagar en",
		"validation.error_balance_is_less": `Invalid bet. Balance is less. Maximum is {{maxValue}}`,
		"validation.error_min_bet": `Invalid bet payment. Minimum is {{minValue}}.`,
		"validation.error_max_bet": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_slot": `Invalid bet payment. Minimum is {{minValue}}, maximum is {{maxValue}}.`,
		"validation.error_max_bets_on_table": `Invalid number of bets. Maximum is {{maxValue}}.`,
		"validation.error_min_bets_on_table": `Place chip on desired bet to start.`,

		"table.column.1": `1ra\ncol.`,
		"table.column.2": `2da\ncol.`,
		"table.column.3": `3ra\ncol.`,
		"table.low": `Bajo`,
		"table.high": `Alto`,
		"table.even": `Par`,
		"table.odd": `Impar`,
		"table.red": `Rojo`,
		"table.black": `Negro`,
		"table.part.1": `1ra {{rows}}`,
		"table.part.2": `2da {{rows}}`,
		"table.part.3": `3ra {{rows}}`,

		"combination.street": "Fila",
		"combination.six line": "Dos Filas",
		"combination.column.1": "1ra columna",
		"combination.column.2": "2da columna",
		"combination.column.3": "3ra columna",
		"combination.part.1": "1ra docena",
		"combination.part.2": "2da docena",
		"combination.part.3": "3ra docena",
		"combination.low": `Bajo`,
		"combination.high": `Alto`,
		"combination.even": `Par`,
		"combination.odd": `Impar`,
		"combination.red": `Rojo`,
		"combination.black": `Negro`,
		"combination.nums.1": `Directo`,
		"combination.nums.2": `Dividir`,
		"combination.nums.3": `Trio`,
		"combination.nums.4": `Cuadrante`,
		"combination.nums.5": `Canasta`,
	},
};

export default class Locale {
	static Dict = Dict;

	static Get(key: keyof Dictionary | string, config?: DefaultConfig & CustomArgs): string {
		const { defaultValue, ...params } = config ?? {};
		let result = Dict[$locales.getState().lang]?.[key as keyof Dictionary];
		if (result) {
			for (const [key, value] of Object.entries(params)) {
				result = result.replace(`{{${key}}}`, String(value));
			}
			return result;
		} else {
			if (defaultValue) addUnregisteredKeys({ key, defaultValue } as { key: string; defaultValue: string });
			addUniqueKeyError(key as string);
			return `*${defaultValue || "%Error%"}*`;
		}
	}

	static CreateTemplated(template: Template) {
		return template;
	}
}
