import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$locales, setLanguage} from "../../../store/locales";
import Locale, {Lang} from "../../../core/locale";
import {useState} from "react";

export const Language = ()=>{
	const locales = useStore($locales); //static/img/flags/en.svg
	const [isOpened, setIsOpened] = useState<boolean>(false);

	const onClick = (lang: Lang)=>{
		setLanguage(lang);
		setIsOpened(false);
	}

	return <div className={style.language}>
		<div className={style.lang} onClick={()=>{setIsOpened(!isOpened)}}>
			<img className={style.flag} src={process.env.PUBLIC_URL + `/static/img/flags/${locales.lang}.svg`} alt="flag"/>
			<p className={style.title}>{Locale.Get(`language.${locales.lang}`)}</p>
		</div>
		{ isOpened &&
            <div className={style.langList}>
				{Object.keys(Locale.Dict).map((lang: string, i) => {
					return <div className={style.lang} key={i} onClick={()=>{onClick(lang as Lang)}}>
						<img className={style.flag} src={process.env.PUBLIC_URL + `/static/img/flags/${lang}.svg`}
							 alt="flag"/>
						<p className={style.title}>{Locale.Get(`language.${lang}`)}</p>
					</div>
				})}
            </div>
		}
	</div>
}