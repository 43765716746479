import { cn } from "./classNames";

type CreateVariants = <V extends VariantsGroups, D extends { [group in keyof V]: keyof V[group] }>(
	defaultStyle: string,
	options: {
		variants: V;
		defaultVariants: D;
	},
) => GetStyled<V>;

type GetStyled<Variant extends VariantsGroups> = (options: {
	[group in keyof Partial<Variant>]: keyof Variant[group];
}) => string;

type VariantsGroups = Record<string, GroupVariants>;
type GroupVariants = Record<string, string>;

type PropsVariants<F extends GetStyled<VariantsGroups>> = Parameters<F>[0];

/**
 * @description Create class based variants
 * @param defaultStyle
 * @param options
 * @example
 * const variants = createVariants("flex gap-5", {
 * 	variants: {
 * 		color: { red: "bg-red", yellow: "bg-yellow" },
 * 		size: { small: "min-w-8", big: "min-w-40" },
 * 		visible: { true: "opacity-100", false: "opacity-0" }
 * 	},
 * 	defaultVariants: {
 * 		color: "red",
 * 		size: "small",
 * 		visible: "true",
 * 	}
 * });
 * ...
 * const Component = (props: PropsVariants) => <div className={variants(props)/>}
 * ...
 * <Component color={"red"} size={"big"} />
 * // <div class="bg-red min-w-40 opacity-100"></div>
 */
const createVariants: CreateVariants = (defaultStyle, options) => {
	const getStyled: GetStyled<(typeof options)["variants"]> = (variants) => {
		const groups = Object.entries(options.variants);
		const optionsStyle = groups.map(([groupName, groupVariants]) => {
			const userVariant = variants[groupName] as string | undefined;
			const userValue = userVariant ? groupVariants[userVariant] : null;
			const defaultVariant = options.defaultVariants[groupName] as string;
			const defaultValue = groupVariants[defaultVariant];
			return userValue ?? defaultValue ?? "";
		});
		return cn(defaultStyle, ...optionsStyle);
	};
	return getStyled;
};

export { type PropsVariants, createVariants };
