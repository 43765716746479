import style from "./style.module.scss";

import stop from "./../../../img/debug/stop.svg";
import play from "./../../../img/debug/play.svg";
import pause from "./../../../img/debug/pause.svg";
import { Bool } from "../primitives/bool/Bool";
import { Checkbox } from "../primitives/checkbox/Checkbox";
import { Progress } from "../primitives/progress/Progress";
import { useStore } from "effector-react";
import { $store } from "../../../store";
import { setIsBallShaking, setIsSpin, setNextNumber, setPhysVariant, setWheelIsSpin } from "../../../store/game";
import Constants from "../../../core/constants";
import { SocketControls } from "../socketControls/SocketControls";
import { LocalesControls } from "../localesControls/LocalesControls";

const onRandPhysVariant = () => setPhysVariant(Math.round(Math.random() * (Constants.PHYS_VARIANTS.length - 1)));
const onRandTargetNumber = () =>
	setNextNumber(Constants.NUMBERS[Math.round(Math.random() * (Constants.NUMBERS.length - 1))].num);
const onPauseToggle = () => setIsSpin(!$store.getState().game.isSpin);

export const WheelControls = (props: { onSpin: () => void; onStop: () => void; onReset: () => void }) => {
	const store = useStore($store);

	return (
		<div className={`${style.wheelControls}`}>
			<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
				<div className={style.col}>
					<div className={`${style.group} ${style.flexCol}`}>
						<div className={style.group}>
							<div className={style.row}>
								<p>{`WSS:`}</p>
								<Bool bool={store.app.socketIsReady} />
								<p>{`SPIN:`}</p>
								<Bool bool={store.game.isSpin} />
							</div>
						</div>

						<div className={`${style.group} ${style.flexCol}`}>
							<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
								<p>{`Speed:`}</p>
								<p>{`${store.game.curSpeedCoefficient.toFixed(2)}`}</p>
							</div>
							<Progress value={store.game.curSpeedCoefficient} />
							<Progress value={store.game.curBallRadCoefficient} />
							<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
								<p>{`Rad:`}</p>
								<p>{`${store.game.curBallRadCoefficient.toFixed(2)}`}</p>
							</div>
						</div>
					</div>

					<div className={style.group}>
						<p
							onClick={() => props.onSpin()}
							className={`
                        ${style.btn}
                        ${store.game.isSpin || store.game.curSpeedCoefficient > 0 ? "disabled" : ""}
                    `}
						>
							SPIN
						</p>
						<p
							onClick={onRandTargetNumber}
							className={`
                        ${style.btn}
                        ${store.game.isSpin || store.game.curSpeedCoefficient > 0 ? "disabled" : ""}
                    `}
							style={{ background: store.game.nextNumber.color }}
						>{`N: ${store.game.nextNumber.num}`}</p>
					</div>

					<div className={style.group}>
						<p
							className={`${style.btn} ${!store.game.isSpin ? "disabled" : ""}`}
							onClick={() => props.onStop()}
						>
							FINISH
						</p>
						<p
							className={`
                        ${style.btn}
                        ${!store.game.isSpin && store.game.curSpeedCoefficient === 0 ? "disabled" : ""}
                    `}
							onClick={() => props.onReset()}
						>
							<img src={stop} alt="stop" />
						</p>
						<p
							className={`
                        ${style.btn}
                        ${!store.game.isSpin && store.game.curSpeedCoefficient === 0 ? "disabled" : ""}
                    `}
							onClick={onPauseToggle}
						>
							{!store.game.isSpin && store.game.curSpeedCoefficient !== 1 ? (
								<img src={play} alt="play" />
							) : (
								<img src={pause} alt="pause" />
							)}
						</p>
					</div>

					<div className={`${style.group} ${style.flexCol}`}>
						<div
							style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
							className={`${store.game.isSpin ? "disabled" : ""}`}
						>
							<p>{`Phys variant:`}</p>
							<p
								className={`${style.btn}`}
								onClick={onRandPhysVariant}
							>{`${store.game.physVariant + 1}/${Constants.PHYS_VARIANTS.length}`}</p>
						</div>
						<p
							className={style.group}
							style={{ wordBreak: "break-word", maxHeight: 50, overflowY: "auto" }}
							onClick={() => {
								console.log(Constants.PHYS_VARIANTS[store.game.physVariant]);
							}}
							title={"Click to print in console"}
						>
							{JSON.stringify(Constants.PHYS_VARIANTS[store.game.physVariant])}
						</p>
					</div>
					<div className={`${style.group} ${style.flexCol}`}>
						<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
							<p>{`Progress`}</p>
							<p>{`${store.game.progressCoefficient.toFixed(2)}`}</p>
						</div>
						<Progress value={store.game.progressCoefficient} />
					</div>
				</div>

				<SocketControls />
			</div>

			<div
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "end",
				}}
			>
				<div className={style.col}>
					<div className={`${style.group} ${style.flexCol}`}>
						<div
							className={style.group}
							style={{ cursor: "pointer" }}
							onClick={() => setWheelIsSpin(!store.game.wheelIsSpin)}
						>
							<p>{`Wheel spin`}</p>
							<Checkbox bool={store.game.wheelIsSpin} />
						</div>
						<div
							className={style.group}
							style={{ cursor: "pointer" }}
							onClick={() => setIsBallShaking(!store.game.isBallShaking)}
						>
							<p>{`Ball shaking`}</p>
							<Checkbox bool={store.game.isBallShaking} />
						</div>
					</div>
				</div>
				<LocalesControls />
			</div>
		</div>
	);
};

onRandPhysVariant();
onRandTargetNumber();
