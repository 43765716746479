import { createStore, createEvent, sample } from "effector";
import { selectNumbers, setSelectedGroup } from "./table";
import { createEffect } from "effector/compat";

export type ANIM_EL = {
	delay: number;
	title: string;
	subtitle: string;
	value: number;
	flag: string;
	numbers: number[];
};

export type AnimVariant = {
	title: string;
	subtitle: string;
	value: number;
};

export type Variant = {
	isAnimating: boolean;
	index: number;

	anim: AnimVariant;
};

const mock: Variant = {
	isAnimating: false,
	index: 0,

	anim: {
		title: "-",
		subtitle: "-",
		value: 1,
	},
};

export const setTableVariantInfo = createEvent<AnimVariant>();
export const setAnimIndex = createEvent<number>();
export const setVariant = createEvent<ANIM_EL>();
export const clear = createEvent();

const applyVariantFx = createEffect((animData: ANIM_EL) => {
	const anim: AnimVariant = {
		title: animData.title,
		subtitle: animData.subtitle,
		value: animData.value,
	};

	selectNumbers(animData.numbers);
	setSelectedGroup(animData.flag);

	return anim;
});

export const $variant = createStore<Variant>(mock)
	.on(setTableVariantInfo, (state, anim) => {
		return { ...state, anim };
	})
	.on(setAnimIndex, (state, index) => {
		return { ...state, index };
	})
	.on(applyVariantFx.doneData, (state, anim) => {
		return { ...state, anim };
	})
	.reset(clear);

sample({ clock: setVariant, target: applyVariantFx });

export const SECTIONS_ANIM: ANIM_EL[] = [
	{
		// 1st12, 2nd12, 3rd12 ----------------------------
		delay: 2000,
		title: "dozen",
		subtitle: "variants.1st12",
		value: 3,
		flag: "1st12",
		numbers: [],
	},
	{
		delay: 2000,
		title: "dozen",
		subtitle: "variants.2nd12",
		value: 3,
		flag: "2nd12",
		numbers: [],
	},
	{
		delay: 2000,
		title: "dozen",
		subtitle: "variants.3rd12",
		value: 3,
		flag: "3rd12",
		numbers: [],
	},
	{
		// low, high ------------------------------------
		delay: 3000,
		title: "combination.low",
		subtitle: "variants.low",
		value: 2,
		flag: "low",
		numbers: [],
	},
	{
		delay: 3000,
		title: "combination.high",
		subtitle: "variants.high",
		value: 2,
		flag: "high",
		numbers: [],
	},
	{
		// col1, col2, col3 -----------------------------
		delay: 2000,
		title: "column",
		subtitle: "variants.col1",
		value: 3,
		flag: "col1",
		numbers: [],
	},
	{
		delay: 2000,
		title: "column",
		subtitle: "variants.col2",
		value: 3,
		flag: "col2",
		numbers: [],
	},
	{
		delay: 2000,
		title: "column",
		subtitle: "variants.col3",
		value: 3,
		flag: "col3",
		numbers: [],
	},
	{
		// red, black -----------------------------------
		delay: 3000,
		title: "combination.red",
		subtitle: "variants.red",
		value: 2,
		flag: "red",
		numbers: [],
	},
	{
		delay: 3000,
		title: "combination.black",
		subtitle: "variants.black",
		value: 2,
		flag: "black",
		numbers: [],
	},
	{
		// even, odd ------------------------------------
		delay: 3000,
		title: "combination.even",
		subtitle: "variants.even",
		value: 2,
		flag: "even",
		numbers: [],
	},
	{
		delay: 3000,
		title: "combination.odd",
		subtitle: "variants.odd",
		value: 2,
		flag: "odd",
		numbers: [],
	},

	{
		// Straight up -----------------------------------
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [1],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [24],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [19],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [11],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [31],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [8],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [15],
	},
	{
		delay: 500,
		title: "straight_up",
		subtitle: "variants.straight_up",
		value: 36,
		flag: "-",
		numbers: [23],
	},

	{
		// Street -----------------------------------
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [1, 2, 3],
	},
	{
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [4, 5, 6],
	},
	{
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [7, 8, 9],
	},
	{
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [10, 11, 12],
	},
	{
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [13, 14, 15],
	},
	{
		delay: 1000,
		title: "street",
		subtitle: "variants.street",
		value: 12,
		flag: "-",
		numbers: [16, 17, 18],
	},

	{
		// Split -----------------------------------
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [3, 6],
	},
	{
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [7, 10],
	},
	{
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [15, 18],
	},
	{
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [20, 23],
	},
	{
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [22, 25],
	},
	{
		delay: 1000,
		title: "split",
		subtitle: "variants.split",
		value: 18,
		flag: "-",
		numbers: [29, 32],
	},

	{
		// Corner -----------------------------------
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [20, 21, 23, 24],
	},
	{
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [13, 14, 16, 17],
	},
	{
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [11, 12, 14, 15],
	},
	{
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [4, 5, 7, 8],
	},
	{
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [2, 3, 5, 6],
	},
	{
		delay: 1000,
		title: "corner",
		subtitle: "variants.corner",
		value: 9,
		flag: "-",
		numbers: [1, 2, 4, 5],
	},

	{
		// Basket ----------------------------
		delay: 3000,
		title: "basket",
		subtitle: "variants.basket",
		value: 7,
		flag: "-",
		numbers: [0, 100, 1, 2, 3],
	},

	{
		// Trio ----------------------------
		delay: 3000,
		title: "trio",
		subtitle: "variants.trio",
		value: 12,
		flag: "-",
		numbers: [0, 1, 2],
	},
	{
		delay: 3000,
		title: "trio",
		subtitle: "variants.trio",
		value: 12,
		flag: "-",
		numbers: [100, 2, 3],
	},
	{
		delay: 3000,
		title: "trio",
		subtitle: "variants.trio",
		value: 12,
		flag: "-",
		numbers: [0, 100, 2],
	},

	{
		// Double street ----------------------------
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [1, 2, 3, 4, 5, 6],
	},
	{
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [4, 5, 6, 7, 8, 9],
	},
	{
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [7, 8, 9, 10, 11, 12],
	},
	{
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [10, 11, 12, 13, 14, 15],
	},
	{
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [13, 14, 15, 16, 17, 18],
	},
	{
		delay: 1000,
		title: "double_street",
		subtitle: "variants.w_street",
		value: 6,
		flag: "-",
		numbers: [16, 17, 18, 19, 20, 21],
	},
];
