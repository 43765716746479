import { createStore, createEvent, sample, createEffect } from "effector";
import Locale, { Lang } from "../core/locale";

export type UnregKeys = { [key: string]: string };

export type Locales = {
	errors: string[];
	unregisteredKeys: UnregKeys;
	lang: Lang;
	debug: boolean;
};

const mock: Locales = {
	errors: [],
	unregisteredKeys: {},
	lang: "ht",
	debug: false,
};

export const setLanguage = createEvent<Lang>();
export const setDictDebug = createEvent<boolean>();
export const addUniqueKeyError = createEvent<string>();
export const addUnregisteredKeys = createEvent<{ key: string; defaultValue: string }>();
export const clear = createEvent();

const validateLanguageFx = createEffect((lang: string) => {
	const translate = Locale.Dict[lang as keyof typeof Locale.Dict];
	if (!translate) throw new Error(`Translations with lang "${lang}" does not exist`);
	return lang as Lang;
});

export const $locales = createStore<Locales>(mock)
	.on(validateLanguageFx.doneData, (state, lang) => {
		localStorage.setItem("lang", lang);
		return { ...state, lang };
	})
	.on(setDictDebug, (state, debug: boolean) => {
		return { ...state, debug };
	})
	.on(addUniqueKeyError, (state, key: string) => {
		if (state.errors.indexOf(key) === -1) state.errors.push(key);
	})
	.on(addUnregisteredKeys, (state, props: { key: string; defaultValue: string }) => {
		if (!state.unregisteredKeys[props.key]) state.unregisteredKeys[props.key] = props.defaultValue;
	})
	.reset(clear);

sample({
	clock: setLanguage,
	target: validateLanguageFx,
});
