import { createEffect, createEvent, createStore, sample, scopeBind } from "effector";
import Utils from "../../core/utils";
import { type Notification } from "./types";

export const $notifications = createStore<Notification[]>([]);

export const addNotification = createEvent<Notification>();
export const removeNotification = createEvent<Pick<Notification, "id">>();

const addNotificationFx = createEffect(addNotification);
const createNotificationDestructionTimerFx = createEffect(async (data: Pick<Notification, "id"> & { time: number }) => {
	const boundedRemove = scopeBind(removeNotification, { safe: true });
	return setTimeout(() => {
		boundedRemove({ id: data.id });
	}, data.time);
});
export const createSelfDestructedNotificationFx = createEffect(
	async (data: Pick<Notification, "message" | "type"> & { time: number }) => {
		const id = Utils.getRandomId();
		const timerId = await createNotificationDestructionTimerFx({ id, time: data.time });
		await addNotificationFx({
			id,
			timerId,
			type: data.type,
			message: data.message,
		});
	},
);

sample({
	clock: addNotification,
	source: $notifications,
	fn: (notifications, notification) => [...notifications, notification],
	target: $notifications,
});
sample({
	clock: removeNotification,
	source: $notifications,
	fn: (notifications, { id }) => notifications.filter((error) => error.id !== id),
	target: $notifications,
});
