import { sample } from "effector";
import {
	$nums,
	$zeroes,
	balanceChanged,
	betsCancellationEnabledChanged,
	betsEnabledChanged,
	betsLimitsChanged,
	loadHistoryFX,
	roundIdChanged,
} from "./store";
import { authed, betResultReceived, betted, nobetsed, started } from "../../core/socket";
import { updateHistoryFx } from "../history/store";
import { GAME_ACTION, GAME_STATE } from "../../store/game";
import { $app } from "../../store/app";

sample({
	clock: [authed, betted],
	fn: (data) => data.balance,
	target: balanceChanged,
});
sample({
	clock: $app,
	fn: (data) => data.balance,
	target: balanceChanged,
});
sample({
	clock: authed,
	fn: (data) => ({ min: data.minBet, max: data.maxBet }),
	target: betsLimitsChanged,
});
sample({
	clock: authed,
	fn: (data) => {
		return !([GAME_ACTION.nobets] as GAME_STATE[]).includes(data.round_state_name);
	},
	target: betsEnabledChanged,
});
sample({
	clock: authed,
	fn: (data) => data.isCashdesk,
	target: betsCancellationEnabledChanged,
});
sample({
	clock: [started],
	fn: () => true,
	target: betsEnabledChanged,
});
sample({
	clock: [nobetsed],
	fn: () => false,
	target: betsEnabledChanged,
});
sample({
	clock: [authed],
	fn: (action) => action.round_id,
	target: roundIdChanged,
});
sample({
	clock: [started],
	fn: (action) => action.round,
	target: roundIdChanged,
});
sample({
	clock: betResultReceived,
	source: { $zeroes, $nums },
	fn: (states, update) => ({ allNums: [...states.$zeroes, ...states.$nums], update }),
	target: updateHistoryFx,
});
sample({
	clock: authed,
	fn: (data) => data.bet_history,
	target: loadHistoryFX,
});
