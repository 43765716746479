export const COLUMNS = 3;
export const ROWS = 12;
export const ROWS_IN_PART = 4;
export const BLACK_NUMS = [2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35];
export const BETS = [
	{ key: "25", value: 25 * 100, color: "rgb(21, 101, 192)" },
	{ key: "100", value: 100 * 100, color: "rgb(67, 160, 71)" },
	{ key: "500", value: 500 * 100, color: "rgb(142, 36, 170)" },
	{ key: "1K", value: 1000 * 100, color: "rgb(198, 40, 40)" },
	{ key: "2K", value: 2000 * 100, color: "rgb(255, 114, 0)" },
] as const;
export const PREFIX = {
	num: "num",
	nums: "nums",
	row: "row",
	rows: "rows",
	column: "column",
	columns: "columns",
	part: "part",
	parts: "parts",
} as const;
export const ZEROES = [
	{ title: "0", value: 0 },
	{ title: "00", value: 100 },
] as const;
export const CELLS = {
	low: "low",
	high: "high",
	even: "even",
	odd: "odd",
	red: "red",
	black: "black",
} as const;
export const PRESETS = [
	{
		name: "Red splits",
		numbers: [
			[9, 12],
			[16, 19],
			[18, 21],
			[27, 30],
		],
	},
	{
		name: "Black splits",
		numbers: [
			[8, 11],
			[10, 13],
			[10, 11],
			[17, 20],
			[26, 29],
			[28, 29],
			[28, 31],
		],
	},
	{ name: "Snake", numbers: [[1], [5], [9, 12], [14], [16, 19], [23], [27, 30], [32], [34]] },
] as { name: string; numbers: number[][] }[];
export const MAX_BETS_COUNT_PER_TABLE = 15;
export const MIN_BETS_VALUE_PET_SLOT = 0;
export const MAX_BETS_VALUE_PER_SLOT = 2000 * 100;
