import React, { useCallback, useRef } from "react";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { PayInButton, BetsTable, Payment, SignIn, useBets, useUser } from "../../features/table";
import { useAuth } from "../../features/signIn/hooks";
import { ShortHistory, useLastStatus, TICKET_STATUS } from "../../features/history";
import { Button } from "../../shared/ui/button";
import { ReactComponent as Copy } from "../../shared/assets/copy.svg";
import { useOutsideClick } from "../../shared/hooks";
import { $app } from "../../store/app";
import { cn } from "../../shared/lib/classNames";

const $isSocketReady = $app.map((state) => state.socketIsReady);

export const Betslip = () => {
	const [showHistory, setHistoryVisibility] = React.useState(false);
	const auth = useAuth();
	const { applyBets, isSending } = useBets();
	const { historyShown } = useLastStatus();
	const { betsEnabled } = useUser();
	const socketIsReady = useUnit($isSocketReady);
	const buttonRef = useRef<HTMLButtonElement>(null);
	const historyRef = useOutsideClick<HTMLDivElement>({
		onOutsideClick: () => {
			setHistoryVisibility(false);
		},
		ignored: [buttonRef],
	});

	const handleHistoryClick = () => {
		setHistoryVisibility((v) => !v);
		historyShown();
	};
	const closeHistory = useCallback(() => {
		setHistoryVisibility(false);
	}, []);

	return (
		<div className={style.betslip} style={{ position: "relative" }}>
			{showHistory ? (
				<ShortHistory
					ref={historyRef}
					style={{ position: "absolute", left: 0, bottom: `100%`, zIndex: 10 }}
					onTitleClick={closeHistory}
					onRebetClick={closeHistory}
					chevron={true}
				/>
			) : null}
			<div className={style.controls}>
				<Button
					ref={buttonRef}
					className={style.history}
					variant={"contained"}
					colored={"basic"}
					onClick={handleHistoryClick}
				>
					<LastBetStatusIndicator />
					<Copy height={14} width={14} />
				</Button>
				<BetsTable style={{ flexGrow: 1 }} />
				<Payment />
				{auth.isAuthenticated ? (
					<PayInButton onClick={applyBets} disabled={!socketIsReady || !betsEnabled || isSending} />
				) : (
					<SignIn />
				)}
			</div>
		</div>
	);
};

const LastBetStatusIndicator = () => {
	const { status } = useLastStatus();

	if (!status) return null;
	return (
		<div
			className={cn(
				style.status,
				status === TICKET_STATUS.paid && style.statusPaidOut,
				status === TICKET_STATUS.lost && style.statusLost,
			)}
		/>
	);
};
