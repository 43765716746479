import { createEffect, createEvent, sample, scopeBind } from "effector";
import { $app, addResults, setIsDebug } from "./app";
import { type Lang } from "../core/locale";
import { $locales, setLanguage } from "./locales";
import { type WS_ACTION_BETRESULT } from "../core/socket";

type BroadcastData =
	| {
			channel: typeof CHANNEL.debug;
			data: boolean;
	  }
	| {
			channel: typeof CHANNEL.lang;
			data: Lang;
	  }
	| {
			channel: typeof CHANNEL.betResult;
			data: WS_ACTION_BETRESULT;
	  };

const CHANNEL = {
	debug: "debug",
	lang: "lang",
	betResult: "bet-result",
} as const;

const dataBroadcast = new BroadcastChannel("data");

const isDebugChanged = createEvent<boolean>();
const langChanged = createEvent<Lang>();
const resultsChanged = createEvent<WS_ACTION_BETRESULT>();

const subscribeDataFx = createEffect(() => {
	const boundedDebugUpdate = scopeBind(isDebugChanged, { safe: true });
	const boundedLangUpdate = scopeBind(langChanged, { safe: true });
	const boundedBetResultUpdate = scopeBind(resultsChanged, { safe: true });

	dataBroadcast.addEventListener("message", (event) => {
		const { channel, data } = event.data as BroadcastData;
		switch (channel) {
			case CHANNEL.debug: {
				boundedDebugUpdate(data);
				break;
			}
			case CHANNEL.lang: {
				boundedLangUpdate(data);
				break;
			}
			case CHANNEL.betResult: {
				boundedBetResultUpdate(data);
				break;
			}
			default: {
				console.error(`Channel "${channel}" not found.`);
			}
		}
	});
});

const sendDataFx = createEffect((data: BroadcastData) => {
	dataBroadcast.postMessage(data);
});

sample({
	clock: setIsDebug,
	fn: (data) => ({ channel: CHANNEL.debug, data }),
	target: sendDataFx,
});
sample({
	clock: isDebugChanged,
	source: $app,
	filter: (app, isDebug) => app.isDebug !== isDebug,
	fn: (_, isDebug) => isDebug,
	target: setIsDebug,
});

sample({
	clock: setLanguage,
	fn: (data) => ({ channel: CHANNEL.lang, data }),
	target: sendDataFx,
});
sample({
	clock: langChanged,
	source: $locales,
	filter: (locales, lang) => locales.lang !== lang,
	fn: (_, lang) => lang,
	target: setLanguage,
});

sample({
	clock: addResults,
	fn: (data) => ({ channel: CHANNEL.betResult, data }),
	target: sendDataFx,
});
sample({
	clock: resultsChanged,
	source: $app,
	filter: (app, results) => !app.results.some((el) => el.betId === results?.betId),
	fn: (_, results) => results,
	target: addResults,
});

subscribeDataFx().then();
