import {
	type Effect as EffectorEffect,
	type Event as EffectorEvent,
	type Store as EffectorStore,
	attach,
	combine,
	createEffect,
	createEvent,
	createStore,
	sample,
} from "effector";
import {
	type Bet,
	type Cell,
	type Column,
	type ColumnId,
	type ColumnsId,
	type Num,
	type NumId,
	type NumsId,
	type Part,
	type PartId,
	type PartsId,
	type Row,
	type RowId,
	type RowsId,
	type SelectedState,
	type Slot,
	type SlotId,
	type SlotPlace,
	type SpecialCellId,
	type TicketStatus,
	type UserBet,
} from "./types";
import {
	BETS,
	BLACK_NUMS,
	CELLS,
	COLUMNS,
	MAX_BETS_COUNT_PER_TABLE,
	MAX_BETS_VALUE_PER_SLOT,
	MIN_BETS_VALUE_PET_SLOT,
	PREFIX,
	ROWS,
	ROWS_IN_PART,
	ZEROES,
} from "./config";
import Utils from "../../core/utils";
import { getOrdinalNumber } from "../../shared/lib/getOrdinalNumber";
import { UTC } from "../../shared/lib/time";
import Socket, { type WS_RESPONSE_AUTH_DATA } from "../../core/socket";
import { $betsHistory, addToHistory, updateHistoryFx } from "../history/store";
import { TICKET_STATUS } from "../history";
import { type HistoryItem, type SubBet, type Ticket } from "../history/types";
import { getTransformedAmount } from "../../shared/lib/getTransformedAmount";
import { $app } from "../../store/app";
import Locale, { type Template } from "../../core/locale";

export const numId = (id: number | string): NumId => `${PREFIX.num}-${id}`;
export const columnId = (id: number | string): ColumnId => `${PREFIX.column}-${id}`;
export const rowId = (id: number | string): RowId => `${PREFIX.row}-${id}`;
export const partId = (id: number | string): PartId => `${PREFIX.part}-${id}`;
export const idFromIds = (ids: string[]) => ids.join(".");
export const numsId = (ids: string[]): NumsId => `${PREFIX.nums}:${idFromIds(ids)}`;
export const rowsId = (ids: string[]): RowsId => `${PREFIX.rows}:${idFromIds(ids)}`;
export const columnsId = (ids: string[]): ColumnsId => `${PREFIX.columns}:${idFromIds(ids)}`;
export const partsId = (ids: string[]): PartsId => `${PREFIX.parts}:${idFromIds(ids)}`;
export const isNumId = (id: string): id is NumId => new RegExp(`^${PREFIX.num}`, "i").test(id);
export const isColumnId = (id: string): id is ColumnId => new RegExp(`^${PREFIX.column}`, "i").test(id);
export const isRowId = (id: string): id is RowId => new RegExp(`^${PREFIX.row}`, "i").test(id);
export const isPartId = (id: string): id is PartId => new RegExp(`^${PREFIX.part}`, "i").test(id);
export const isSpecialCellId = (id: string): id is SpecialCellId =>
	new RegExp(`^(${Object.values(CELLS).join("|")})`, "i").test(id);
export const isNumsId = (id: string): id is NumsId => new RegExp(`^${PREFIX.nums}`, "i").test(id);
export const isColumnsId = (id: string): id is ColumnsId => new RegExp(`^${PREFIX.columns}`, "i").test(id);
export const isRowsId = (id: string): id is RowsId => new RegExp(`^${PREFIX.rows}`, "i").test(id);
export const isPartsId = (id: string): id is PartsId => new RegExp(`^${PREFIX.parts}`, "i").test(id);

export const createZeroes: () => Num[] = () => {
	let nums: Num[] = [];
	for (const zero of ZEROES) {
		nums = [
			...nums,
			{
				id: numId(zero.title),
				num: zero.value,
				title: zero.title,
				column: 0,
				row: 0,
				part: 0,
				columnId: columnId(0),
				rowId: rowId(0),
				partId: partId(0),
				isEven: false,
				isOdd: false,
				isLow: false,
				isHigh: false,
				isBlack: false,
				isRed: false,
			},
		];
	}
	return nums;
};
export const createTableNums: (args: [columns: Column[], rows: Row[], parts: Part[]]) => Num[] = ([
	columns,
	rows,
	parts,
]) => {
	let nums: Num[] = [];
	const columnsLength = columns.length;
	for (const column of columns) {
		const rowLength = columns.length;
		for (const row of rows) {
			const num = rowLength * (row.row - 1) + column.column;
			const part = Math.ceil(num / (ROWS_IN_PART * columnsLength));
			const numPartId = parts.find((p) => p.part === part)?.id || partId(part);
			const isLow = row.row <= 0.5 * rows.length;
			const isEven = !(num % 2);
			nums = [
				...nums,
				{
					id: numId(num),
					num,
					title: String(num),
					column: column.column,
					row: row.row,
					columnId: column.id,
					rowId: row.id,
					part,
					partId: numPartId,
					isEven,
					isOdd: !isEven,
					isLow,
					isHigh: !isLow,
					isBlack: BLACK_NUMS.includes(num),
					isRed: !BLACK_NUMS.includes(num),
				},
			];
		}
	}
	return nums;
};
export const createColumns: (columns: number, startIndex?: number) => Column[] = (columns, startIndex = 1) => {
	const columnsArr: number[] = new Array(columns).fill(startIndex).map((c, i) => c + i);
	return columnsArr.map((column) => ({ id: columnId(column), column }));
};
export const createRows: (rows: number, startIndex?: number, extraIndexes?: number[]) => Row[] = (
	rows,
	startIndex = 1,
	extraIndexes = [],
) => {
	const rowsArr: number[] = new Array(rows).fill(startIndex).map((c, i) => c + i);
	return rowsArr.map((row) => ({ id: rowId(row), row, extra: extraIndexes.includes(row) }));
};
export const getNumsIds: (...nums: Num[]) => NumId[] = (...nums) => {
	const sortedNums = [...nums].sort((a, b) => a.num - b.num);
	return sortedNums.map((n) => n.id);
};
export const createParts: (rows: Row[], rowsPerPart: number) => Part[] = (rows, perPart) => {
	const parts = Math.ceil(rows.length / perPart);
	return new Array(parts).fill(1).map((p, i) => ({ id: partId(p + i), part: p + i }));
};
export const getColumnsCells: (columns: Column[]) => Cell[] = (columns) => {
	return columns.map((column) => {
		const title = `${getOrdinalNumber(column.column)} col.`;
		return {
			id: column.id,
			title,
			titleKey: Locale.CreateTemplated({
				key: `table.column.${column.column}`,
				args: { defaultValue: title, column: column.column },
			}),
			column: column.column,
			row: -1,
			part: -1,
			columnId: column.id,
			rowId: rowId("none"),
			partId: partId("none"),
		};
	});
};
export const getPartsCells: (parts: Part[]) => Cell[] = (parts) => {
	return parts.map((part) => {
		const title = `${getOrdinalNumber(part.part)} ${ROWS}`;
		return {
			id: part.id,
			title,
			titleKey: Locale.CreateTemplated({
				key: `table.part.${part.part}`,
				args: { defaultValue: title, part: part.part, rows: ROWS },
			}),
			column: -1,
			row: -1,
			part: part.part,
			columnId: columnId("none"),
			rowId: rowId("none"),
			partId: part.id,
		};
	});
};
export const createSlots: (
	args: [nums: Num[], zeroes: Num[], columnsCells: Cell[], partsCells: Cell[], rows: Row[]],
) => Slot[] = ([nums, zeroes, columnsCells, partsCells, rows]) => {
	const maxColumn = [...columnsCells].sort((a, b) => b.column - a.column).at(0);

	const getNumsMultiply = (targetNums: (Num | NumId | Cell)[]) => {
		const multiply = Math.floor(nums.length / targetNums.length);
		return Math.max(1, multiply);
	};
	const getRowsMultiply = (rows: RowId[]) => {
		const filteredNums = [...zeroes, ...nums].filter(({ rowId }) => rows.includes(rowId));
		const multiply = Math.floor(nums.length / filteredNums.length);
		return Math.max(1, multiply);
	};
	const getColumnsMultiply = (columns: ColumnId[]) => {
		const filteredNums = nums.filter(({ columnId }) => columns.includes(columnId));
		const multiply = Math.floor(nums.length / filteredNums.length);
		return Math.max(1, multiply);
	};
	const getPartsMultiply = (parts: PartId[]) => {
		const filteredNums = nums.filter(({ partId }) => parts.includes(partId));
		const multiply = Math.floor(nums.length / filteredNums.length);
		return Math.max(1, multiply);
	};
	const getSpecialCellsMultiply = (cellId: SpecialCellId) => {
		switch (cellId) {
			case CELLS.red: {
				const filteredNums = nums.filter(({ isRed }) => isRed);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			case CELLS.black: {
				const filteredNums = nums.filter(({ isBlack }) => isBlack);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			case CELLS.even: {
				const filteredNums = nums.filter(({ isEven }) => isEven);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			case CELLS.odd: {
				const filteredNums = nums.filter(({ isOdd }) => isOdd);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			case CELLS.low: {
				const filteredNums = nums.filter(({ isLow }) => isLow);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			case CELLS.high: {
				const filteredNums = nums.filter(({ isHigh }) => isHigh);
				const multiply = Math.floor(nums.length / filteredNums.length);
				return Math.max(1, multiply);
			}
			default: {
				return 1;
			}
		}
	};

	const numsSlots = nums
		.map((num) => {
			const numSlots: Slot[] = [
				{
					id: numsId(getNumsIds(num)),
					title: "",
					ids: [{ id: num.id }],
					parent: num.id,
					place: `2.2`,
					fill: true,
					multiply: getNumsMultiply([num]),
				},
			];

			const rightNumber = nums.find((n) => n.row === num.row && n.column === num.column + 1);
			const topNumber = nums.find((n) => n.row === num.row - 1 && n.column === num.column);
			const topRightNumber = nums.find((n) => n.column === rightNumber?.column && n.row === topNumber?.row);

			if (rightNumber) {
				numSlots.push({
					id: numsId(getNumsIds(num, rightNumber)),
					title: "",
					ids: [{ id: num.id }, { id: rightNumber.id }],
					parent: num.id,
					place: `1.2`,
					multiply: getNumsMultiply([num, rightNumber]),
				});
			}
			if (topNumber) {
				numSlots.push({
					id: numsId(getNumsIds(num, topNumber)),
					title: "",
					ids: [{ id: num.id }, { id: topNumber.id }],
					parent: num.id,
					place: `2.1`,
					multiply: getNumsMultiply([num, topNumber]),
				});
			}
			if (topRightNumber) {
				numSlots.push({
					id: numsId(getNumsIds(num, topRightNumber, rightNumber!, topNumber!)),
					title: "",
					ids: [{ id: num.id }, { id: topRightNumber.id }, { id: rightNumber!.id }, { id: topNumber!.id }],
					parent: num.id,
					place: `1.1`,
					multiply: getNumsMultiply([num, topRightNumber, rightNumber!, topNumber!]),
				});
			}

			if (num.column === maxColumn?.column) {
				const previousRowId = rows.find(({ row }) => row === num.row - 1)?.id || rowId(0);
				const currentRowId = rows.find(({ row }) => row === num.row)?.id || rowId("none");
				numSlots.push({
					id: rowsId([currentRowId]),
					title: "",
					ids: [{ id: currentRowId }],
					parent: num.id,
					place: `1.2`,
					multiply: getRowsMultiply([currentRowId]),
				});
				numSlots.push({
					id: rowsId([previousRowId, currentRowId]),
					title: "",
					ids: [{ id: previousRowId }, { id: currentRowId }],
					parent: num.id,
					place: `1.1`,
					multiply: getRowsMultiply([previousRowId, currentRowId]),
				});
			}

			return numSlots;
		})
		.flat();

	const specialSlots: Slot[] = (() => {
		const slots: Slot[] = [];

		const extraNumsSlots: [parent: NumId, place: SlotPlace, ids: string[]][] = [
			[numId("3"), "2.1", ["00", "3"]],
			[numId("2"), "1.1", ["00", "2", "3"]],
			[numId("2"), "2.1", ["0", "00", "2"]],
			[numId("1"), "1.1", ["0", "1", "2"]],
			[numId("1"), "2.1", ["0", "1"]],
			[numId("0"), "1.2", ["0", "00"]],
		];
		extraNumsSlots.forEach(([parent, place, ids]) => {
			const realIds = ids.map(numId);
			slots.push({
				id: numsId(realIds),
				title: "",
				ids: realIds.map((id) => ({ id })),
				parent,
				place,
				multiply: getNumsMultiply(realIds),
			});
		});

		for (const cell of zeroes) {
			slots.push({
				id: numsId([cell.id]),
				title: "",
				ids: [{ id: cell.id }],
				parent: cell.id,
				place: "2.2",
				fill: true,
				multiply: getNumsMultiply([cell]),
			});
		}

		for (const column of columnsCells) {
			slots.push({
				id: columnsId([column.id]),
				title: "",
				ids: [{ id: column.id }],
				parent: column.id,
				place: "2.2",
				fill: true,
				multiply: getColumnsMultiply([column.columnId]),
			});
		}

		for (const part of partsCells) {
			slots.push({
				id: partsId([part.id]),
				title: "",
				ids: [{ id: part.id }],
				parent: part.id,
				place: "2.2",
				fill: true,
				multiply: getPartsMultiply([part.partId]),
			});
		}

		const cells = Object.values(CELLS);
		for (const cell of cells) {
			slots.push({
				id: cell,
				title: cell,
				ids: [{ id: cell }],
				parent: cell,
				place: `2.2`,
				fill: true,
				multiply: getSpecialCellsMultiply(cell),
			});
		}

		return slots;
	})();

	return [...numsSlots, ...specialSlots];
};
const collapseBetsInSlot: (userBets: UserBet[], bets: Bet[], slotId: SlotId) => UserBet[] = (allBets, bets, slotId) => {
	const sortedBets = [...bets].sort((a, b) => b.value - a.value);
	const betsSum = allBets.reduce((val, bet) => {
		const betValue = bets.find(({ key }) => key === bet.betKey);
		return val + (betValue?.value ?? 0);
	}, 0);
	let usedSum = 0;
	const collapsedBets: UserBet[] = [];
	for (const bet of sortedBets) {
		const int = Math.trunc((betsSum - usedSum) / bet.value);
		if (int > 0) {
			usedSum = usedSum + int * bet.value;
			collapsedBets.push(...new Array(int).fill(0).map(() => ({ slotId, betKey: bet.key })));
		}
	}
	return collapsedBets;
};
const collapseSelectedBets: (userBets: UserBet[], bets: Bet[]) => UserBet[] = (allBets, bets) => {
	const betsBySlots = allBets.reduce<{ [slotId: string]: { slotId: SlotId; slotBets: UserBet[] } }>((prev, bet) => {
		if (!prev[bet.slotId]) return { ...prev, [bet.slotId]: { slotId: bet.slotId, slotBets: [bet] } };
		return { ...prev, [bet.slotId]: { ...prev[bet.slotId], slotBets: [...prev[bet.slotId].slotBets, bet] } };
	}, {});
	return Object.values(betsBySlots)
		.map(({ slotId, slotBets }) => collapseBetsInSlot(slotBets, bets, slotId))
		.flat();
};
const getUserBetsAmount = (userBets: UserBet[], bets: Bet[]) => {
	let value = 0;
	for (const bet of userBets) {
		const betValue = bets.find(({ key }) => key === bet.betKey)?.value ?? 0;
		value = value + betValue;
	}
	return value;
};
const checkBetsMaxCountPerTable = (userBets: UserBet[], maxOnTable: number) => {
	let memorized: string[] = [];
	for (const bet of userBets) {
		if (!memorized.includes(bet.slotId)) memorized = [...memorized, bet.slotId];
		if (memorized.length > maxOnTable) return false;
	}
	return true;
};
const checkBetsMaxSlotAmountValue = (userBets: UserBet[], bets: Bet[], maxBet: number) => {
	let memorized: { [slotId: string]: number } = {};
	for (const bet of userBets) {
		const value = bets.find(({ key }) => key === bet.betKey)?.value ?? 0;
		if (!memorized[bet.slotId]) memorized = { ...memorized, [bet.slotId]: value };
		else memorized = { ...memorized, [bet.slotId]: memorized[bet.slotId] + value };

		if (memorized[bet.slotId] > maxBet) return false;
	}
	return true;
};
const checkBetsMinTableAmountValue = (userBets: UserBet[], bets: Bet[], minBet: number) => {
	let value = 0;
	for (const bet of userBets) {
		const betValue = bets.find(({ key }) => key === bet.betKey)?.value ?? 0;
		value = value + betValue;
		if (value >= minBet) return true;
	}
	return false;
};
const checkBetsMaxTableAmountValue = (userBets: UserBet[], bets: Bet[], maxBet: number) => {
	let value = 0;
	for (const bet of userBets) {
		const betValue = bets.find(({ key }) => key === bet.betKey)?.value ?? 0;
		value = value + betValue;
		if (value > maxBet) return false;
	}
	return true;
};

type CreateNumsSlotData = (
	slot: Slot,
	nums: Num[],
	data: { status: TicketStatus; amount: number; win: number },
) => Ticket;
const getNumsCombination = (totalValues: number) => {
	if (totalValues === 1) {
		const name = "straight";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.nums.${totalValues}`,
				args: { defaultValue: name, nums: totalValues },
			}),
		};
	} else if (totalValues === 2) {
		const name = "split";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.nums.${totalValues}`,
				args: { defaultValue: name, nums: totalValues },
			}),
		};
	} else if (totalValues === 3) {
		const name = "trio";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.nums.${totalValues}`,
				args: { defaultValue: name, nums: totalValues },
			}),
		};
	} else if (totalValues === 4) {
		const name = "corner";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.nums.${totalValues}`,
				args: { defaultValue: name, nums: totalValues },
			}),
		};
	} else if (totalValues === 5) {
		const name = "basket";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.nums.${totalValues}`,
				args: { defaultValue: name, nums: totalValues },
			}),
		};
	}
	return {
		name: "",
		key: Locale.CreateTemplated({
			key: "",
			args: { defaultValue: "...", nums: totalValues },
		}),
	};
};
const createNumsSlotData: CreateNumsSlotData = (slot, nums, { status, amount, win }) => {
	const ids = slot.ids.map(({ id }) => id).filter(isNumId);
	const numsByIds = nums.filter(({ id }) => ids.includes(id));
	const selectedNums = numsByIds.map(({ num }) => num);
	const combination = getNumsCombination(selectedNums.length);
	const { name: combinationName, key: combinationKey } = combination;

	return {
		id: Utils.getRandomId(),
		status,
		amount,
		paid: win,
		possible: amount * slot.multiply,
		slotId: slot.id,
		selected: selectedNums,
		selectedNums,
		combinationName,
		combinationKey,
		combinationMultiply: slot.multiply,
	};
};

type CreateRowsSlotData = (
	slot: Slot,
	nums: Num[],
	rows: Row[],
	columns: Column[],
	data: { status: TicketStatus; amount: number; win: number },
) => Ticket;
const getRowsCombination = (rows: Row[]): { key: Template; name: string } => {
	const totalRows = rows.length;
	if (totalRows === 1) {
		const name = "street";
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.street`,
				args: { defaultValue: name, rows: totalRows },
			}),
		};
	} else if (totalRows === 2) {
		const name = "six line";
		return {
			name,
			key: Locale.CreateTemplated({
				key: "combination.six line",
				args: { defaultValue: name, rows: totalRows },
			}),
		};
	}
	return { name: "", key: Locale.CreateTemplated({ key: "", args: { defaultValue: "...", rows: totalRows } }) };
};
const createRowsSlotData: CreateRowsSlotData = (slot, nums, rows, columns, { status, amount, win }) => {
	const ids = slot.ids.map(({ id }) => id).filter(isRowId);
	const rowsByIds = rows.filter(({ id }) => ids.includes(id));
	const numsByRows = rowsByIds.map(({ row }) => nums.filter((num) => num.row === row)).flat();
	const selectedNums = numsByRows.map(({ num }) => num);
	const isNonStandardRows = !!(numsByRows.length % columns.length);
	const combination = isNonStandardRows ? getNumsCombination(numsByRows.length) : getRowsCombination(rowsByIds);
	const { name: combinationName, key: combinationKey } = combination;
	return {
		id: Utils.getRandomId(),
		status,
		amount,
		paid: win,
		possible: amount * slot.multiply,
		slotId: slot.id,
		selected: selectedNums,
		selectedNums,
		combinationName,
		combinationKey,
		combinationMultiply: slot.multiply,
	};
};

type CreateColumnsSlotData = (
	slot: Slot,
	nums: Num[],
	columns: Column[],
	data: { status: TicketStatus; amount: number; win: number },
) => Ticket;
const getColumnsCombination = (columns: Column[]): { name: string; key: Template } => {
	const totalColumns = columns.length;
	if (totalColumns === 1) {
		const column = columns.at(0)!;
		const name = `${getOrdinalNumber(column.column)} column`;
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.column.${column.column}`,
				args: { defaultValue: name, columns: totalColumns },
			}),
		};
	}
	return {
		name: "",
		key: Locale.CreateTemplated({
			key: "",
			args: { defaultValue: "...", columns: totalColumns },
		}),
	};
};
const createColumnsSlotData: CreateColumnsSlotData = (slot, nums, columns, { status, amount, win }) => {
	const ids = slot.ids.map(({ id }) => id).filter(isColumnId);
	const columnsByIds = columns.filter(({ id }) => ids.includes(id));
	const numsByColumns = columnsByIds.map(({ column }) => nums.filter((num) => num.column === column)).flat();
	const numsValues = numsByColumns.map((num) => num.num);
	const selectedNums = [...numsValues];
	const combination = getColumnsCombination(columnsByIds);
	const { name: combinationName, key: combinationKey } = combination;
	return {
		id: Utils.getRandomId(),
		status,
		amount,
		paid: win,
		possible: amount * slot.multiply,
		slotId: slot.id,
		selected: selectedNums,
		selectedNums,
		combinationName,
		combinationKey,
		combinationMultiply: slot.multiply,
	};
};

type CreatePartsSlotData = (
	slot: Slot,
	nums: Num[],
	parts: Part[],
	data: { status: TicketStatus; amount: number; win: number },
) => Ticket;
const getPartsCombination = (parts: Part[]): { name: string; key: Template } => {
	const totalParts = parts.length;
	if (totalParts === 1) {
		const part = parts.at(0)!;
		const name = `${getOrdinalNumber(part.part)} dozen`;
		return {
			name,
			key: Locale.CreateTemplated({
				key: `combination.part.${part.part}`,
				args: { defaultValue: name, parts: totalParts, rows: ROWS },
			}),
		};
	}
	return {
		name: "",
		key: Locale.CreateTemplated({
			key: "",
			args: { defaultValue: "...", parts: totalParts, rows: ROWS },
		}),
	};
};
const createPartsSlotData: CreatePartsSlotData = (slot, nums, parts, { status, amount, win }) => {
	const ids = slot.ids.map(({ id }) => id).filter(isPartId);
	const partsByIds = parts.filter(({ id }) => ids.includes(id));
	const numsByParts = partsByIds.map(({ part }) => nums.filter((num) => num.part === part)).flat();
	const numsValues = numsByParts.map(({ num }) => num);
	const selectedNums = [...numsValues];
	const combination = getPartsCombination(partsByIds);
	const { name: combinationName, key: combinationKey } = combination;
	return {
		id: Utils.getRandomId(),
		status,
		amount,
		paid: win,
		possible: amount * slot.multiply,
		slotId: slot.id,
		selected: selectedNums,
		selectedNums,
		combinationName,
		combinationKey,
		combinationMultiply: slot.multiply,
	};
};

type CreateSpecialCellsSlotData = (
	slot: Slot,
	nums: Num[],
	data: { status: TicketStatus; amount: number; win: number },
) => Ticket;
const createSpecialCellsSlotData: CreateSpecialCellsSlotData = (slot, nums, { status, amount, win }) => {
	const ids = slot.ids.map(({ id }) => id);
	const numsByIds = ids
		.map((id) => {
			switch (id) {
				case CELLS.low:
					return nums.filter(({ isLow }) => isLow);
				case CELLS.high:
					return nums.filter(({ isHigh }) => isHigh);
				case CELLS.black:
					return nums.filter(({ isBlack }) => isBlack);
				case CELLS.red:
					return nums.filter(({ isRed }) => isRed);
				case CELLS.even:
					return nums.filter(({ isEven }) => isEven);
				case CELLS.odd:
					return nums.filter(({ isOdd }) => isOdd);
				default:
					return [];
			}
		})
		.flat();
	const numsValues = numsByIds.map(({ num }) => num);
	const selectedNums = [...numsValues];
	const getCombination = (slot: Slot) => {
		switch (slot.id) {
			case CELLS.low: {
				const name = "1 to 18";
				return {
					name,
					key: Locale.CreateTemplated({
						key: `combination.${CELLS.low}`,
						args: { defaultValue: name },
					}),
				};
			}
			case CELLS.high: {
				const name = "19 to 36";
				return {
					name,
					key: Locale.CreateTemplated({
						key: `combination.${CELLS.high}`,
						args: { defaultValue: name },
					}),
				};
			}
			default: {
				const name = slot.title;
				return {
					name,
					key: Locale.CreateTemplated({
						key: `combination.${slot.title}`,
						args: { defaultValue: name },
					}),
				};
			}
		}
	};

	const combination = getCombination(slot);
	const { name: combinationName, key: combinationKey } = combination;
	return {
		id: Utils.getRandomId(),
		status,
		amount,
		paid: win,
		possible: amount * slot.multiply,
		slotId: slot.id,
		selected: slot.id as SpecialCellId,
		selectedNums,
		combinationName,
		combinationKey,
		combinationMultiply: slot.multiply,
	};
};

export const initialSelectedState: SelectedState = {
	odd: false,
	even: false,
	low: false,
	high: false,
	red: false,
	black: false,
	rowsIds: [],
	columnsIds: [],
	partsIds: [],
	numIds: [],
	cells: [],
};
const initialBets = BETS.map((bet) => ({ title: bet.key, ...bet }));

export const $zeroes = createStore<Num[]>(createZeroes());
export const $columns = createStore(createColumns(COLUMNS, 1));
const $allRows = createStore(createRows(1 + ROWS, 0, [0]));
export const $rows = $allRows.map((rows) => rows.filter((row) => !row.extra));
export const $parts = $rows.map((rows) => createParts(rows, ROWS_IN_PART));
export const $nums = combine([$columns, $rows, $parts], createTableNums);
export const $selected = createStore<SelectedState>(initialSelectedState);
export const $bets = createStore<Bet[]>(initialBets);
export const $selectedBet = createStore<string>(initialBets.at(0)?.key || "");
export const $columnsCells = $columns.map(getColumnsCells);
export const $partsCells = $parts.map(getPartsCells);
export const $slots = combine([$nums, $zeroes, $columnsCells, $partsCells, $rows], createSlots);
export const $userBets = createStore<UserBet[]>([]);
export const $userBetsLog = createStore<UserBet[][]>([]);
export const $mousePosition = createStore<[number, number]>([0, 0]);
export const $totalUserBet = combine([$bets, $userBets], ([bets, userBets]) => {
	const betsValues = userBets.map((bet) => {
		return bets.find(({ key }) => key === bet.betKey)?.value ?? 0;
	});
	return betsValues.reduce((prev, value) => prev + value, 0);
});
export const $selectedSlots = combine([$slots, $userBets], ([slots, bets]) => {
	return bets.reduce<string[]>((prev, bet) => {
		const slot = slots.find((slot) => slot.id === bet.slotId);
		if (!slot) return prev;
		if (prev.includes(slot.id)) return prev;
		return [...prev, slot.id];
	}, []);
});
export const $rotation = createStore<{ isVertical: boolean }>({
	isVertical: JSON.parse(localStorage.getItem("table_rot") || "false"),
});
export const $user = createStore<{
	balance: number;
	betsEnabled: boolean;
	betsCancellationEnabled: boolean;
	betLimits: { min: number; max: number };
}>({
	balance: 0,
	betsEnabled: false,
	betsCancellationEnabled: false,
	betLimits: {
		min: MIN_BETS_VALUE_PET_SLOT,
		max: MAX_BETS_COUNT_PER_TABLE * MAX_BETS_VALUE_PER_SLOT,
	},
});
const $roundId = createStore(0);

export const oddSelected = createEvent();
export const evenSelected = createEvent();
export const lowSelected = createEvent();
export const highSelected = createEvent();
export const redSelected = createEvent();
export const blackSelected = createEvent();
export const numIdsSelected = createEvent<NumId[]>();
export const columnsIdsSelected = createEvent<ColumnId[]>();
export const rowsIdsSelected = createEvent<RowId[]>();
export const partsIdsSelected = createEvent<PartId[]>();
export const cellSelected = createEvent<SpecialCellId[]>();
export const unselected = createEvent();
export const betSelected = createEvent<string>();
export const userBetsCleared = createEvent();
export const userBetsAdded = createEvent<UserBet[]>();
export const userBetsMultiplied = createEvent<number | void>();
export const userBetsRemoved = createEvent<UserBet[]>();
export const userBetsSet = createEvent<UserBet[]>();
export const userBetsApplied = createEvent<void>();
export const selectedBetsAdded = createEvent<{ slotsIds: SlotId[]; collapse?: boolean }>();
export const selectedBetsSet = createEvent<{ slotsIds: SlotId[]; collapse?: boolean }>();
export const userLastBetInPositionRemoved = createEvent<{ slotId: SlotId }>();
export const userAllBetsInPositionRemoved = createEvent<{ slotId: SlotId }>();
export const mousePositionChanged = createEvent<[number, number]>();
export const userBetsUndo = createEvent();
export const rotationChanged = createEvent();
export const setRotate = createEvent<boolean>();
export const balanceChanged = createEvent<number>();
export const betsEnabledChanged = createEvent<boolean>();
export const betsLimitsChanged = createEvent<Partial<{ min: number; max: number }>>();
export const betsCancellationEnabledChanged = createEvent<boolean>();
export const roundIdChanged = createEvent<number>();

const userBetsUndoFx = attach({
	source: $userBetsLog,
	effect: (userBetsLog) => {
		const lastStep = userBetsLog.at(-2) ?? [];
		return {
			bets: [...lastStep],
			log: [...userBetsLog].slice(0, -1),
		};
	},
});

const validateBetsAmountFx = attach({
	source: { $bets, $user, $app },
	effect: ({ $bets: bets, $user: user, $app: app }, nextBets: UserBet[]) => {
		if (app.isCashdesk) return true;

		const totalBetsAmount = getUserBetsAmount(nextBets, bets);
		if (totalBetsAmount > user.balance) {
			throw new Error(
				Locale.Get("validation.error_balance_is_less", { maxValue: getTransformedAmount(user.balance) }),
			);
		}
	},
});
const validateTableMinBetsAmountFx = attach({
	source: { $bets, $user },
	effect: ({ $bets: bets, $user: user }, nextBets: UserBet[]) => {
		const { min: minUserBet } = user.betLimits;
		const minBetTableCheckResult = checkBetsMinTableAmountValue(nextBets, bets, minUserBet);
		if (!minBetTableCheckResult) {
			throw new Error(Locale.Get("validation.error_min_bet", { minValue: getTransformedAmount(minUserBet) }));
		}
	},
});
const validateTableMaxBetsAmountFx = attach({
	source: { $bets, $user },
	effect: ({ $bets: bets, $user: user }, nextBets: UserBet[]) => {
		const { min: minUserBet, max: maxUserBet } = user.betLimits;
		const maxBetTableCheckResult = checkBetsMaxTableAmountValue(nextBets, bets, maxUserBet);
		if (!maxBetTableCheckResult) {
			throw new Error(
				Locale.Get("validation.error_max_bet", {
					ns: "table",
					minValue: getTransformedAmount(minUserBet),
					maxValue: getTransformedAmount(maxUserBet),
				}),
			);
		}
	},
});
const validateSlotMaxBetsAmountFx = attach({
	source: { $bets, $user },
	effect: ({ $bets: bets, $user: user }, nextBets: UserBet[]) => {
		const { min: minUserBet } = user.betLimits;
		const maxBetSlotCheckResult = checkBetsMaxSlotAmountValue(nextBets, bets, MAX_BETS_VALUE_PER_SLOT);
		if (!maxBetSlotCheckResult) {
			throw new Error(
				Locale.Get("validation.error_max_bets_on_slot", {
					ns: "table",
					minValue: getTransformedAmount(minUserBet),
					maxValue: getTransformedAmount(MAX_BETS_VALUE_PER_SLOT),
				}),
			);
		}
	},
});
const validateTableMaxBetsCountFx = attach({
	source: {},
	effect: (_source, nextBets: UserBet[]) => {
		const maxCountCheckResult = checkBetsMaxCountPerTable(nextBets, MAX_BETS_COUNT_PER_TABLE);
		if (!maxCountCheckResult) {
			throw new Error(Locale.Get("validation.error_max_bets_on_table", { maxValue: MAX_BETS_COUNT_PER_TABLE }));
		}
	},
});
const checkBetsAddValidityFx = createEffect(async (nextBets: UserBet[]) => {
	await validateBetsAmountFx(nextBets);
	await validateTableMaxBetsAmountFx(nextBets);
	await validateSlotMaxBetsAmountFx(nextBets);
	await validateTableMaxBetsCountFx(nextBets);
});
const checkBetsSendValidityFx = createEffect(async (nextBets: UserBet[]) => {
	await validateBetsAmountFx(nextBets);
	await validateTableMinBetsAmountFx(nextBets);
	await validateTableMaxBetsAmountFx(nextBets);
	await validateSlotMaxBetsAmountFx(nextBets);
	await validateTableMaxBetsCountFx(nextBets);
});
export const addUserSelectedBetsFx = attach({
	source: { $selectedBet, $userBets, $bets },
	effect: async (
		{ $selectedBet: selectedBet, $userBets: userBets, $bets: bets },
		{ slotsIds, collapse = true }: { slotsIds: SlotId[]; collapse?: boolean },
	) => {
		const getNextBets = () => {
			const addedBets = slotsIds.map((slotId) => ({ slotId, betKey: selectedBet }));
			const allBets = [...userBets, ...addedBets];
			if (!collapse) return allBets;
			const filteredBets = allBets.filter((bet) => !slotsIds.includes(bet.slotId));
			const updatedSlots = allBets.filter((bet) => slotsIds.includes(bet.slotId));
			const collapsedBets = collapseSelectedBets(updatedSlots, bets);
			return [...filteredBets, ...collapsedBets];
		};
		const nextBets = getNextBets();
		await checkBetsAddValidityFx(nextBets);
		return nextBets;
	},
});
export const setUserSelectedBetsFx = attach({
	source: { $selectedBet, $userBets, $bets },
	effect: async (
		{ $selectedBet: selectedBet, $bets: bets },
		{ slotsIds, collapse = true }: { slotsIds: SlotId[]; collapse?: boolean },
	) => {
		const getNextBets = () => {
			const addedBets = slotsIds.map((slotId) => ({ slotId, betKey: selectedBet }));
			const allBets = [...addedBets];
			if (!collapse) return allBets;
			const filteredBets = allBets.filter((bet) => !slotsIds.includes(bet.slotId));
			const updatedSlots = allBets.filter((bet) => slotsIds.includes(bet.slotId));
			const collapsedBets = collapseSelectedBets(updatedSlots, bets);
			return [...filteredBets, ...collapsedBets];
		};
		const nextBets = getNextBets();
		await checkBetsAddValidityFx(nextBets);
		return nextBets;
	},
});
export const setUserBetsFx = createEffect(async (nextBets: UserBet[]) => {
	await checkBetsAddValidityFx(nextBets);
	return nextBets;
});
export const multiplyUserBetsFx = attach({
	source: { $userBets, $bets },
	effect: async ({ $userBets: userBets, $bets: bets }, multiply = 2) => {
		const nextBets = new Array(multiply).fill(userBets).flat();
		const collapsedBets = collapseSelectedBets(nextBets, bets);
		await checkBetsAddValidityFx(collapsedBets);
		return collapsedBets;
	},
});
export const sendBetsFx = attach({
	source: { $userBets, $bets, $slots, $nums, $zeroes, $allRows, $columns, $parts, $roundId },
	effect: async ({
		$userBets: userBets,
		$bets: bets,
		$slots: slots,
		$nums: nums,
		$zeroes: zeroes,
		$allRows: rows,
		$columns: columns,
		$parts: parts,
		$roundId: roundId,
	}) => {
		await checkBetsSendValidityFx(userBets);

		let data: HistoryItem["data"] = [];
		const localDate = new Date();
		const betsBySlots = userBets.reduce<{ [slotId: string]: { slotId: SlotId; userBets: UserBet[] } }>(
			(prev, bet) => {
				if (!prev[bet.slotId]) return { ...prev, [bet.slotId]: { slotId: bet.slotId, userBets: [bet] } };
				else
					return {
						...prev,
						[bet.slotId]: { ...prev[bet.slotId], userBets: [...prev[bet.slotId].userBets, bet] },
					};
			},
			{},
		);
		for (const { slotId, userBets } of Object.values(betsBySlots)) {
			const betValue = userBets.reduce(
				(prev, bet) => prev + (bets.find(({ key }) => key === bet.betKey)?.value ?? 0),
				0,
			);
			const slot = slots.find(({ id }) => id === slotId);
			if (!slot) {
				//console.warn(`Slot with ID ${slotId} undefined`);
				continue;
			}

			const allNums = [...zeroes, ...nums];
			if (isNumsId(slotId)) {
				const slotData = createNumsSlotData(slot, allNums, {
					status: TICKET_STATUS.open,
					amount: betValue,
					win: 0,
				});
				data = [...data, slotData];
			} else if (isRowsId(slotId)) {
				const slotData = createRowsSlotData(slot, allNums, rows, columns, {
					status: TICKET_STATUS.open,
					amount: betValue,
					win: 0,
				});
				data = [...data, slotData];
			} else if (isColumnsId(slotId)) {
				const slotData = createColumnsSlotData(slot, allNums, columns, {
					status: TICKET_STATUS.open,
					amount: betValue,
					win: 0,
				});
				data = [...data, slotData];
			} else if (isPartsId(slotId)) {
				const slotData = createPartsSlotData(slot, allNums, parts, {
					status: TICKET_STATUS.open,
					amount: betValue,
					win: 0,
				});
				data = [...data, slotData];
			} else {
				const slotData = createSpecialCellsSlotData(slot, allNums, {
					status: TICKET_STATUS.open,
					amount: betValue,
					win: 0,
				});
				data = [...data, slotData];
			}
		}
		const subBets: SubBet[] = data.map((data) => ({
			amount: data.amount,
			slotId: data.slotId,
			selectedNums: data.selected,
		}));
		const { betId, pin } = await Socket.createBet({ subBets });
		const item: HistoryItem = { id: betId, roundId, date: localDate, pin, userBets, data, resultBall: null };
		return item;
	},
});
export const isBetsSending = sendBetsFx.pending;
export const loadHistoryFX = attach({
	source: { $bets, $slots, $nums, $zeroes, $allRows, $columns, $parts },
	effect: async (
		{ $bets: bets, $slots: slots, $nums: nums, $zeroes: zeroes, $allRows: rows, $columns: columns, $parts: parts },
		data: WS_RESPONSE_AUTH_DATA["bet_history"],
	) => {
		const getBetsFromAmount = (amount: number, bets: Bet[]) => {
			const sortedBets = [...bets].sort((a, b) => b.value - a.value);
			let restAmount = amount;
			let betsList: Bet[] = [];
			for (const bet of sortedBets) {
				const count = Math.trunc(restAmount / bet.value);
				betsList = [...betsList, ...new Array(count).fill(bet)];
				restAmount = restAmount - count * bet.value;
				if (restAmount === 0) return betsList;
			}
			return betsList;
		};

		const allNums = [...zeroes, ...nums];
		let items: HistoryItem[] = [];
		for (const bet of data) {
			let userBets: UserBet[] = [];
			let data: Ticket[] = [];

			for (const subBet of bet.userBets) {
				const betsList = getBetsFromAmount(subBet.amount, bets);
				const userBetsFromList = betsList.map((item) => ({ slotId: subBet.slotId, betKey: item.key }));
				userBets = [...userBets, ...userBetsFromList];

				const slot = slots.find((slot) => slot.id === subBet.slotId)!;
				if (!slot) {
					//console.warn(`Slot with ID ${subBet.slotId} undefined`);
					continue;
				}

				if (isNumsId(subBet.slotId)) {
					const slotData = createNumsSlotData(slot, allNums, {
						status: subBet.status,
						amount: subBet.amount,
						win: subBet.win,
					});
					data = [...data, slotData];
				} else if (isRowsId(subBet.slotId)) {
					const slotData = createRowsSlotData(slot, allNums, rows, columns, {
						status: subBet.status,
						amount: subBet.amount,
						win: subBet.win,
					});
					data = [...data, slotData];
				} else if (isColumnsId(subBet.slotId)) {
					const slotData = createColumnsSlotData(slot, allNums, columns, {
						status: subBet.status,
						amount: subBet.amount,
						win: subBet.win,
					});
					data = [...data, slotData];
				} else if (isPartsId(subBet.slotId)) {
					const slotData = createPartsSlotData(slot, allNums, parts, {
						status: subBet.status,
						amount: subBet.amount,
						win: subBet.win,
					});
					data = [...data, slotData];
				} else {
					const slotData = createSpecialCellsSlotData(slot, allNums, {
						status: subBet.status,
						amount: subBet.amount,
						win: subBet.win,
					});
					data = [...data, slotData];
				}
			}

			const resultBall = allNums.find(({ num }) => num === bet.ball) ?? null;

			items = [
				...items,
				{
					id: bet.id,
					roundId: bet.roundId,
					date: new Date(bet.date),
					pin: bet.pin,
					userBets,
					data,
					resultBall,
				},
			];
		}
		return items;
	},
});

sample({
	clock: oddSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, odd: true }),
	target: $selected,
});
sample({
	clock: evenSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, even: true }),
	target: $selected,
});
sample({
	clock: lowSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, low: true }),
	target: $selected,
});
sample({
	clock: highSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, high: true }),
	target: $selected,
});
sample({
	clock: redSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, red: true }),
	target: $selected,
});
sample({
	clock: blackSelected,
	source: $selected,
	fn: (selected) => ({ ...selected, black: true }),
	target: $selected,
});
sample({
	clock: cellSelected,
	source: $selected,
	fn: (selected, cells) => ({ ...selected, cells }),
	target: $selected,
});
sample({
	clock: numIdsSelected,
	source: $selected,
	fn: (selected, ids) => ({ ...selected, numIds: ids }),
	target: $selected,
});
sample({
	clock: columnsIdsSelected,
	source: $selected,
	fn: (selected, columnsIds) => ({ ...selected, columnsIds }),
	target: $selected,
});
sample({
	clock: rowsIdsSelected,
	source: $selected,
	fn: (selected, rowsIds) => ({ ...selected, rowsIds }),
	target: $selected,
});
sample({
	clock: partsIdsSelected,
	source: $selected,
	fn: (selected, partsIds) => ({ ...selected, partsIds }),
	target: $selected,
});
sample({
	clock: unselected,
	source: $selected,
	fn: () => initialSelectedState,
	target: $selected,
});
sample({
	clock: betSelected,
	target: $selectedBet,
});
sample({
	clock: userBetsAdded,
	source: $userBets,
	fn: (userBets, bets) => [...userBets, ...bets],
	target: $userBets,
});
sample({
	clock: userBetsMultiplied,
	target: multiplyUserBetsFx,
});
sample({
	clock: multiplyUserBetsFx.doneData,
	target: $userBets,
});
sample({
	clock: userBetsRemoved,
	source: $userBets,
	fn: (userBets, bets) => {
		let nextBets = [...userBets].reverse();
		for (const removedBet of bets) {
			const last = userBets.find((b) => b.betKey === removedBet.betKey && b.slotId === removedBet.slotId);
			nextBets = nextBets.filter((bet) => bet !== last);
		}
		return nextBets.reverse();
	},
	target: $userBets,
});
sample({
	clock: selectedBetsAdded,
	target: addUserSelectedBetsFx,
});
sample({
	clock: selectedBetsSet,
	target: setUserSelectedBetsFx,
});
sample({
	clock: userBetsSet,
	target: setUserBetsFx,
});
sample({
	clock: [addUserSelectedBetsFx.doneData, setUserSelectedBetsFx.doneData, setUserBetsFx.doneData],
	target: $userBets,
});
sample({
	clock: userLastBetInPositionRemoved,
	source: $userBets,
	fn: (userBets, { slotId }) => {
		const nextBets = [...userBets].reverse();
		const lastBet = nextBets.find((b) => b.slotId === slotId);
		if (!lastBet) return userBets;
		const filteredBets = nextBets.filter((bet) => bet !== lastBet);
		return [...filteredBets].reverse();
	},
	target: $userBets,
});
sample({
	clock: userAllBetsInPositionRemoved,
	source: $userBets,
	fn: (userBets, { slotId }) => {
		return userBets.filter((bet) => bet.slotId !== slotId);
	},
	target: $userBets,
});
sample({
	clock: mousePositionChanged,
	source: $mousePosition,
	fn: (_, newPosition) => newPosition,
	target: $mousePosition,
});
sample({
	clock: $userBets,
	source: $userBetsLog,
	fn: (state, bets) => [...state, bets],
	target: $userBetsLog,
});
sample({
	clock: userBetsUndo,
	target: userBetsUndoFx,
});
sample({
	clock: userBetsUndoFx.doneData,
	fn: ({ bets }) => bets,
	target: $userBets,
});
sample({
	clock: userBetsUndoFx.doneData,
	fn: ({ log }) => log,
	target: $userBetsLog,
});
sample({
	clock: userBetsApplied,
	target: sendBetsFx,
});
sample({
	clock: sendBetsFx.doneData,
	target: [addToHistory, userBetsCleared],
});
sample({
	clock: rotationChanged,
	source: $rotation,
	fn: (rotation) => {
		// TODO: ADD SET METHOD ?
		const isRotate = !rotation.isVertical;
		localStorage.setItem("table_rot", JSON.stringify(isRotate));
		return { ...rotation, isVertical: isRotate };
	},
	target: $rotation,
});
sample({
	clock: setRotate,
	source: $rotation,
	fn: (rotation, isRotate) => {
		localStorage.setItem("table_rot", JSON.stringify(isRotate));
		return { ...rotation, isVertical: isRotate };
	},
	target: $rotation,
});
sample({
	clock: balanceChanged,
	source: $user,
	fn: (state, balance) => ({ ...state, balance }),
	target: $user,
});
sample({
	clock: betsEnabledChanged,
	source: $user,
	fn: (state, betsEnabled) => ({ ...state, betsEnabled }),
	target: $user,
});
sample({
	clock: betsLimitsChanged,
	source: $user,
	fn: (state, betsLimits) => ({ ...state, betLimits: { ...state.betLimits, ...betsLimits } }),
	target: $user,
});
sample({
	clock: betsCancellationEnabledChanged,
	source: $user,
	fn: (state, betsCancellationEnabled) => ({ ...state, betsCancellationEnabled }),
	target: $user,
});
sample({
	clock: addToHistory,
	source: $betsHistory,
	fn: (state, bet) => [...state, bet],
	target: $betsHistory,
});
sample({
	clock: roundIdChanged,
	target: $roundId,
});
sample({
	clock: updateHistoryFx.doneData,
	target: $betsHistory,
});
sample({
	clock: loadHistoryFX.doneData,
	target: $betsHistory,
});

$userBets.reset(userBetsCleared);

if (process.env.NODE_ENV?.trim() === "development") {
	const logged: { [name: string]: EffectorStore<any> | EffectorEvent<any> | EffectorEffect<any, any> } = {};
	Object.entries(logged).forEach(([name, entity]) => {
		const entityColor = (kind: (typeof entity)["kind"]) => {
			switch (kind) {
				case "store":
					return "#8050f3";
				case "event":
					return "#0f8808";
				case "effect":
					return "#e31f55";
				default:
					return "#373636";
			}
		};
		entity.watch((state) =>
			console.log(
				"%c%s",
				`padding: 2px 4px; border-radius: 4px; background:${entityColor(entity.kind)}; color:white; font-weight: bold;`,
				name,
				state,
			),
		);
	});
}
