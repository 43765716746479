import { getTransformedAmount } from "../../shared/lib/getTransformedAmount";

export const getAmount = (value: number) => getTransformedAmount(value).toFixed(2);
export const getFormatDate = (date: Date) => {
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();

	const time = date.toLocaleTimeString([], { hour: "numeric", minute: "numeric", hour12: true });

	return `${month + 1}/${day}/${year} ${time}`;
};
