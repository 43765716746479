import { createEvent } from "effector";
import {
	addResults,
	resetTimer,
	setBalance,
	setCurrency,
	setIsCashdesk,
	setResults,
	setRoundDelay,
	setSocketIsReady
} from "../store/app";
import { $store } from "../store";
import { type GAME_STATE, setNextNumber, setRoundNumber, setRoundState, setTimings } from "../store/game";
import { Print } from "./print";
import Utils from "./utils";
import { type SlotId, type SpecialCellId, TicketStatus } from "../features/table/types";
import { setLastNumbers } from "../store/stats";
import { type Values } from "../shared/types";
import Ticket from "./ticket";

export const UPDATE_ACTION = {
	start: "start",
	lastbet: "lastbets",
	nobets: "nobets",
	finish: "finish",
	betResultReceived: "betresult",
} as const;
export const REQ_ACTION = {
	debug: "debug",
	auth: "auth",
	bet: "bet",
	cancelBet: "cancel",
} as const;

export const authed = createEvent<WS_RESPONSE_AUTH_DATA>();
export const betted = createEvent<WS_RESPONSE_BET_DATA>();
export const started = createEvent<WS_ACTION_START>();
export const lastbet = createEvent<void>();
export const nobetsed = createEvent<void>();
export const finished = createEvent<void>();
export const betResultReceived = createEvent<WS_ACTION_BETRESULT>();

export default class Socket {
	// =====================================================================
	private static connection: WebSocket;

	public static startService() {
		Socket.connection = new WebSocket(process.env.REACT_APP_URL_WSS!);
		Socket.connection.onopen = () => {
			setSocketIsReady(true);
			Socket.auth();
		};
		Socket.connection.onmessage = Socket.onMessage;
		Socket.connection.onclose = (event) => setSocketIsReady(false);
		Socket.connection.onerror = (error: any) => {
			console.error(`Socket err: ${error?.message}`);
			setSocketIsReady(false);
		};
	}

	public static disconnect() {
		Socket.connection.close();
	}

	public static reconnect() {
		Socket.startService();
	}

	public static auth() {
		Socket.connection.send(
			JSON.stringify({
				action: `${$store.getState().app.authToken === "debug" ? "debug" : "auth"}`,
				token: $store.getState().app.authToken,
			}),
		);
	}

	public static async createBet({ subBets }: { subBets: SubBet[] }) {
		const action = REQ_ACTION.bet;
		const data: WS_REQUEST_BET = { action, data: { subBets } };
		const response = await SocketPromise.request<WS_RESPONSE_BET>(
			data,
			(data) => {
				Socket.connection.send(JSON.stringify(data));
			},
			(marker) => `${action}:${marker}`,
		);
		return response.data;
	}

	public static async cancelBet({ betId }: { betId: number }) {
		const action = REQ_ACTION.cancelBet;
		const data: WS_REQUEST_CANCEL_BET = { action, data: betId };
		const response = await SocketPromise.request<WS_RESPONSE_CANCEL_BET>(
			data,
			(data) => {
				Socket.connection.send(JSON.stringify(data));
			},
			(marker) => `${action}:${marker}`,
		);
		return response.data;
	}

	public static onMessage = (event: { data: any }) => {
		const message = JSON.parse(event.data);
		if (message?.response) {
			// ОТВЕТЫ НА ЗАПРОСЫ -----------------------------------
			if (message.marker) {
				if (SocketPromise.list.has(message.marker)) SocketPromise.response(message);
			}

			if (message?.result) {
				const response = message as WS_TYPE_RESPONSE;
				if (response.data?.balance) setBalance(response.data.balance);
				switch (response.response) {
					case REQ_ACTION.auth: {
						const data = response.data;
						setBalance(data.balance);
						setCurrency(data.currency || "DMO");
						setRoundState(data.round_state_name);
						setRoundNumber(data.round_id);
						setRoundDelay(0);
						setIsCashdesk(data.isCashdesk);
						resetTimer();
						setLastNumbers(data.ball_last);
						setTimings({
							round_interval: data.round_interval,
							time_to_finish: data.time_to_finish,
							time_to_lastbets: data.time_to_lastbets,
							time_to_nobets: data.time_to_nobets,
						});
						authed(data);
						break;
					}
					case REQ_ACTION.bet: {
						const { data } = response;
						betted(data);
						$store.getState().app.isCashdesk && setTimeout(() => Ticket.Bet(data.betId), 100);
						break;
					}
					default:
						Print.warn("WSS", "Unhandled resp message:", message);
				}
			} else {
				Print.err("WSS", (message as WS_RESPONSE_ERROR)?.error || "???", message);
			}
		} else if (message?.action) {
			// СООБЩЕНИЯ СЕРВЕРА ------------------------------
			const actionMessage = message as WS_TYPE_ACTION;

			if (actionMessage.data?.round) setRoundNumber(actionMessage.data.round);
			if (actionMessage.data?.balance) setBalance(actionMessage.data.balance);

			switch (actionMessage.action) {
				case UPDATE_ACTION.start: {
					const { action, data } = actionMessage;
					setRoundState(action);
					setRoundDelay($store.getState().game.timing.time_to_nobets);
					// setResults([]);
					resetTimer();
					setLastNumbers(data.ball_last);
					started(data);
					break;
				}
				case UPDATE_ACTION.lastbet: {
					const { action, data } = actionMessage;
					setRoundState(action);
					if ($store.getState().app.roundDelay === 0) {
						setRoundDelay(
							$store.getState().game.timing.time_to_nobets -
								$store.getState().game.timing.time_to_lastbets,
						);
					}
					setLastNumbers(data.ball_last);
					lastbet();
					break;
				}
				case UPDATE_ACTION.nobets: {
					const { action, data } = actionMessage;
					setRoundState(action);
					setRoundDelay(
						$store.getState().game.timing.round_interval - $store.getState().game.timing.time_to_nobets,
					);
					resetTimer();
					setNextNumber(data?.ball);
					setLastNumbers(data.ball_last);
					nobetsed();
					break;
				}
				case UPDATE_ACTION.finish: {
					const { action, data } = actionMessage;
					setRoundState(action);
					if ($store.getState().app.roundDelay === 0) {
						setRoundDelay(
							$store.getState().game.timing.round_interval - $store.getState().game.timing.time_to_finish,
						);
						resetTimer();
					}
					setLastNumbers(data.ball_last);
					finished();
					break;
				}
				case UPDATE_ACTION.betResultReceived: {
					const { data } = actionMessage;
					betResultReceived(data);
					addResults(data);
					console.log("RESULTS", data)
					break;
				}

				case "debug": {
					// ...
					break;
				}
				default: {
					const { action } = actionMessage;
					Print.warn("WSS", `Unhandled action[${action}] message:`, message);
				}
			}
		} else {
			// НЕИЗВЕСТНЫЕ ДАННЫЕ -------------------------------------------------
			// ...
		}
	};

	public static test: <Data, TransformedData>(data: Data, transformer: (data: Data) => TransformedData) => void = (
		data,
		transformer,
	) => {
		this.onMessage(transformer(data) as any);
	};
	public static testPromise: <Data, TransformedData>(
		data: Data,
		transformer: (data: Data) => TransformedData,
		delay?: number,
	) => Promise<TransformedData> = (data, transformer, delay = 1000) => {
		const transformedData = transformer(data);
		return SocketPromise.request(transformedData, (request) => {
			setTimeout(() => {
				this.onMessage({ data: JSON.stringify(request) });
			}, delay);
		});
	};
} // =================================================================================================
const createSocketPromise = () => {
	type MarkedData<Data> = { marker: string } & Data;
	type ResolvePromise<T> = (value: T) => void;
	type RejectPromise = (reason?: unknown) => void;

	const list = new Map<string, { promise: Promise<unknown>; resolve: ResolvePromise<any>; reject: RejectPromise }>();
	const request = <T, Data = object>(
		data: Data,
		callback: (data: MarkedData<Data>) => void,
		mark: (id: string) => string = (m) => m,
	) => {
		const marker = mark(Utils.getRandomId());
		const requestData = { marker, ...data };
		let resolvePromise: ResolvePromise<T> = () => {};
		let rejectPromise: RejectPromise = () => {};
		const promise = new Promise<T>((resolve, reject) => {
			resolvePromise = resolve;
			rejectPromise = reject;
		});
		list.set(marker, { promise, resolve: resolvePromise, reject: rejectPromise });
		callback(requestData);
		return promise;
	};
	const response = (responseData: MarkedData<WS_TYPE_RESPONSE | WS_RESPONSE_ERROR>) => {
		const { marker, ...data } = responseData;
		if (responseData.error) {
			list.get(marker)?.reject({ message: responseData.error, details: data });
		} else {
			list.get(marker)?.resolve(data);
		}
		list.delete(responseData.marker);
	};

	return { list, request, response };
};
const SocketPromise = createSocketPromise();

export type UPDATE_NAME = `${Values<typeof UPDATE_ACTION>}`;
export type REQUEST_NAME = `${Values<typeof REQ_ACTION>}`;

type WS_REQUEST_NAME = REQUEST_NAME;
type WS_RESPONSE_NAME = "debug" | REQUEST_NAME;
type WS_ACTION_NAME = "debug" | UPDATE_NAME;

export type ROUND_TIMING = {
	round_interval: number;
	time_to_finish: number;
	time_to_lastbets: number;
	time_to_nobets: number;
};
// Request =========================
type NamedRequest<Name extends WS_REQUEST_NAME, Data> = { action: Name; data: Data };
type WS_REQUEST_BET = NamedRequest<"bet", { subBets: SubBet[] }>;
type SubBet = {
	amount: number;
	slotId: SlotId;
	selectedNums: SpecialCellId | number[];
};
type WS_REQUEST_CANCEL_BET = NamedRequest<"cancel", number>;
// --
// Response ========================
type WS_TYPE_RESPONSE = WS_RESPONSE_AUTH | WS_RESPONSE_BET | WS_RESPONSE_CANCEL_BET;
type NamedResponse<Name extends WS_RESPONSE_NAME, Data> = {
	response: Name;
	result: boolean;
	data: Data;
	error?: string;
};
type WS_RESPONSE_AUTH = NamedResponse<"auth", WS_RESPONSE_AUTH_DATA>;
type WS_RESPONSE_BET = NamedResponse<"bet", WS_RESPONSE_BET_DATA>;
type WS_RESPONSE_CANCEL_BET = NamedResponse<"cancel", WS_RESPONSE_CANCEL_BET_DATA>;
// --
type WS_RESPONSE_ERROR = {
	error: string;
};

export type WS_RESPONSE_AUTH_DATA = {
	balance: number;
	currency: string;
	delay: number;
	isCashdesk: boolean;
	maxBet: number;
	minBet: number;
	round_id: number;
	round_state: number;
	round_state_name: GAME_STATE;

	round_interval: number;
	time_to_finish: number;
	time_to_lastbets: number;
	time_to_nobets: number;

	ball_hyst: { num: number; stat: number }[];
	ball_last: number[];

	bet_history: {
		id: number;
		roundId: number;
		/** UTC ms */
		date: number;
		pin: number;
		ball: number;
		userBets: {
			amount: number;
			status: TicketStatus;
			win: number;
			slotId: SlotId;
		}[];
	}[];
};
export type WS_RESPONSE_BET_DATA = {
	betId: number;
	roundId: number;
	pin: number;
	balance: number;
};
type WS_RESPONSE_CANCEL_BET_DATA = {
	betId: number;
	roundeId: number;
	balance: number;
};

// Action ==========================
type WS_TYPE_ACTION =
	| NamedAction<"debug", Partial<WS_ACTION_BASE>>
	| NamedAction<"start", WS_ACTION_START>
	| NamedAction<"lastbets", WS_ACTION_LASTBETS>
	| NamedAction<"nobets", WS_ACTION_NOBETS>
	| NamedAction<"finish", WS_ACTION_FINISH>
	| NamedAction<"betresult", WS_ACTION_BETRESULT>;
type NamedAction<Name extends WS_ACTION_NAME, Data> = { action: Name; data: Data };
// --
type WS_ACTION_BASE = {
	delay: number; // Time to next game action
	round: number;
	balance?: number;
	ball_hyst: { num: number; stat: number }[];
	ball_last: number[];
};

interface WS_ACTION_START extends WS_ACTION_BASE {
	// ...
}

interface WS_ACTION_LASTBETS extends WS_ACTION_BASE {
	// ...
}

interface WS_ACTION_NOBETS extends WS_ACTION_BASE {
	ball: number;
}

interface WS_ACTION_FINISH extends WS_ACTION_BASE {
	// ...
}

export interface WS_ACTION_BETRESULT extends WS_ACTION_BASE {
	betId: number;
	roundId: number;
	allWin: number;
	ball: number;
	parts: Array<{
		amount: number;
		nums: number[];
		slotId: string;
		win: number;
	}>;
	round: never;
	delay: never;
}
