import style from "./style.module.scss";
import {useStore} from "effector-react";
import {$store} from "../../../store";
import {Bool} from "../primitives/bool/Bool";
import Socket from "../../../core/socket";

export const SocketControls = ()=>{
    const store = useStore($store);
    return <div className={`${style.socketControls}`}>
        <div className={style.btn} onClick={store.app.socketIsReady ? Socket.disconnect : Socket.reconnect}>
            <p>{store.app.socketIsReady ? "Disconnect" : "Reconnect"}</p>
        </div>
        <div className={style.group}>
            <p>{`WSS`}</p>
            <Bool bool={store.app.socketIsReady}/>
        </div>
    </div>
}