import { PHYS_VARIANT } from "../../../core/constants";
import Easing from "../../../core/easing";

const SENSITIVE_multiply = 10;
export const SENSITIVE_totalPath = SENSITIVE_multiply * 360;

export const getSpeed = ({
	deltaTime,
	prevProgressCoefficient,
}: {
	deltaTime: number;
	prevProgressCoefficient: number;
	prevSpeedCoefficient: number;
}) => {
	const progressCoefficient = Math.min(1, prevProgressCoefficient + 0.1 * deltaTime);
	const curSpeedCoefficient = Easing.easeOutSine(1 - progressCoefficient);
	return { progressCoefficient, curSpeedCoefficient };
};
export const getRadius = ({
	isBallShaking,
	progressCoefficient,
	curSpeedCoefficient,
	radiusEasing,
}: {
	isBallShaking: boolean;
	progressCoefficient: number;
	curSpeedCoefficient: number;
	radiusEasing?: PHYS_VARIANT["radiusEasing"];
}) => {
	let curBallRadCoefficient: number;
	const defaultEasing = (x: number) => Easing.easeInOutBounce(Easing.easeOutExpo(x));
	curBallRadCoefficient = radiusEasing?.easing(1 - progressCoefficient) ?? defaultEasing(1 - progressCoefficient);
	curBallRadCoefficient = Math.max(0, curBallRadCoefficient);

	// Ball jump ----------------------------------------------------------------
	let randJumpShift: number;
	if (isBallShaking && curBallRadCoefficient <= 0.5) {
		randJumpShift = 50 * curSpeedCoefficient;
	} else {
		randJumpShift = 0;
	}

	return { curBallRadCoefficient, randJumpShift };
};
export const getRotation = ({
	progressCoefficient,
	expectedSectorAngle,
	angleEasing,
}: {
	progressCoefficient: number;
	expectedSectorAngle: number;
	angleEasing?: PHYS_VARIANT["angleEasing"];
}) => {
	type OffsetAngle = { fullOffsetAngle: number; curOffsetAngle: number };
	type CreateOffset = (data: {
		progress: number;
		trigger?: number;
		offsetAngle?: number;
		easing?: Easing;
	}) => OffsetAngle;
	type Easing = (x: number) => number;

	const easing = Easing.easeOutSine;
	const expectedAngle = SENSITIVE_totalPath + expectedSectorAngle;

	let offset: OffsetAngle = { fullOffsetAngle: 0, curOffsetAngle: 0 };

	// Reverse ------------------------------------------------------------------
	const createOffset: CreateOffset = ({ progress, trigger = 0, offsetAngle = 0, easing = (x: number) => x }) => {
		let fullOffsetAngle = 0;
		let curOffsetAngle = 0;

		const ver_2 = () => {
			fullOffsetAngle = 2 * offsetAngle;
			if (trigger === 0 || progress < 1 - trigger) {
				const max = 1 - trigger;
				const offsetProgress = Math.max(0, (max - progress) / max);
				curOffsetAngle = -1 * fullOffsetAngle * offsetProgress;
			} else {
				const min = 1 - trigger;
				const offsetProgress = Math.max(0, (progress - min) / (1 - min));
				curOffsetAngle = fullOffsetAngle * easing(offsetProgress);
			}
		};

		ver_2();

		return { fullOffsetAngle, curOffsetAngle };
	};
	offset = createOffset({
		progress: progressCoefficient,
		trigger: angleEasing?.trigger,
		offsetAngle: angleEasing?.offsetAngle,
		easing: angleEasing?.easing,
	});

	const newAngle = (expectedAngle + offset.fullOffsetAngle - offset.curOffsetAngle) * easing(progressCoefficient);
	return {
		curBallAngle: newAngle,
		offset: offset.fullOffsetAngle - offset.curOffsetAngle,
		normalizedOffset: (offset.fullOffsetAngle - offset.curOffsetAngle) * easing(progressCoefficient),
		expectedAngle: expectedSectorAngle * easing(progressCoefficient),
	};
};
