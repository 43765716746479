import { type ButtonHTMLAttributes, forwardRef } from "react";
import style from "./style.module.scss";
import { cn } from "../../lib/classNames";
import { createVariants, type PropsVariants } from "../../lib/classVariants";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & Variants;

const buttonVariants = createVariants(style.button, {
	variants: {
		variant: {
			text: style.text,
			contained: style.contained,
			outline: style.outline,
		},
		colored: {
			basic: style.basic,
			payIn: style.payIn,
			danger: style.danger,
		},
		size: {
			md: style.md,
			lg: style.lg,
		},
	},
	defaultVariants: {
		variant: "contained",
		colored: "basic",
		size: "md",
	},
});
type Variants = PropsVariants<typeof buttonVariants>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ variant, colored, size, className, ...props }, ref) => (
		<button ref={ref} className={cn(buttonVariants({ variant, colored, size }), className)} {...props} />
	),
);
