import { createStore, createEvent } from "effector";
import Constants, { NUMBER } from "../core/constants";
import {types} from "sass";
import Number = types.Number;

export type Table = {
	allowMouseReaction: boolean,
	selectedGroup: string,
	selectedNumbers: NUMBER[],
}

const mock = {
	allowMouseReaction: false,
	selectedGroup: "-",
	selectedNumbers: [],
}

export const setAllowMouseReaction = createEvent<boolean>();
export const clearHighlight = createEvent();
export const setSelectedGroup = createEvent<string>();
export const setSelectedNumber = createEvent<NUMBER>();
export const selectNumber = createEvent<number>();
export const selectNumbers = createEvent<number[]>();
export const clear = createEvent();

export const $table = createStore<Table>(mock)
	.on(setAllowMouseReaction, (state, allowMouseReaction:boolean) => {
		return {...state, allowMouseReaction};
	})
	.on(setSelectedGroup, (state, selectedGroup:string) => {
		return {...state, selectedGroup, selectedNumber: {} as NUMBER};
	})
	.on(setSelectedNumber, (state, selectedNumber:NUMBER) => {
		return {...state, selectedGroup:"-", selectedNumber};
	})
	.on(clearHighlight, (state) => {
		return {...state, selectedGroup:"-", selectedNumber: {} as NUMBER};
	})
	.on(selectNumber, (state, num:number) => {
		const number: NUMBER|undefined = Constants.NUMBERS.find((el:NUMBER)=>el.num==num);
		return {...state, selectedGroup:"-", selectedNumbers: (number ? [number]:[])};
	})
	.on(selectNumbers, (state, nums:number[]) => {
		let numbers: NUMBER[] = [];
		for (const num of nums ) {
			const number: NUMBER|undefined = {...Constants.NUMBERS.find((el:NUMBER)=>el.num==num)!};
			if(number) {
				number.flags = "-";
				number && numbers.push(number);
			}
		}

		return {...state, selectedGroup:"-", selectedNumbers: numbers||[]};
	})
	.reset(clear);
