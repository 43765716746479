import style from "./colors.module.scss";
import { createVariants } from "../../shared/lib/classVariants";
import { NOTIFICATION_TYPE } from "./config";

export const notificationColor = createVariants(style.notification, {
	variants: {
		status: {
			default: "",
			[NOTIFICATION_TYPE.error]: style.error,
			[NOTIFICATION_TYPE.warning]: style.warning,
			[NOTIFICATION_TYPE.attention]: style.attention,
			[NOTIFICATION_TYPE.success]: style.success,
		},
		background: { true: style.background, false: "" },
		color: { true: style.color, false: "" },
	},
	defaultVariants: {
		status: "default",
		background: "false",
		color: "false",
	},
});
