import { type HTMLAttributes } from "react";
import style from "./style.module.scss";
import { getFormatDate } from "../../helpers";
import { useTranslation } from "../../../../shared/hooks";

export const ShortInfo = ({ id, roundId, date }: { id: number; roundId: number; date: Date }) => {
	const { t } = useTranslation();

	return (
		<div className={style.shortInfo}>
			<span>{`${t("short_info.round")} ${roundId}`}</span>
			<Separator className={style.separator} />
			<span>{`${t("short_info.ticket_code")} ${id}`}</span>
			<Separator className={style.separator} />
			<span>{getFormatDate(date)}</span>
		</div>
	);
};

export const Separator = (props: HTMLAttributes<HTMLSpanElement>) => <span {...props}>{"|"}</span>;
