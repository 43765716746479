import style from "./style.module.scss";
import { useTranslation } from "../../../../shared/hooks";

export const TotalsInfo = ({ payment, payout }: { payment: string; payout: string }) => {
	const { t } = useTranslation();

	return (
		<div className={style.totalsInfo}>
			<div className={style.payment}>
				<span>{t("totals.payment")}</span>
				<span>{payment}</span>
			</div>
			<div className={style.payout}>
				<span>{t("totals.payout")}</span>
				<span>{payout}</span>
			</div>
		</div>
	);
};
