import style from "./style.module.scss";
import wheel_numbers from "./../../../img/wheel/numbers.png";
import wheel_desk from "./../../../img/wheel/desk.png";
import wheel_cover from "./../../../img/wheel/cover.png";
import ballSVG from "./../../../img/wheel/ball.svg";
import { IntRange } from "../../../global";
import React, { Fragment, useLayoutEffect } from "react";
import { useUnit } from "effector-react";
import { $app } from "../../../store/app";
import {
	$game,
	GAME_ACTION,
	GAME_STATE,
	setCurBallAngle,
	setCurBallRadCoefficient,
	setCurSpeedCoefficient,
	setCurWheelAngle,
	setIsSpin,
	setPhysVariant,
	setProgressCoefficient,
	setRandJumpShift,
} from "../../../store/game";
import Constants from "../../../core/constants";
import { WheelControls } from "../../debug/wheelControls/WheelControls";
import { RoundResult } from "../../roundResult/RoundResult";
import Time from "../../../core/time";
import Utils from "../../../core/utils";
import { clearHighlight } from "../../../store/table";
import { createAnimation, type FrameData } from "../../../shared/lib/createAnimation";
import { getRadius, getRotation, getSpeed } from "./rotation";

export const GameWheel = () => {
	const app = useUnit($app);
	const game = useUnit($game);

	useLayoutEffect(() => {
		animation.start();
		setCurWheelAngle(Utils.RandomWithSeed(Time.GetTimeH() + game.nextNumber.num, 0, 359));
		setPhysVariant(
			Utils.RandomWithSeed(Time.GetTimeH() + game.nextNumber.num, 0, Constants.PHYS_VARIANTS.length - 1),
		);
		onSpin();
		return () => {
			clearHighlight();
			animation.stop();
		};
	}, [game.nextNumber.num]);

	useLayoutEffect(() => {
		switch (game.roundState) {
			case GAME_ACTION.start: {
				break;
			}
			case GAME_ACTION.lastbet: {
				break;
			}
			case GAME_ACTION.nobets: {
				if (!game.isSpin) onSpin();
				break;
			}
			case GAME_ACTION.finish: {
				break;
			}
			default:
				return;
		}
	}, [game.roundState]);

	return (
		<Fragment>
			<div className={`${style.gameWheel} ${([GAME_ACTION.start, GAME_ACTION.finish] as GAME_STATE[]).includes(game.roundState) && style.blur}`}>
				<Wheel />
			</div>
			{!([GAME_ACTION.start, GAME_ACTION.finish] as GAME_STATE[]).includes(game.roundState) && app.isDebug && (
				<WheelControls onSpin={onSpin} onStop={onStop} onReset={onReset} />
			)}
		</Fragment>
	);
};

const frame = ({ delta: deltaTime }: FrameData) => {
	const game = $game.getState();

	if (game.wheelIsSpin) {
		setCurWheelAngle(game.curWheelAngle + 30 * -deltaTime);
	}

	if (game.isSpin) {
		// BALL
		// SPEED ------------------------------------------------------------------------
		const speed = getSpeed({
			deltaTime,
			prevProgressCoefficient: game.progressCoefficient,
			prevSpeedCoefficient: game.curSpeedCoefficient,
		});
		// RADIUS -----------------------------------------------------------------------
		const radius = getRadius({
			isBallShaking: game.isBallShaking,
			progressCoefficient: speed.progressCoefficient,
			curSpeedCoefficient: speed.curSpeedCoefficient,
		});
		// BALL ROTATION
		const rotation = getRotation({
			progressCoefficient: speed.progressCoefficient,
			expectedSectorAngle: game.nextNumber.deg,
			angleEasing: Constants.PHYS_VARIANTS[game.physVariant].angleEasing,
		});

		setProgressCoefficient(speed.progressCoefficient);
		setCurSpeedCoefficient(speed.curSpeedCoefficient);
		setCurBallRadCoefficient(radius.curBallRadCoefficient);
		setRandJumpShift(radius.randJumpShift);
		setCurBallAngle(rotation.curBallAngle);

		// Stop moment --------------------------------------------------------------
		if (speed.progressCoefficient >= 1) {
			onStop();
		}
	}
};

const animation = createAnimation({
	onFrame: frame,
	onStart: () => {
		setProgressCoefficient(0);
	},
});

const onSpin = () => {
	animation.start();
	setCurSpeedCoefficient(1);
	setCurBallRadCoefficient(1);
	setRandJumpShift(0);
	setCurBallAngle(0);
	setIsSpin(true);
};
const onStop = () => {
	setIsSpin(false);
	setCurSpeedCoefficient(0);
	setCurBallRadCoefficient(0);
};
const onReset = () => {
	onStop();
	setRandJumpShift(0);
	setCurBallAngle(0);
	setProgressCoefficient(0);
};

// ====================================================================================================================

const Wheel = () => {
	const app = useUnit($app);
	const game = useUnit($game);
	const wheel_rotation = {
		transform: `rotate(${game.curWheelAngle}deg)`,
	};

	return (
		<div className={`${style.wheel}`}>
			<div className={style.cover}>
				<img src={wheel_desk} alt="" />
			</div>
			<div className={style.rotate} style={wheel_rotation}>
				<img src={wheel_numbers} alt="" style={{ height: "66%" }} />
				<Ball altitude={1} />

				{app.isDebug && (
					<div className={style.debug}>
						<div className={style.debugLine} style={{ transform: `rotate(${game.nextNumber.deg}deg)` }}>
							<div className={style.line}>
								<div className={style.info}>
									<p>{`${game.nextNumber.deg.toFixed(0)}`}</p>
								</div>
							</div>
						</div>
						<DevTintLines />
					</div>
				)}
			</div>
			<div className={style.cover}>
				<img src={wheel_cover} alt="" />
			</div>
		</div>
	);
};
const DevTintLines = () => {
	const game = useUnit($game);
	const ball = useUnit($ball);
	const allLines = [
		{ angle: 0, color: `rgba(190,190,255,0.7)` },
		{ angle: game.nextNumber.deg, color: `rgba(158, 246, 85, 0.3)` },
		{ angle: ball.rotation - 90, color: `rgba(165, 23, 23, 0.3)` },
	];

	return (
		<div
			className={style.debugLine}
			style={{
				position: "absolute",
				top: "50%",
				left: "50%",
				height: "50%",
				width: 8,
				rotate: `0deg`,
				translate: `-50% -50%`,
				zIndex: 20,
			}}
		>
			{allLines.map((line, i) => (
				<div
					key={i}
					className={style.line}
					style={{
						position: "absolute",
						bottom: "50%",
						left: 0,
						height: "50%",
						width: "100%",
						background: line.color,
						transformOrigin: "50% 100%",
						rotate: `${line.angle}deg`,
					}}
				></div>
			))}
		</div>
	);
};

const $ball = $game.map((game) => {
	const rotation = Utils.normalize360(game.curBallAngle + 90);
	const translateX = (1 - game.curBallRadCoefficient) * 500 + game.randJumpShift * Math.random() * 2;
	const translateY = 1 - game.curBallRadCoefficient + game.randJumpShift * Math.random() * 3;

	return { rotation, translateX, translateY };
});
const Ball = (props: { altitude: IntRange<0, 100> }) => {
	const app = useUnit($app);
	const game = useUnit($game);
	const ball = useUnit($ball);
	const ball_rotation = {
		transform: `rotate(${ball.rotation}deg)`,
	};
	const ball_pos = {
		transform: `translateX(${ball.translateX}%)` + `translateY(${ball.translateY}%)`,
	};

	return (
		<div className={style.ball}>
			<div className={`${style.rotator}`} style={ball_rotation}>
				<img src={ballSVG} alt="ball" style={ball_pos} />
				{app.isDebug && (
					<div className={style.debug}>
						<div
							className={style.debugLine}
							style={{ transform: `rotate(${game.curBallRadCoefficient - 90}deg)` }}
						>
							<div className={style.line} style={{ background: "rgba(55, 255, 0, 0.29)" }}>
								<div className={style.info}>
									<p>{`${game.curBallRadCoefficient.toFixed(0)}`}</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

onReset();
