import { type HTMLAttributes, memo, type MouseEvent } from "react";
import style from "./style.module.scss";
import { cn } from "../../../../shared/lib/classNames";
import { Bet, type Slot } from "../../types";
import { useRotation, useBets } from "../../hooks";
import { BetChip } from "../betChip";

export interface CellProps extends HTMLAttributes<HTMLDivElement>, SlotReactions {
	selected: boolean;
	slots?: Slot[];
	betClassName?: string;
}

export const Cell = ({
	slots,
	selected,
	onSlotClick,
	onSlotContextMenu,
	onSlotMouseEnter,
	onSlotMouseLeave,
	betClassName,
	className,
	children,
	...props
}: CellProps) => {
	const { isVertical } = useRotation();
	return (
		<div className={cn(style.cell, selected && style.selected, className)} {...props}>
			<div className={style.highlight}></div>
			<span>{children}</span>
			{slots?.length ? (
				<div className={cn(style.slots, isVertical && style.rotated)}>
					{slots.map((slot) => (
						<CellSlot
							key={slot.id}
							className={betClassName}
							slot={slot}
							onSlotClick={onSlotClick}
							onSlotContextMenu={onSlotContextMenu}
							onSlotMouseEnter={onSlotMouseEnter}
							onSlotMouseLeave={onSlotMouseLeave}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

interface CellSlotProps extends Omit<HTMLAttributes<HTMLDivElement>, "slot">, SlotReactions {
	slot: Slot;
}
interface SlotReactions {
	onSlotClick?: (slot: Slot, event: MouseEvent) => void;
	onSlotContextMenu?: (slot: Slot, event: MouseEvent) => void;
	onSlotMouseEnter?: (slot: Slot, event: MouseEvent) => void;
	onSlotMouseLeave?: (slot: Slot, event: MouseEvent) => void;
}
const CellSlot = ({
	slot,
	onSlotClick,
	onSlotContextMenu,
	onSlotMouseEnter,
	onSlotMouseLeave,
	className,
}: CellSlotProps) => {
	const { getBetInfo, userBetsInSlot } = useBets();
	const userBets = userBetsInSlot({ slotId: slot.id });

	return (
		<div
			key={slot.id}
			data-slot={slot.place}
			className={cn(slot.fill && style.fill, className)}
			onClick={(e) => onSlotClick?.(slot, e)}
			onContextMenu={(e) => onSlotContextMenu?.(slot, e)}
			onMouseEnter={(e) => onSlotMouseEnter?.(slot, e)}
			onMouseLeave={(e) => onSlotMouseLeave?.(slot, e)}
		>
			{userBets.map((bet, i) => (
				<CellBet key={i} betInfo={getBetInfo(bet.betKey)} />
			))}
		</div>
	);
};
interface CellBetProps {
	betInfo?: Bet;
}
const CellBet = memo(({ betInfo }: CellBetProps) => {
	return (
		<div
			className={style.bet}
			style={{
				rotate: 360 * Math.random() + "deg",
				translate: `${-10 + 20 * Math.random()}% ${-10 + 20 * Math.random()}%`,
			}}
		>
			<BetChip style={{ color: betInfo?.color }}>{betInfo?.key}</BetChip>
		</div>
	);
});
