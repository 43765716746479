import { Fragment, useLayoutEffect } from "react";
import { useStore } from "effector-react";
import style from "./style.module.scss";
import { StatsWheel } from "../wheel/stats/StatsWheel";
import { Table } from "../table/Table";
import logo from "./../../img/logo.svg";
import { ReactComponent as Fullscreen } from "../../img/icons/fullscreen.svg";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import { $store } from "../../store";
import { GameWheel } from "../wheel/game/GameWheel";
import {GAME_ACTION, GAME_STATE, setIsPreloader, setIsWheelOpen} from "../../store/game";
import { Variant } from "../variant/Variant";
import { $stats } from "../../store/stats";
import Locale from "../../core/locale";
import Display from "../../core/display";
import {Language} from "../debug/language/Language";
import {RoundResult} from "../roundResult/RoundResult";
import {BetsResult} from "../roundResult/BetsResult";
import Device from "../../core/device";

export const Game = (props: { onToScreen: () => void; onToHome: () => void }) => {
	const store = useStore($store);

	useLayoutEffect(() => {
		const timeoutId = setTimeout(() => {
			setIsPreloader(false);
		}, 1000);
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);

	return (
		<div className={`${style.game}`}>
			<Header onToScreen={props.onToScreen} onToHome={props.onToHome}/>
			{store.app.socketIsReady && (
				<Fragment>
					{store.game.isWheelOpen ? (
						<GameWheel/>
					) : (
						<Fragment>
							<p></p>
							<p></p>
							<div className={style.table}>
								<Table/>
							</div>
							<Variant/>
							<LastNumbers/>
							<div className={style.statsWheel}>
								<StatsWheel/>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
			{ store.app.isCashdesk && store.app.results.length
				? <BetsResult/>
				: ([GAME_ACTION.finish] as GAME_STATE[]).includes(store.game.roundState) && (!store.app.isCashdesk || !store.app.results.length)
					? <RoundResult/>
					: null
			}
		</div>
	);
};

const Header = (props: { onToScreen: () => void; onToHome: () => void }) => {
	const store = useStore($store);
	const timer = 100 * (store.app.timer / store.app.roundDelay);

	useLayoutEffect(() => {
		switch (store.game.roundState) {
			case GAME_ACTION.start: {
				if (store.game.isWheelOpen) {
					setIsPreloader(true);
					const timeouts: NodeJS.Timeout[] = [];
					timeouts.push(
						setTimeout(() => {
							setIsWheelOpen(false);
							timeouts.push(
								setTimeout(() => {
									setIsPreloader(false);
								}, 500),
							);
						}, 1000),
					);
					return () => {
						for (const id of timeouts) clearTimeout(id);
					};
				}
				break;
			}
			case GAME_ACTION.nobets: {
				if (!store.game.isWheelOpen) {
					setIsPreloader(true);
					const timeouts: NodeJS.Timeout[] = [];
					timeouts.push(
						setTimeout(() => {
							setIsWheelOpen(true);
							timeouts.push(
								setTimeout(() => {
									setIsPreloader(false);
								}, 500),
							);
						}, 1000),
					);
					return () => {
						for (const id of timeouts) clearTimeout(id);
					};
				}
				break;
			}
			default:
				return;
		}
	}, [store.game.roundState]);

	const switchFullscreen = () => {
		Display.isFullScreen(!Display.getIsFullscreen());
	}

	return (
		<div className={`${style.header} ${(store.game.isPreloader || !store.app.socketIsReady) && style.closed}`}>
			<div className={style.left}>
				{ Display.getIsFullscreen()
					? <Close height={18} width={18} style={{cursor:"pointer"}} onClick={switchFullscreen}/>
					: !Device.isMobile() && <Fullscreen height={18} width={18} style={{cursor:"pointer"}} onClick={switchFullscreen}/>
				}

				{ store.app.isDebug && <Language/> }
			</div>
			<div className={style.logo}>
				<img src={logo} onClick={() => props.onToHome} alt="logo" />
				<p onClick={() => props.onToHome}>{Locale.Get("game_a_roulette").toUpperCase()}</p>
			</div>
			<div className={style.round}>
				<Fragment>
					<span className={style.number}>
						<p>{Locale.Get("round").toUpperCase()}</p>
						<p>
							{store.game.isWheelOpen || store.app.roundDelay === 0
								? Locale.Get("number").toUpperCase()
								: `${store.game.roundNumber} ${"In:".toUpperCase()}`}
						</p>
					</span>
					<span className={style.timer}>
						{store.game.isWheelOpen || store.app.roundDelay === 0
							? store.game.roundNumber
							: new Date(store.app.timer * 1000).toISOString().slice(15, 19)}
					</span>
				</Fragment>
			</div>
			<div className={style.timeout}>
				{store.app.roundDelay !== 0 ? <div style={{ width: `${timer}%` }} className={style.fill} /> : null}
			</div>
		</div>
	);
};

const LastNumbers = () => {
	const stats = useStore($stats);
	return (
		<div className={style.last}>
			<span>{Locale.Get("game_last_result").toUpperCase()}</span>
			{stats.lastNumbers.length ? (
				stats.lastNumbers.map((el, i) => (
					<p style={{ background: `${el.color}` }} key={`${el.index}${i}`}>
						{el.num===100 ? "00" : el.num}
					</p>
				))
			) : (
				<p style={{ textWrap: "nowrap", width: "auto" }}>{"NONE"}</p>
			)}
		</div>
	);
};
