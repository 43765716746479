type CreateAnimation = (data: { onFrame: OnFrame; onStart?: () => void; onStop?: () => void }) => {
	start: Start;
	stop: Stop;
};
type Start = () => void;
type Stop = () => void;
export type FrameData = { isPlaying: boolean; delta: number };
type OnFrame = (frame: FrameData) => void;

export const createAnimation: CreateAnimation = ({ onStart, onStop, onFrame }) => {
	let frameId = -1;
	let isPlaying = false;
	let lastTime = Date.now();

	const start: Start = () => {
		if (!isPlaying) {
			isPlaying = true;
			lastTime = Date.now();
			frameId = requestAnimationFrame(frame);
			onStart?.();
		}
	};
	const stop: Stop = () => {
		isPlaying = false;
		cancelAnimationFrame(frameId);
		frameId = -1;
		onStop?.();
	};
	const frame = () => {
		const currentTime = Date.now();
		const delta = (currentTime - lastTime) * 0.001;
		lastTime = Date.now();
		onFrame({ isPlaying, delta });
		if (isPlaying) frameId = requestAnimationFrame(frame);
	};

	return { start, stop };
};
