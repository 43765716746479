import React, { type ButtonHTMLAttributes, type HTMLAttributes, type PropsWithChildren, memo, useState } from "react";
import { createPortal } from "react-dom";
import { useUnit } from "effector-react";
import style from "./style.module.scss";
import { type ColumnId, type NumId, type PartId, type RowId, type Slot, type SlotId } from "../types";
import { ReactComponent as UndoIcon } from "../../../shared/assets/undo.svg";
import { ReactComponent as RepeatIcon } from "../../../shared/assets/repeat.svg";
import { ReactComponent as ArrowIcon } from "../../../shared/assets/arrow_right.svg";
import { cn } from "../../../shared/lib/classNames";
import {
	isColumnId,
	isColumnsId,
	isNumId,
	isNumsId,
	isPartId,
	isPartsId,
	isRowId,
	isRowsId,
	isSpecialCellId,
	numId,
} from "../store";
import {
	useBets,
	useMousePosition,
	useMouseUpdate,
	useRotation,
	useSelected,
	useSlots,
	useTable,
	usePrognosis,
} from "../hooks";
import { Cell } from "./cell";
import { CELLS, PRESETS } from "../config";
import { BetChip } from "./betChip";
import { Button } from "../../../shared/ui/button";
import { VerticalLine } from "./verticalLine";
import { $store } from "../../../store";
import { getTransformedAmount } from "../../../shared/lib/getTransformedAmount";
import { useNotifications } from "../../notifications";
import { notificationColor } from "../../notifications/colors";
import { useTranslation } from "../../../shared/hooks";
import { $app } from "../../../store/app";

export const BetTable = () => {
	const app = useUnit($app);
	const { isVertical } = useRotation();
	const { columns, rows } = useTable();
	const [isBetVisible, setBetVisibility] = useState(false);
	const { updatePosition: updateMousePosition } = useMouseUpdate();

	const handleMouseEnter: React.MouseEventHandler = (event) => {
		setBetVisibility(true);
		updateMousePosition([event.clientY, event.clientX]);
	};
	const handleMouseMove: React.MouseEventHandler = (event) => {
		setBetVisibility(true);
		updateMousePosition([event.clientY, event.clientX]);
	};
	const handleMouseLeave: React.MouseEventHandler = () => {
		setBetVisibility(false);
	};

	return (
		<div key={String(isVertical)} className={cn(style.betTable, isVertical && style.vertical)}>
			<RouletteControls style={{ order: isVertical ? 1 : 0 }} isVertical={isVertical} />
			<div className={cn(style.tableContainer, isVertical && style.vertical)}>
				<div
					className={style.table}
					style={{
						gridTemplateColumns: `repeat(${(isVertical ? columns.length : rows.length) + 2}, 1fr)`,
						gridTemplateRows: `repeat(${(isVertical ? rows.length : columns.length) + 2}, 1fr)`,
					}}
					onMouseEnter={handleMouseEnter}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
				>
					<ZerosTable startX={isVertical ? 3 : 1} startY={1} />
					<NumsTable startX={isVertical ? 2 : 1} startY={1} />
					<ColumnsTable startX={isVertical ? 3 : rows.length + 2} startY={isVertical ? rows.length + 2 : 1} />
					<PartsTable startX={2} startY={isVertical ? 2 : columns.length + 1} />
					<SpecialsTable startX={isVertical ? 1 : 2} startY={isVertical ? 2 : columns.length + 2} />
				</div>
				{isBetVisible && !app.appIsMobile && <FloatBet />}
			</div>
			<DevTable />
		</div>
	);
};

const RouletteControls = ({ isVertical }: HTMLAttributes<HTMLDivElement> & { isVertical?: boolean }) => {
	const { t } = useTranslation();
	const { userBets, userBetsLog, multiplyBets, undoBets } = useBets();

	return (
		<div className={cn(style.controls, isVertical && style.vertical)}>
			<Button
				className={cn(style.control, !isVertical && style.minWidth)}
				onClick={() => multiplyBets(2)}
				disabled={!userBets.length}
				aria-label={t("controls.double")}
			>
				<span className={style.icon}>
					<span style={{ fontSize: "60%" }}>{"✖"}</span>
					<span style={{ fontSize: "120%" }}>{"2"}</span>
				</span>
				{!isVertical && <span className={style.label}>{t("controls.double")}</span>}
			</Button>
			<Button
				className={cn(style.control, !isVertical && style.minWidth)}
				disabled={true}
				aria-label={t("controls.rebet")}
			>
				<RepeatIcon className={style.icon} />
				{!isVertical && <span className={style.label}>{t("controls.rebet")}</span>}
			</Button>
			<VerticalLine />
			<Button
				className={cn(style.control, !isVertical && style.minWidth)}
				onClick={undoBets}
				disabled={!userBetsLog.length}
				aria-label={t("controls.undo")}
			>
				<UndoIcon className={style.icon} />
				{!isVertical && <span className={style.label}>{t("controls.undo")}</span>}
			</Button>
		</div>
	);
};
const SpecialsTable = ({ startX = 1, startY = 1 }: { startX?: number; startY?: number }) => {
	const { isVertical } = useRotation();
	const { t } = useTranslation();
	const { rows } = useTable();
	const { selected, selectOdd, selectEven, selectLow, selectHigh, selectRed, selectBlack, selectCells, unselect } =
		useSelected();
	const { getSlotsByParent } = useSlots();
	const { addSelectedBets, removePositionAllBets } = useBets();
	const { prognosis, setHovered } = usePrognosis();

	const handleMouseLeave: (slot: Slot) => void = () => {
		unselect();
		setHovered(null);
	};

	const handleSlotClick: (slot: Slot, event: React.MouseEvent) => void = (slot) => {
		addSelectedBets({ slotsIds: [slot.id] });
	};

	const handleSlotContextMenu: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		event.preventDefault();
		removePositionAllBets({ slotId: slot.id });
	};

	return (
		<>
			{prognosis ? (
				<SlotBetPopup
					top={prognosis.position.at(0)}
					left={prognosis.position.at(1)}
					amount={prognosis.amount}
					prognosis={prognosis.prognosis}
				/>
			) : null}
			<div
				className="grid"
				style={
					isVertical
						? {
								gridArea: `${startY} / ${startX} / ${startY + rows.length} / ${startX + 1}`,
								gridTemplateRows: `repeat(${rows.length}, 1fr)`,
							}
						: {
								gridArea: `${startY} / ${startX} / ${startY + 1} / ${startX + rows.length}`,
								gridTemplateColumns: `repeat(${rows.length}, 1fr)`,
							}
				}
			>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.low)}
					slots={getSlotsByParent(CELLS.low)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectLow();
						selectCells([CELLS.low]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `1 / 1 / span 2 / span 1` : `1 / 1 / span 1 / span 2`,
					}}
				>
					{t(`table.${CELLS.low}`, { defaultValue: "LOW" }).toUpperCase()}
				</Cell>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.even)}
					slots={getSlotsByParent(CELLS.even)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectEven();
						selectCells([CELLS.even]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `3 / 1 / span 2 / span 1` : `1 / 3 / span 1 / span 2`,
					}}
				>
					{t(`table.${CELLS.even}`, { defaultValue: "EVEN" }).toUpperCase()}
				</Cell>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.red)}
					slots={getSlotsByParent(CELLS.red)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectRed();
						selectCells([CELLS.red]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `5 / 1 / span 2 / span 1` : `1 / 5 / span 1 / span 2`,
						background: "rgb(204, 48, 48)",
					}}
				>
					{t(`table.${CELLS.red}`, { defaultValue: "RED" }).toUpperCase()}
				</Cell>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.black)}
					slots={getSlotsByParent(CELLS.black)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectBlack();
						selectCells([CELLS.black]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `7 / 1 / span 2 / span 1` : `1 / 7 / span 1 / span 2`,
						background: "rgb(0, 0, 0)",
					}}
				>
					{t(`table.${CELLS.black}`, { defaultValue: "BLACK" }).toUpperCase()}
				</Cell>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.odd)}
					slots={getSlotsByParent(CELLS.odd)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectOdd();
						selectCells([CELLS.odd]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `9 / 1 / span 2 / span 1` : `1 / 9 / span 1 / span 2`,
					}}
				>
					{t(`table.${CELLS.odd}`, { defaultValue: "ODD" }).toUpperCase()}
				</Cell>
				<Cell
					className={cn(style.specialCell, isVertical && style.rotateText)}
					betClassName={style.bet}
					selected={selected.cells.includes(CELLS.high)}
					slots={getSlotsByParent(CELLS.high)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={(slot, event) => {
						setHovered({ slot, element: event.currentTarget });
						selectHigh();
						selectCells([CELLS.high]);
					}}
					onSlotMouseLeave={handleMouseLeave}
					style={{
						gridArea: isVertical ? `11 / 1 / span 2 / span 1` : `1 / 11 / span 1 / span 2`,
					}}
				>
					{t(`table.${CELLS.high}`, { defaultValue: "HIGH" }).toUpperCase()}
				</Cell>
			</div>
		</>
	);
};
const ZerosTable = ({ startX = 1, startY = 1 }: { startX?: number; startY?: number }) => {
	const { isVertical } = useRotation();
	const { zeroes, columns } = useTable();
	const { getSlotsByParent } = useSlots();
	const { selected, selectNumIds, unselect } = useSelected();
	const { addSelectedBets, removePositionAllBets } = useBets();
	const { prognosis, setHovered } = usePrognosis();

	const handleMouseEnter: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		const addNumsSelection = () => {
			selectNumIds(slot.ids.map(({ id }) => id).filter(isNumId));
		};

		addNumsSelection();
		setHovered({ slot, element: event.currentTarget });
	};
	const handleMouseLeave: (slot: Slot) => void = () => {
		unselect();
		setHovered(null);
	};

	const handleSlotClick: (slot: Slot, event: React.MouseEvent) => void = (slot) => {
		addSelectedBets({ slotsIds: [slot.id] });
	};

	const handleSlotContextMenu: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		event.preventDefault();
		removePositionAllBets({ slotId: slot.id });
	};

	return (
		<>
			{prognosis ? (
				<SlotBetPopup
					top={prognosis.position.at(0)}
					left={prognosis.position.at(1)}
					amount={prognosis.amount}
					prognosis={prognosis.prognosis}
				/>
			) : null}
			<div
				className="grid"
				style={
					isVertical
						? {
								gridArea: `${startY} / ${startX} / span 1 / span ${columns.length}`,
								gridTemplateColumns: `repeat(${zeroes.length}, 1fr)`,
								gridTemplateRows: `1fr`,
							}
						: {
								gridArea: `${startY} / ${startX} / span ${columns.length} / span 1`,
								gridTemplateColumns: `1fr`,
								gridTemplateRows: `repeat(${zeroes.length}, 1fr)`,
							}
				}
			>
				{zeroes.map((zero, i) => (
					<Cell
						key={zero.id}
						className={style.zeroCell}
						selected={selected.numIds.includes(zero.id) || selected.rowsIds.includes(zero.rowId)}
						slots={getSlotsByParent(zero.id)}
						onSlotClick={handleSlotClick}
						onSlotContextMenu={handleSlotContextMenu}
						onSlotMouseEnter={handleMouseEnter}
						onSlotMouseLeave={handleMouseLeave}
						style={{
							gridArea: isVertical
								? `1 / ${1 + i} / span 1 / span 1`
								: `${1 + zeroes.length - (i + 1)} / 1 / span 1 / span 1`,
						}}
					>
						{zero.title}
					</Cell>
				))}
			</div>
		</>
	);
};
const NumsTable = ({ startX = 1, startY = 1 }: { startX?: number; startY?: number }) => {
	const { isVertical } = useRotation();
	const { nums, columns } = useTable();
	const { selected, selectRowsIds, selectNumIds, unselect } = useSelected();
	const { addSelectedBets, removePositionAllBets } = useBets();
	const { getSlotsByParent } = useSlots();
	const { prognosis, setHovered } = usePrognosis();

	const handleMouseEnter: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		const addRowSelection = () => {
			selectRowsIds(slot.ids.map(({ id }) => id).filter(isRowId));
		};
		const addNumSelection = () => {
			selectNumIds(slot.ids.map(({ id }) => id).filter(isNumId));
		};

		if (isRowsId(slot.id)) {
			addRowSelection();
		} else if (isNumsId(slot.id)) {
			addNumSelection();
		}
		setHovered({ slot, element: event.currentTarget });
	};
	const handleMouseLeave: (slot: Slot, event: React.MouseEvent) => void = () => {
		unselect();
		setHovered(null);
	};

	const handleSlotClick: (slot: Slot, event: React.MouseEvent) => void = (slot) => {
		addSelectedBets({ slotsIds: [slot.id] });
	};

	const handleSlotContextMenu: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		event.preventDefault();
		removePositionAllBets({ slotId: slot.id });
	};

	return (
		<>
			{prognosis ? (
				<SlotBetPopup
					top={prognosis.position.at(0)}
					left={prognosis.position.at(1)}
					amount={prognosis.amount}
					prognosis={prognosis.prognosis}
				/>
			) : null}
			{nums.map((num) => (
				<Cell
					key={num.id}
					className={cn(style.numsCell, num.isBlack && style.black, num.isRed && style.red)}
					selected={
						(num.isEven && selected.even) ||
						(num.isOdd && selected.odd) ||
						(num.isLow && selected.low) ||
						(num.isHigh && selected.high) ||
						(num.isBlack && selected.black) ||
						(num.isRed && selected.red) ||
						selected.columnsIds.includes(num.columnId) ||
						selected.rowsIds.includes(num.rowId) ||
						selected.partsIds.includes(num.partId) ||
						selected.numIds.includes(num.id)
					}
					slots={getSlotsByParent(num.id)}
					onSlotClick={handleSlotClick}
					onSlotContextMenu={handleSlotContextMenu}
					onSlotMouseEnter={handleMouseEnter}
					onSlotMouseLeave={handleMouseLeave}
					style={
						isVertical
							? {
									gridArea: `${startY + num.row} / ${startX + num.column} / span 1 / span 1`,
								}
							: {
									gridArea: `${startY + (columns.length - num.column)} / ${startX + num.row} / span 1 / span 1`,
								}
					}
				>
					{num.num}
				</Cell>
			))}
		</>
	);
};
const ColumnsTable = ({ startX, startY }: { startX: number; startY: number }) => {
	const { isVertical } = useRotation();
	const { t } = useTranslation();
	const { columns, columnsCells } = useTable();
	const { selected, selectColumnsIds, unselect } = useSelected();
	const { getSlotsByParent } = useSlots();
	const { addSelectedBets, removePositionAllBets } = useBets();
	const { prognosis, setHovered } = usePrognosis();

	const handleMouseEnter: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		const addColumnSelection = () => {
			selectColumnsIds(slot.ids.map(({ id }) => id).filter(isColumnId));
		};

		addColumnSelection();
		setHovered({ slot, element: event.currentTarget });
	};
	const handleMouseLeave: (slot: Slot) => void = () => {
		unselect();
		setHovered(null);
	};

	const handleSlotClick: (slot: Slot, event: React.MouseEvent) => void = (slot) => {
		addSelectedBets({ slotsIds: [slot.id] });
	};

	const handleSlotContextMenu: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		event.preventDefault();
		removePositionAllBets({ slotId: slot.id });
	};

	return (
		<>
			{prognosis ? (
				<SlotBetPopup
					top={prognosis.position.at(0)}
					left={prognosis.position.at(1)}
					amount={prognosis.amount}
					prognosis={prognosis.prognosis}
				/>
			) : null}
			<div
				className="grid"
				style={
					isVertical
						? {
								gridArea: `${startY} / ${startX} / ${startY + 1} / ${startX + columns.length}`,
								gridTemplateRows: "1fr",
								gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
							}
						: {
								gridArea: `${startY} / ${startX} / ${startY + columns.length} / ${startX + 1}`,
								gridTemplateColumns: `1fr`,
								gridTemplateRows: `repeat(${columns.length}, 1fr)`,
							}
				}
			>
				{columnsCells.map((c) => (
					<Cell
						key={c.id}
						className={cn(style.columCell, isVertical && style.rotateText)}
						betClassName={style.bet}
						selected={selected.columnsIds.includes(c.columnId)}
						slots={getSlotsByParent(c.id)}
						onSlotClick={handleSlotClick}
						onSlotContextMenu={handleSlotContextMenu}
						onSlotMouseEnter={handleMouseEnter}
						onSlotMouseLeave={handleMouseLeave}
						style={{
							gridArea: isVertical
								? `1 / ${c.column} / span 1 / span 1`
								: `${1 + (columns.length - c.column)} / 1 / span 1 / span 1`,
						}}
					>
						{t(c.titleKey.key, c.titleKey.args)}
					</Cell>
				))}
			</div>
		</>
	);
};
const PartsTable = ({ startX, startY }: { startX: number; startY: number }) => {
	const { isVertical } = useRotation();
	const { t } = useTranslation();
	const { selected, selectPartsIds, unselect } = useSelected();
	const { parts, partsCells, rows } = useTable();
	const { getSlotsByParent } = useSlots();
	const { addSelectedBets, removePositionAllBets } = useBets();
	const { prognosis, setHovered } = usePrognosis();

	const handleMouseEnter: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		const addColumnSelection = () => {
			selectPartsIds(slot.ids.map(({ id }) => id).filter(isPartId));
		};

		addColumnSelection();
		setHovered({ slot, element: event.currentTarget });
	};
	const handleMouseLeave: (slot: Slot, event: React.MouseEvent) => void = () => {
		unselect();
		setHovered(null);
	};

	const handleSlotClick: (slot: Slot, event: React.MouseEvent) => void = (slot) => {
		addSelectedBets({ slotsIds: [slot.id] });
	};

	const handleSlotContextMenu: (slot: Slot, event: React.MouseEvent) => void = (slot, event) => {
		event.preventDefault();
		removePositionAllBets({ slotId: slot.id });
	};

	return (
		<>
			{prognosis ? (
				<SlotBetPopup
					top={prognosis.position.at(0)}
					left={prognosis.position.at(1)}
					amount={prognosis.amount}
					prognosis={prognosis.prognosis}
				/>
			) : null}
			<div
				className="grid"
				style={
					isVertical
						? {
								gridArea: `${startY} / ${startX} / ${startY + rows.length} / ${startX + 1}`,
								gridTemplateColumns: "1fr",
								gridTemplateRows: `repeat(${parts.length}, 1fr)`,
							}
						: {
								gridArea: `${startY} / ${startX} / ${startY + 1} / ${startX + rows.length}`,
								gridTemplateColumns: `repeat(${parts.length}, 1fr)`,
								gridTemplateRows: "1fr",
							}
				}
			>
				{partsCells.map((part) => (
					<Cell
						key={part.id}
						className={cn(style.partCell, isVertical && style.rotateText)}
						betClassName={style.bet}
						selected={selected.partsIds.includes(part.partId)}
						slots={getSlotsByParent(part.id)}
						onSlotClick={handleSlotClick}
						onSlotContextMenu={handleSlotContextMenu}
						onSlotMouseEnter={handleMouseEnter}
						onSlotMouseLeave={handleMouseLeave}
					>
						{t(part.titleKey.key, part.titleKey.args)}
					</Cell>
				))}
			</div>
		</>
	);
};

const FloatBet = () => {
	const {
		position: [top, left],
	} = useMousePosition();
	const { selectedBet, getBetInfo } = useBets();
	const betInfo = getBetInfo(selectedBet);

	return (
		<div className={style.floatBet} style={{ top, left }}>
			<BetChip style={{ color: betInfo?.color }}>{betInfo?.key}</BetChip>
		</div>
	);
};
const BetPopup = ({ ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => (
	<div className={style.popup} {...props} />
);
const SlotBetPopup = ({
	top,
	left,
	amount,
	prognosis,
}: {
	top?: number;
	left?: number;
	amount: number;
	prognosis: number;
}) => {
	const { t } = useTranslation();

	return createPortal(
		<BetPopup style={{ top, left }}>
			<span>{`${t("slotBet.Pay in")}: ${getTransformedAmount(amount)}`}</span>
			<span>{`${t("slotBet.Payout")}: ${getTransformedAmount(prognosis)}`}</span>
		</BetPopup>,
		document.body,
	);
};

export const BetsTable = ({ isVertical, ...props }: { isVertical?: boolean } & HTMLAttributes<HTMLDivElement>) => {
	const { bets, selectedBet, selectBet } = useBets();

	return (
		<div className={cn(style.betsTable, isVertical && style.vertical)} {...props}>
			{bets.map((bet) => (
				<BetChip
					key={bet.key}
					className={cn(style.betChip, selectedBet === bet.key && style.selected)}
					style={{
						color: bet.color,
					}}
					onClick={() => selectBet(bet.key)}
				>
					{bet.title}
				</BetChip>
			))}
		</div>
	);
};
export const Payment = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
	const { t } = useTranslation();
	const { totalUserBet } = useBets();

	return (
		<div className={cn(style.payment, className)} {...props}>
			<span className={style.label}>{t("payment.total")}</span>
			<span className={style.value}>{getTransformedAmount(totalUserBet).toFixed(2)}</span>
		</div>
	);
};
export const Presets = ({ onPresetClick }: { onPresetClick?: () => void }) => {
	const { getSlotsByCells } = useSlots();
	const { setSelectedBets } = useBets();
	const setNumbers: (numbers: number[][]) => void = (numbers) => {
		let slotsIds: SlotId[] = [];
		for (const nums of numbers) {
			const ids = nums.map(numId);
			const slots = getSlotsByCells(ids);
			slotsIds = [...slotsIds, ...slots.map(({ id }) => id)];
		}
		setSelectedBets({ slotsIds });
	};

	return (
		<div className={style.presets}>
			{PRESETS.map((preset) => (
				<div
					key={preset.name}
					className={style.preset}
					onClick={() => {
						setNumbers(preset.numbers);
						onPresetClick?.();
					}}
				>
					<div className={style.info}>
						<span>{preset.name}</span>
						<span className={style.cells}>{preset.numbers.length}</span>
					</div>
					<ArrowIcon className={style.icon} />
				</div>
			))}
		</div>
	);
};
export const SignIn = ({ className, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => {
	const { t } = useTranslation();

	return (
		<Button variant={"contained"} colored={"payIn"} className={cn(style.auth, style.signIn, className)} {...props}>
			{t("sign.in")}
		</Button>
	);
};
export const PayInButton = ({ className, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => {
	const { t } = useTranslation();

	return (
		<Button variant={"contained"} colored={"payIn"} className={cn(style.auth, style.signIn, className)} {...props}>
			<span>{t("pay.in")}</span>
		</Button>
	);
};

const DevTable = () => {
	const store = useUnit($store);

	if (!store.app.isDebug) return null;
	return (
		<div
			style={{
				order: -1,
				position: "relative",
				display: "grid",
				gap: 16,
				background: "#223",
				padding: 8,
				border: "2px solid red",
				borderRadius: 2,
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 0,
					right: 0,
					background: "red",
					padding: 4,
					borderRadius: "0 0 0 2px",
					color: "white",
				}}
			>
				{"dev"}
			</div>
			<BetsTable />
			<DevActions />
			<Presets />
			<DevBets />
			<DevNotifications />
		</div>
	);
};
const DevNotifications = () => {
	const { notifications } = useNotifications();

	return (
		<div style={{ display: "grid", gap: 8 }}>
			{notifications.map((notify) => (
				<div
					key={notify.id}
					className={notificationColor({ status: notify.type, background: "true", color: "true" })}
					style={{ borderRadius: 4, padding: 8 }}
				>
					`[${notify.id}] ${notify.message}`
				</div>
			))}
		</div>
	);
};
const DevActions = () => {
	const rotation = useRotation();
	const { multiplyBets, clearBets } = useBets();

	return (
		<div style={{ display: "flex", gap: 8 }}>
			<Button onClick={() => rotation.rotate()}>{"Rotate table"}</Button>
			<Button onClick={() => multiplyBets(2)}>{"x2 bets"}</Button>
			<Button onClick={clearBets}>{"clear bets"}</Button>
		</div>
	);
};
const DevBets = () => {
	const { userBets, bets, removePositionLastBet, removePositionAllBets } = useBets();
	const { slots } = useSlots();
	const {
		selectRowsIds,
		selectColumnsIds,
		selectPartsIds,
		selectNumIds,
		selectCells,
		selectLow,
		selectHigh,
		selectEven,
		selectOdd,
		selectRed,
		selectBlack,
		unselect,
	} = useSelected();

	const getBetInfo = (betId: string) => {
		return bets.find((bet) => bet.key === betId);
	};
	const getSlotInfo = (slotId: string) => {
		return slots.find((slot) => slot.id === slotId);
	};

	return (
		<div style={{ display: "grid", gap: 8 }}>
			<p style={{ background: "#141e26", borderRadius: 4, padding: 8, color: "#ccf" }}>
				{"LMB - log info"}
				<br />
				{"RMB - remove last bet in position"}
				<br />
				{"Shift + RMB - remove all bets in position"}
			</p>
			{userBets.map((bet, i) => {
				const betInfo = getBetInfo(bet.betKey);
				const slotInfo = getSlotInfo(bet.slotId);
				return (
					<div
						key={`${bet.slotId}.${bet.betKey}.${i}`}
						style={{ display: "grid", gridTemplateColumns: `minmax(200px, 20%) auto`, gap: 8 }}
					>
						<span>{`Bet: ${betInfo?.title} [key: ${betInfo?.key}]`}</span>
						<div style={{ display: "flex", flexFlow: "row", gap: 4 }}>
							{slotInfo?.ids.map((id) => (
								<Button
									key={id.id}
									onContextMenu={(event) => {
										event.preventDefault();
										if (event.shiftKey) removePositionAllBets(bet);
										else removePositionLastBet(bet);
									}}
									onMouseEnter={() => {
										if (!slotInfo) return;
										if (isRowsId(slotInfo.id)) return selectRowsIds([id.id as RowId]);
										if (isColumnsId(slotInfo.id)) return selectColumnsIds([id.id as ColumnId]);
										if (isPartsId(slotInfo.id)) return selectPartsIds([id.id as PartId]);
										if (isNumsId(slotInfo.id)) return selectNumIds([id.id as NumId]);
										if (isSpecialCellId(id.id)) {
											selectCells([id.id]);
											switch (id.id) {
												case "low":
													return selectLow();
												case "high":
													return selectHigh();
												case "even":
													return selectEven();
												case "odd":
													return selectOdd();
												case "red":
													return selectRed();
												case "black":
													return selectBlack();
												default:
													break;
											}
										}
									}}
									onMouseLeave={unselect}
								>{`${id.id}`}</Button>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export const MemoizedBetTable = memo(BetTable);
