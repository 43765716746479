import Easing from "./easing";

interface EL {
	name: string;
	value: number;
	color?: string;
}
export interface NUMBER {
	index: number;
	num: number;
	color: string;
	flags: string;
	probability: {
		count: number;
		cf: number;
		color: string;
	};
}
export type PHYS_VARIANT = {
	name: string;
	radiusEasing?: {
		easing: (x: number) => number;
	};
	angleEasing?: {
		trigger: number;
		offsetAngle: number;
		easing?: (x: number) => number;
	};
};
export type COL = {
	value: string;
	flag: string;
};

export default class Constants {
	static COLORS = {
		null: "#FF00F3",
		transparent: "transparent",
		fill: {
			back: "#0E2C25CE",
			lines: "#184339",
			inner: "#09170f",
			counter: "#181e19",
			numbers: "#242424",
			graphs: "#181e2e",
			groups: "#1c1f1d",
			percent: "#232a26",
		},
		numbers: {
			zero: "#287736",
			red: "#ca3030",
			black: "#242424",
		},
		probability: {
			"0.00": "#2e80c1",
			"0.25": "#00b40d",
			"0.50": "#d9de2b",
			"0.75": "#dd7e2a",
			"1.00": "#ca3030",
		},
	};
	static NUMBERS: NUMBER[] = [
		{
			index: 1,
			num: 0,
			color: Constants.COLORS.numbers.zero,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "zero",
		}, // 0
		{
			index: 2,
			num: 28,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,black,col1,3rd12",
		}, // 28
		{
			index: 3,
			num: 9,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,red,col3,1st12",
		}, // 9
		{
			index: 4,
			num: 26,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,black,col2,3rd12",
		}, // 26
		{
			index: 5,
			num: 30,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,red,col3,3rd12",
		}, // 30
		{
			index: 6,
			num: 11,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,black,col2,1st12",
		}, // 11
		{
			index: 7,
			num: 7,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,red,col1,1st12",
		}, // 7
		{
			index: 8,
			num: 20,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,black,col2,2nd12",
		}, // 20
		{
			index: 9,
			num: 32,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,red,col2,3rd12",
		}, // 32
		{
			index: 10,
			num: 17,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,black,col2,2nd12",
		}, // 17
		{
			index: 11,
			num: 5,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,red,col2,1st12",
		}, // 5
		{
			index: 12,
			num: 22,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,black,col1,2nd12",
		}, // 22
		{
			index: 13,
			num: 34,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,red,col1,3rd12",
		}, // 34
		{
			index: 14,
			num: 15,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,black,col3,2nd12",
		}, // 15
		{
			index: 15,
			num: 3,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,red,col3,1st12",
		}, // 3
		{
			index: 16,
			num: 24,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,black,col3,2nd12",
		}, // 24
		{
			index: 17,
			num: 36,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,high,red,col3,3rd12",
		}, // 36
		{
			index: 18,
			num: 13,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,black,col1,2nd12",
		}, // 13
		{
			index: 19,
			num: 1,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,low,red,col1,1st12",
		}, // 1
		{
			index: 20,
			num: 100,
			color: Constants.COLORS.numbers.zero,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "zero",
		}, // 00
		{
			index: 21,
			num: 27,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,red,col3,3rd12",
		}, // 27
		{
			index: 22,
			num: 10,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,black,col1,1st12",
		}, // 10
		{
			index: 23,
			num: 25,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,red,col1,3rd12",
		}, // 25
		{
			index: 24,
			num: 29,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,black,col2,3rd12",
		}, // 29
		{
			index: 25,
			num: 12,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,red,col3,1st12",
		}, // 12
		{
			index: 26,
			num: 8,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,black,col2,1st12",
		}, // 8
		{
			index: 27,
			num: 19,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,red,col1,2nd12",
		}, // 19
		{
			index: 28,
			num: 31,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,black,col1,3rd12",
		}, // 31
		{
			index: 29,
			num: 18,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,red,col3,2nd12",
		}, // 18
		{
			index: 30,
			num: 6,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,black,col3,1st12",
		}, // 6
		{
			index: 31,
			num: 21,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,red,col3,2nd12",
		}, // 21
		{
			index: 32,
			num: 33,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,black,col3,3rd12",
		}, // 33
		{
			index: 33,
			num: 16,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,red,col1,2nd12",
		}, // 16
		{
			index: 34,
			num: 4,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,black,col1,1st12",
		}, // 4
		{
			index: 35,
			num: 23,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,red,col2,2nd12",
		}, // 23
		{
			index: 36,
			num: 35,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "odd,high,black,col2,3rd12",
		}, // 35
		{
			index: 37,
			num: 14,
			color: Constants.COLORS.numbers.red,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,red,col2,2nd12",
		}, // 14
		{
			index: 38,
			num: 2,
			color: Constants.COLORS.numbers.black,
			probability: { count: Math.round(Math.random() * 15) + 1, cf: -1, color: Constants.COLORS.null },
			flags: "even,low,black,col2,1st12",
		}, // 2
	];
	static SECTIONS = [
		// Sections stat
		[{ name: "odd", value: 44.9 } as EL, { name: "even", value: 51.3 } as EL],
		[{ name: "high", value: 49.4 } as EL, { name: "low", value: 46.8 } as EL],
		[
			{ name: "1st col", value: 28.2 } as EL,
			{ name: "2nd col", value: 36.5 } as EL,
			{ name: "3rd col", value: 31.4 } as EL,
		],
		[
			{ name: "1st 12", value: 32.7 } as EL,
			{ name: "2nd 12", value: 30.1 } as EL,
			{ name: "3rd 12", value: 33.3 } as EL,
		],
		[
			{ name: "1st 12", value: 32.7 } as EL,
			{ name: "2nd 12", value: 30.1 } as EL,
			{ name: "3rd 12", value: 33.3 } as EL,
		],
		[
			{ name: "zero", value: 43.6, color: Constants.COLORS.numbers.zero },
			{ name: "red", value: 52.6, color: Constants.COLORS.numbers.red },
			{ name: "black", value: 33.3, color: "#141414" },
		],
	];
	static COLS: COL[] = [
		{ value: "3", flag: "col3" },
		{ value: "2", flag: "col2" },
		{ value: "1", flag: "col1" },
	];
	static GROUPS = {
		"1st 12": [
			{ label: "combination.low", name: "low" },
			{ label: "combination.even", name: "even" },
		],
		"2nd 12": [
			{ label: "combination.red", name: "red" },
			{ label: "combination.black", name: "black" },
		],
		"3rd 12": [
			{ label: "combination.odd", name: "odd" },
			{ label: "combination.high", name: "high" },
		],
	};

	public static getNumDegreesByNumberElement = (numberElement?: NUMBER) => {
		const degOnPart = 360 / Constants.NUMBERS.length;
		const parts = numberElement?.index ? numberElement?.index - 1 : 0;
		return degOnPart * parts;
	};
	private static getNumDegrees = (num: number | string): number => {
		const numberElement = Constants.NUMBERS.find((el: NUMBER) => el.num === num);
		return this.getNumDegreesByNumberElement(numberElement);
	};
	static PHYS_VARIANTS: PHYS_VARIANT[] = [
		{
			// ?
			name: "InOutBounce+OutExp",
			radiusEasing: {
				easing: (x: number) => Easing.easeInOutBounce(Easing.easeOutExpo(x)),
			},
			angleEasing: {
				trigger: 0.205,
				offsetAngle: 30,
				easing: Easing.easeInOutSine,
			},
		},
		{
			name: "InOutBounce+OutBounce",
			radiusEasing: {
				easing: (x: number) => Easing.easeInOutBounce(Easing.easeOutBounce(x)),
			},
			angleEasing: {
				trigger: 0.03,
				offsetAngle: 15,
				easing: Easing.easeInOutSine,
			},
		},
		{
			name: "OutBounce+OutBounce",
			radiusEasing: {
				easing: (x: number) => Easing.easeOutExpo(Easing.easeOutBounce(x)),
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.058,
				offsetAngle: 10,
				easing: Easing.easeOutExpo,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.061,
				offsetAngle: 11,
				easing: Easing.easeOutExpo,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.079,
				offsetAngle: 15,
				easing: Easing.easeOutBounce,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.098,
				offsetAngle: 15,
				easing: Easing.easeInOutBounce,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.17,
				offsetAngle: 2,
				easing: Easing.easeInElastic,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.0646,
				offsetAngle: 11,
				easing: Easing.easeOutBack,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.1015,
				offsetAngle: 21,
				easing: Easing.easeOutBack,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.0775,
				offsetAngle: 4,
				easing: Easing.easeOutElastic,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.0867,
				offsetAngle: 7,
				easing: Easing.easeInBounce,
			},
		},
		{
			name: "",
			angleEasing: {
				trigger: 0.18,
				offsetAngle: 19,
				easing: Easing.easeInOutBounce,
			},
		},
	];
}
