import React, { memo } from "react";
import { useStore } from "effector-react";
import style from "./style.module.scss";
import Constants from "../../core/constants";
import { clearHighlight, setSelectedGroup, setSelectedNumber } from "../../store/table";
import { $store } from "../../store";
import Locale from "../../core/locale";

const SORTED_NUMBERS = [...Constants.NUMBERS]
	.sort((a, b) => Number(a.num) - Number(b.num))
	.filter((el) => Number(el.num) !== 0 && Number(el.num) !== 100);

const ZERO = [...Constants.NUMBERS].find((el) => el.num === 0);
const DOUBLE_ZERO = [...Constants.NUMBERS].find((el) => el.num === 100);

export const Table = memo(() => {
	const store = useStore($store);

	return (
		<div
			className={style.table}
			onMouseLeave={() => {
				if (store.table.allowMouseReaction) clearHighlight();
			}}
		>
			<div className={style.wrapper}>
				<div className={style.nulls} style={{ background: Constants.COLORS.numbers.zero }}>
					<p
						onMouseEnter={() => {
							if (store.table.allowMouseReaction) setSelectedNumber(DOUBLE_ZERO!);
						}}
						className={`${style.zero} ${store.table.selectedNumbers.find((sn)=>sn.num===100) ? style.active : ""}`}
					>
						{"00"}
					</p>
					<p
						onMouseEnter={() => {
							if (store.table.allowMouseReaction) setSelectedNumber(ZERO!);
						}}
						className={`${style.zero} ${store.table.selectedNumbers.find((sn)=>sn.num===0) ? style.active : ""}`}
					>
						{"0"}
					</p>
				</div>
				<div className={style.numsWrapper}>
					<div className={style.nums}>
						{SORTED_NUMBERS.map((el) => (
							<p
								key={el.num}
								style={{ background: el.color }}
								className={`${store.table.selectedNumbers.find((sn)=>sn.num===el.num) || el.flags.includes(store.table.selectedGroup) ? style.active : ""}`}
								onMouseEnter={() => {
									if (store.table.allowMouseReaction) setSelectedNumber(el);
								}}
							>
								{el.num}
							</p>
						))}
					</div>
					<div className={style.groups}>
						{Object.entries(Constants.GROUPS).map(([k, group]) => {
							const cleaned_k = k.replace(/\s/g, ""); // spaces
							return (
								<div className={style.group} key={k}>
									<p
										className={`${store.table.selectedGroup === cleaned_k || store.table.selectedNumbers.find((sn)=>sn.flags.includes(cleaned_k)) ? style.active : ""}`}
										onMouseEnter={() => {
											if (store.table.allowMouseReaction) setSelectedGroup(cleaned_k);
										}}
									>
										{Locale.Get(`table.part.${k[0]}`, {rows:12})}
									</p>
									<div className={style.odds}>
										{group.map((el) => (
											<p
												key={el.name}
												style={{
													background:
														Constants.COLORS.numbers[
															el.name as keyof typeof Constants.COLORS.numbers
														],
												}}
												className={`${store.table.selectedGroup === el.name || store.table.selectedNumbers.find((sn)=>sn.flags.includes(el.name)) ? style.active : ""}`}
												onMouseEnter={() => {
													if (store.table.allowMouseReaction) setSelectedGroup(el.name);
												}}
											>
												{Locale.Get(el.label).toUpperCase()}
											</p>
										))}
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div>
					{Constants.COLS.map((el, i) => (
						<p
							key={i}
							className={
								store.table.selectedGroup === el.flag ||
								store.table.selectedNumbers.find((sn)=>sn.flags.includes(el.flag))
									? style.active
									: ""
							}
							onMouseEnter={() => {
								if (store.table.allowMouseReaction) setSelectedGroup(el.flag);
							}}
						>{`${Locale.Get(`table.column.${el.value}`)}`}</p>
					))}
				</div>
			</div>
		</div>
	);
});
