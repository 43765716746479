import React, {Fragment, useLayoutEffect} from "react";
import {useStore, useUnit} from "effector-react";
import style from "./style.module.scss";
import Device from "./core/device";
import URL from "./core/url";

import Display from "./core/display";
import {Header} from "./components/header/Header";
import {Betting} from "./components/betting/Betting";
import {Infobar} from "./components/infobar/Infobar";
import {Betslip} from "./components/betslip/Betslip";
import {ItemsList} from "./features/history";
import {cn} from "./shared/lib/classNames";
import {$store} from "./store";
import {$app, defaults, setGameHeight, setIsSidetable, updateFlags} from "./store/app";
import {Locales} from "./components/debug/locales/Locales";
import {useHistory} from "./features/history";
import {SearchNumber} from "./shared/ui/search/searchNumber";
import {Ticket} from "./components/ticket/Ticket";
import {useTranslation} from "./shared/hooks";
import {BetsTable, useRotation} from "./features/table";
import {setRotate} from "./features/table/store";

export default function App() {
	const store = useStore($store);
	const rotation = useRotation();

	useLayoutEffect(() => {
		const gameHeight: number = document.getElementById("game")?.clientHeight || 100;
		setGameHeight(gameHeight);
	}, [store.app.isCashdesk, store.app.isDebug, store.app.isSideblock, store.app.isSidetable]);

	useLayoutEffect(() => {
		if(rotation.isVertical && !store.app.isFullwidth) {
			setIsSidetable(false);
			rotation.rotate();
		}
		if(!rotation.isVertical && store.app.isFullwidth && store.app.isSidetable) {
			rotation.rotate();
		}
		setTimeout(()=>{
			const gameHeight: number = document.getElementById("game")?.clientHeight || 100;
			setGameHeight(gameHeight);
		},100)

	}, [store.app.isFullwidth]);

	useLayoutEffect(() => {
		if(store.app.origin==="portal") {
			updateFlags(defaults.portal);
			setRotate(false);
		}
		if(Device.isMobile()) {
			if($app.getState().isLandscape) {
				updateFlags(defaults.mobile.landscape);
				setRotate(false);
			} else {
				updateFlags(defaults.mobile.portrait);
				setRotate(true);
			}
		}
	}, [store.app.isLandscape]);

	return (
		<Fragment>
			<div
				id={"main"}
				className={`${style.app} ${Device.isMobile() && Device.isIOS() && style.isIOS} roboto-mono-500`}
				onClick={() => {
					if (Device.isMobile() && !Device.isIOS()) Display.isFullScreen(true);
				}}
			>
				{ store.app.isFullwidth && store.app.isSidetable &&
					<div style={{height: `${store.app.gameHeight}px`, minWidth: "fit-content"}}>
						<Betting/>
					</div>
				}

				<div id={"game"} className={`${style.game} ${store.app.isFullwidth ? style.fullwidth : ""}`}>

					<Header/>
					<iframe
						style={{aspectRatio: "16/9"}}
						src={`roulette?token=${$store.getState().app.authToken}&language=${$store.getState().locales.lang}`}
					/>

					{ !store.app.isSidetable &&
						<>
                            <Betting />
							{ store.app.isLandscape && <Infobar/> }
						</>
					}
					<div className={`${style.betslipWrapper} ${!store.app.isLandscape && store.app.appIsMobile && style.sticky}`}>
						{ !store.app.isSidetable && !store.app.isLandscape && <Infobar /> }
						<Betslip/>
					</div>
				</div>
				{ store.app.isSideblock && store.app.isCashdesk && !store.app.appIsMobile
					? <AsideBlock/>
					: null
				}
			</div>

			<Locales/>

			{/*<div style={{position: "absolute", width: "100%", height: "100%", background: "rgba(0,0,0,.1)", top: "0", pointerEvents:"none"}}>*/}
			{/*	<Ticket id={396}></Ticket>*/}
			{/*</div>*/}
			{Device.isMobile() && Device.isIOS() && <div className={style.ios_scroll_crunch}/>}
		</Fragment>
	);
}

const AsideBlock = () => {
	const {t} = useTranslation();
	const app = useUnit($app);
	const {filteredBetsHistory, changeFilterId} = useHistory();
	return (
		<div className={style.asideBlock} style={{height: `${app.gameHeight}px`, background: `#141515`}}>
			<SearchNumber min={0} placeholder={t("short_info.ticket_code")} onValueChange={changeFilterId}/>
			<ItemsList items={filteredBetsHistory} className={cn(style.asideHistory, "scrollbar")}/>
		</div>
	);
};
