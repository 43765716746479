import { sample } from "effector";
import { NOTIFICATION_TYPE } from "./config";
import {
	addUserSelectedBetsFx,
	multiplyUserBetsFx,
	sendBetsFx,
	setUserBetsFx,
	setUserSelectedBetsFx,
} from "../table/store";
import { createSelfDestructedNotificationFx } from "./store";
import { cancelBetFx } from "../history/store";
import Locale from "../../core/locale";

sample({
	clock: multiplyUserBetsFx.failData,
	fn: (error) => ({ type: NOTIFICATION_TYPE.error, message: error.message, time: 2000 }),
	target: createSelfDestructedNotificationFx,
});
sample({
	clock: [
		addUserSelectedBetsFx.failData,
		setUserSelectedBetsFx.failData,
		setUserBetsFx.failData,
		sendBetsFx.failData,
		cancelBetFx.failData,
	],
	fn: (error) => ({ type: NOTIFICATION_TYPE.error, message: error.message, time: 2000 }),
	target: createSelfDestructedNotificationFx,
});
sample({
	clock: sendBetsFx,
	fn: () => ({
		type: NOTIFICATION_TYPE.attention,
		message: Locale.Get("ticket.processing_status"),
		time: 2000,
	}),
	target: createSelfDestructedNotificationFx,
});
sample({
	clock: sendBetsFx.doneData,
	fn: (ticket) => ({
		type: NOTIFICATION_TYPE.success,
		message: Locale.Get("ticket.confirmed_status", { ticketId: ticket.id }),
		time: 2000,
	}),
	target: createSelfDestructedNotificationFx,
});
sample({
	clock: cancelBetFx.doneData,
	fn: (bet) => ({
		type: NOTIFICATION_TYPE.success,
		message: Locale.Get("ticket.successfully_cancelled_status", { betId: bet.betId }),
		time: 2000,
	}),
	target: createSelfDestructedNotificationFx,
});
