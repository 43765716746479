import style from "./style.module.scss";
import {MemoizedBetTable, useRotation} from "../../features/table";
import {$app, setIsFullwidth, setIsSidetable} from "../../store/app";
import {ReactComponent as SideListOpen} from "../../img/icons/sidebar-open.svg";
import {ReactComponent as SideListClose} from "../../img/icons/sidebar-close.svg";
import {Button} from "../../shared/ui/button";
import React from "react";
import {useStore} from "effector-react";

export const Betting = () => {
	const app = useStore($app);
	const rotation = useRotation();
	return (
		<div className={style.betting}>
			{!app.appIsMobile && app.origin!=="portal" &&
                <Button
                    style={{
						width: "40px",
						height: "40px",
						margin: "12px 16px",
						float: "right",
						position: "absolute",
						top: "0",
						left: 0
					}}
                    variant={"contained"}
                    colored={"basic"}
                    onClick={() => {
						setIsSidetable(!app.isSidetable);
						setIsFullwidth(!app.isSidetable);
						rotation.rotate();
					}}
                >
					{app.isSidetable
						? <SideListOpen height={20} width={20} style={{rotate: "90deg"}}/>
						: <SideListClose height={20} width={20}/>
					}
                </Button>
			}
			<MemoizedBetTable/>
		</div>
	);
};
