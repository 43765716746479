import { useUnit } from "effector-react";
import {
	$betsFilters,
	$betsHistory,
	$filteredBets,
	$lastBetStatus,
	filterIdChanged,
	filtersCleared,
	historyShown,
	userBetCanceled,
} from "./store";

export const useHistory = () => {
	const betsHistory = useUnit({
		betsHistory: $betsHistory,
		filteredBetsHistory: $filteredBets,
		betsFilters: $betsFilters,
		cancelUserBet: userBetCanceled,
		changeFilterId: filterIdChanged,
		clearFilters: filtersCleared,
	});
	const getHistoryItem = (id: number) => {
		return betsHistory.betsHistory.find((bet) => bet.id === id);
	};

	return {
		...betsHistory,
		getHistoryItem,
	};
};

export const useLastStatus = () => useUnit({ status: $lastBetStatus, historyShown });
