import style from "./style.module.scss";
import {$store} from "../../store";
import Locale from "../../core/locale";
import {BetsTable} from "../betsTable/BetsTable";
import React, {memo} from "react";
import {Button} from "../../shared/ui/button";
import {setResults} from "../../store/app";

export const BetsResult = memo(() => {
	const result = {
		number: $store.getState().game.nextNumber.num,
		color: $store.getState().game.nextNumber.color
	}
	return (
		<div className={style.result} style={{background: "rgba(9,6,3,.9)"}}>
			<div className={style.wrap}>
				<div className={style.left}>
					<p className={style.title}>{Locale.Get("result.title").toUpperCase()}</p>
					<p className={style.description}>{Locale.Get("result.win_number").toUpperCase()}</p>
					<p style={{background: result.color}} className={style.number}>
						{result.number !== 100 ? result.number : "00"}
					</p>
				</div>
				<div className={style.right}>
					<BetsTable/>
				</div>
			</div>
		</div>
	);
});
