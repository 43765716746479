import check from "../../../../img/debug/check.svg"
export const Checkbox = (props:{bool:boolean, onClick?:Function})=>{
    return <div onClick={()=>{if(props.onClick) props.onClick()}}
        style={{
            width:"12px",height:"12px",
            background:"black",
            border:"solid white 1px",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            cursor:"pointer"
        }}>
        { props.bool && <img src={check} style={{width:"12px",height:"12px"}} alt=""/> }
    </div>
}