import { combine } from "effector";
import { $app } from "./app";
import { $table } from "./table";
import { $game } from "./game";
import { $variant } from "./variant";
import { $stats } from "./stats";
import { $locales } from "./locales";

import "./model";
import "../features/table/model";
import "../features/notifications/model";

export const $store = combine({
	app: $app,
	table: $table,
	game: $game,
	variant: $variant,
	stats: $stats,
	locales: $locales,
});
